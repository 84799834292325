/* eslint-disable default-param-last */
/* eslint-disable dot-notation */
import is from 'is_js';
import * as PIXI from 'pixi.js';

import { getArrowUri, getBackgroundUri, getIconUri } from '../../utils';

import {
  ADD_OFFLINE_TEAM,
  FETCH_OFFLINE_TEAM_USERS_BEGIN,
  FETCH_OFFLINE_TEAM_USERS_FAILURE,
  FETCH_OFFLINE_TEAM_USERS_SUCCESS,
  SET_SYNCHRONISED_USER_COUNT,
  SYNCHRONISE_ARROW_SET_BEGIN,
  SYNCHRONISE_ARROW_SET_FAILURE,
  SYNCHRONISE_ARROW_SET_SUCCESS,
  SYNCHRONISE_BACKGROUNDS_BEGIN,
  SYNCHRONISE_BACKGROUNDS_FAILURE,
  SYNCHRONISE_BACKGROUNDS_SUCCESS,
  SYNCHRONISE_DRILLS_BEGIN,
  SYNCHRONISE_DRILLS_FAILURE,
  SYNCHRONISE_DRILLS_SUCCESS,
  SYNCHRONISE_EXPLAINER_IMAGES_BEGIN,
  SYNCHRONISE_EXPLAINER_IMAGES_FAILURE,
  SYNCHRONISE_EXPLAINER_IMAGES_SUCCESS,
  SYNCHRONISE_ICON_SET_BEGIN,
  SYNCHRONISE_ICON_SET_FAILURE,
  SYNCHRONISE_ICON_SET_SUCCESS,
  SYNCHRONISE_PRODUCT_BEGIN,
  SYNCHRONISE_PRODUCT_FAILURE,
  SYNCHRONISE_PRODUCT_SUCCESS,
  SYNCHRONISE_USER_ASSESSMENTS_BEGIN,
  SYNCHRONISE_USER_ASSESSMENTS_FAILURE,
  SYNCHRONISE_USER_ASSESSMENTS_SUCCESS,
  SYNCHRONISE_USER_BEGIN,
  SYNCHRONISE_USER_FAILURE,
  SYNCHRONISE_USER_PRODUCT_BEGIN,
  SYNCHRONISE_USER_PRODUCT_FAILURE,
  SYNCHRONISE_USER_PRODUCT_SUCCESS,
  SYNCHRONISE_USER_SCORES_BEGIN,
  SYNCHRONISE_USER_SCORES_FAILURE,
  SYNCHRONISE_USER_SCORES_SUCCESS,
  SYNCHRONISE_USER_SUCCESS,
} from './actionTypes';

export const offlineState = {
  arrowSets: {},
  backgrounds: {},
  drills: {},
  error: null,
  iconSets: {},
  loadingArrowSet: false,
  loadingArrowSetError: null,
  loadingAssessments: {},
  loadingAssessmentsError: null,
  loadingBackgrounds: false,
  loadingBackgroundsError: null,
  loadingDrills: false,
  loadingDrillsError: null,
  loadingIconSet: false,
  loadingIconSetError: null,
  loadingProduct: {},
  loadingProductError: null,
  loadingUserProduct: {},
  loadingUserProductError: null,
  loadingUserScores: {},
  loadingUserScoresError: null,
  loadingUsers: false,
  products: {},
  synchronisedUserCount: 0,
  synchronisingUser: {},
  synchronisingUserErrors: [],
  teams: {},
  userScores: {},
  users: {},
};

const reducer = (state = offlineState, action) => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case ADD_OFFLINE_TEAM: {
      const { teams } = state;
      const { team } = action.payload;

      if (!teams[team.id]) {
        teams[team.id] = team;
      }

      return {
        ...state,
        teams,
      };
    }
    case FETCH_OFFLINE_TEAM_USERS_BEGIN: {
      return {
        ...state,
        error: null,
        loadingUsers: true,
      };
    }
    case FETCH_OFFLINE_TEAM_USERS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingUsers: false,
        users: [],
      };
    }
    case FETCH_OFFLINE_TEAM_USERS_SUCCESS: {
      const { users: offlineUsers } = action.payload;
      const { users: syncedUsers } = state;
      const users = {};
      offlineUsers.forEach((user) => {
        const syncedUser = syncedUsers[user.id];

        if (syncedUser) {
          user.lastSynchronised = syncedUser.lastSynchronised;
        }

        users[user.id] = user;
      });

      return {
        ...state,
        error: null,
        loadingUsers: false,
        users,
      };
    }
    case SET_SYNCHRONISED_USER_COUNT: {
      const { count } = action.payload;
      let newCount = count;

      if (is.function(count)) {
        const { synchronisedUserCount: currentCount } = state;
        newCount = count(currentCount);
      }

      return {
        ...state,
        synchronisedUserCount: newCount,
      };
    }
    case SYNCHRONISE_ARROW_SET_BEGIN: {
      return {
        ...state,
        loadingArrowSet: true,
        loadingArrowSetError: null,
      };
    }
    case SYNCHRONISE_ARROW_SET_FAILURE: {
      const { error: loadingArrowSetError } = action.payload;

      return {
        ...state,
        loadingArrowSet: false,
        loadingArrowSetError,
      };
    }
    case SYNCHRONISE_ARROW_SET_SUCCESS: {
      const { arrowSet } = action.payload;
      const { arrowSets } = state;
      const loader = PIXI.Loader.shared;

      if (!loader.resources['Up']) {
        loader.add('Up', getArrowUri('Up', arrowSet.up));
      }

      if (!loader.resources['Down']) {
        loader.add('Down', getArrowUri('Down', arrowSet.down));
      }

      if (!loader.resources['Left']) {
        loader.add('Left', getArrowUri('Left', arrowSet.left));
      }

      if (!loader.resources['Right']) {
        loader.add('Right', getArrowUri('Right', arrowSet.right));
      }

      arrowSets[arrowSet.id] = arrowSet;

      return {
        ...state,
        arrowSets,
        loadingArrowSet: false,
        loadingArrowSetError: null,
      };
    }
    case SYNCHRONISE_BACKGROUNDS_BEGIN: {
      return {
        ...state,
        loadingBackgrounds: true,
        loadingBackgroundsError: null,
      };
    }
    case SYNCHRONISE_BACKGROUNDS_FAILURE: {
      const { error: loadingBackgroundsError } = action.payload;

      return {
        ...state,
        loadingBackgrounds: false,
        loadingBackgroundsError,
      };
    }
    case SYNCHRONISE_BACKGROUNDS_SUCCESS: {
      const { teamId, backgrounds } = action.payload;
      const { backgrounds: offlineBackgrounds } = state;
      const loader = PIXI.Loader.shared;

      for (let i = 0, l = backgrounds.length; i < l; i += 1) {
        if (!loader.resources[backgrounds[i].id]) {
          loader.add(backgrounds[i].id, getBackgroundUri(backgrounds[i]));
        }
      }

      offlineBackgrounds[teamId] = backgrounds;

      return {
        ...state,
        backgrounds: offlineBackgrounds,
        loadingBackgrounds: false,
        loadingBackgroundsError: null,
      };
    }
    case SYNCHRONISE_DRILLS_BEGIN: {
      return {
        ...state,
        loadingDrills: true,
        loadingDrillsError: null,
      };
    }
    case SYNCHRONISE_DRILLS_FAILURE: {
      const { error: loadingDrillsError } = action.payload;

      return {
        ...state,
        loadingDrills: false,
        loadingDrillsError,
      };
    }
    case SYNCHRONISE_DRILLS_SUCCESS: {
      const { drills } = action.payload;
      const { drills: offlineDrills } = state;

      drills.forEach((drill) => {
        offlineDrills[drill.id] = drill;
      });

      return {
        ...state,
        drills: offlineDrills,
        loadingDrills: false,
        loadingDrillsError: null,
      };
    }
    case SYNCHRONISE_EXPLAINER_IMAGES_BEGIN: {
      return {
        ...state,
        loadingExplainerImages: true,
        loadingExplainerImagesError: null,
      };
    }
    case SYNCHRONISE_EXPLAINER_IMAGES_FAILURE: {
      const { error: loadingExplainerImagesError } = action.payload;

      return {
        ...state,
        loadingExplainerImages: false,
        loadingExplainerImagesError,
      };
    }
    case SYNCHRONISE_EXPLAINER_IMAGES_SUCCESS: {
      const { explainerImages } = action.payload;
      const { explainerImages: offlineExplainerImages = {} } = state;

      explainerImages.forEach((explainerImage) => {
        offlineExplainerImages[explainerImage.id] = explainerImage;
      });

      return {
        ...state,
        explainerImages: offlineExplainerImages,
        loadingExplainerImages: false,
        loadingExplainerImagesError: null,
      };
    }
    case SYNCHRONISE_ICON_SET_BEGIN: {
      return {
        ...state,
        loadingIconSet: true,
        loadingIconSetError: null,
      };
    }
    case SYNCHRONISE_ICON_SET_FAILURE: {
      const { error: loadingIconSetError } = action.payload;

      return {
        ...state,
        loadingIconSet: false,
        loadingIconSetError,
      };
    }
    case SYNCHRONISE_ICON_SET_SUCCESS: {
      const { iconSet } = action.payload;
      const { iconSets } = state;
      const loader = PIXI.Loader.shared;

      for (let i = 0, l = iconSet.iconSetImages.length; i < l; i += 1) {
        if (!loader.resources[iconSet.iconSetImages[i].imageId]) {
          loader.add(iconSet.iconSetImages[i].imageId, getIconUri(iconSet.iconSetImages[i].image));
        }
      }

      iconSets[iconSet.id] = iconSet;

      return {
        ...state,
        iconSets,
        loadingIconSet: false,
        loadingIconSetError: null,
      };
    }
    case SYNCHRONISE_PRODUCT_BEGIN: {
      const { productId } = action.payload;
      const { loadingProduct } = state;

      loadingProduct[productId] = true;

      return {
        ...state,
        loadingProduct,
        loadingProductError: null,
      };
    }
    case SYNCHRONISE_PRODUCT_FAILURE: {
      const { productId, error: loadingProductError } = action.payload;
      const { loadingProduct } = state;

      loadingProduct[productId] = false;

      return {
        ...state,
        loadingProduct,
        loadingProductError,
      };
    }
    case SYNCHRONISE_PRODUCT_SUCCESS: {
      const { product } = action.payload;
      const { products, loadingProduct } = state;

      products[product.id] = product;
      loadingProduct[product.id] = false;

      return {
        ...state,
        loadingProduct,
        loadingProductError: null,
        products,
      };
    }
    case SYNCHRONISE_USER_ASSESSMENTS_BEGIN: {
      const { userId } = action.payload;
      const { loadingAssessments } = state;

      loadingAssessments[userId] = true;

      return {
        ...state,
        loadingAssessments,
        loadingAssessmentsError: null,
      };
    }
    case SYNCHRONISE_USER_ASSESSMENTS_FAILURE: {
      const { userId, error: loadingAssessmentsError } = action.payload;
      const { loadingAssessments } = state;

      loadingAssessments[userId] = false;

      return {
        ...state,
        loadingAssessments,
        loadingAssessmentsError,
      };
    }
    case SYNCHRONISE_USER_ASSESSMENTS_SUCCESS: {
      const { userId, assessments } = action.payload;
      const { users, loadingAssessments } = state;

      users[userId].assessments = assessments;
      loadingAssessments[userId] = false;

      return {
        ...state,
        loadingAssessments,
        loadingAssessmentsError: null,
        users,
      };
    }
    case SYNCHRONISE_USER_PRODUCT_BEGIN: {
      const { userId } = action.payload;
      const { loadingUserProduct } = state;

      loadingUserProduct[userId] = true;

      return {
        ...state,
        loadingUserProduct,
        loadingUserProductError: null,
      };
    }
    case SYNCHRONISE_USER_PRODUCT_FAILURE: {
      const { userId, error: loadingUserProductError } = action.payload;
      const { loadingUserProduct } = state;

      loadingUserProduct[userId] = false;

      return {
        ...state,
        loadingUserProduct,
        loadingUserProductError,
      };
    }
    case SYNCHRONISE_USER_PRODUCT_SUCCESS: {
      const { userId, userProduct } = action.payload;
      const { users, loadingUserProduct } = state;

      users[userId].currentUserProduct = userProduct;
      loadingUserProduct[userId] = false;

      return {
        ...state,
        loadingUserProduct,
        users,
      };
    }
    case SYNCHRONISE_USER_SCORES_BEGIN: {
      const { userProductId } = action.payload;
      const { loadingUserScores } = state;

      loadingUserScores[userProductId] = true;

      return {
        ...state,
        loadingUserScores,
        loadingUserScoresError: null,
      };
    }
    case SYNCHRONISE_USER_SCORES_FAILURE: {
      const { userProductId, error: loadingUserScoresError } = action.payload;
      const { loadingUserScores } = state;

      loadingUserScores[userProductId] = false;

      return {
        ...state,
        loadingUserScores,
        loadingUserScoresError,
      };
    }
    case SYNCHRONISE_USER_SCORES_SUCCESS: {
      const { userProductId, userScores: scores } = action.payload;
      const { loadingUserScores, userScores } = state;

      loadingUserScores[userProductId] = false;
      userScores[userProductId] = scores;

      return {
        ...state,
        loadingUserScores,
        loadingUserScoresError: null,
        userScores,
      };
    }
    case SYNCHRONISE_USER_BEGIN: {
      const { userId } = action.payload;
      const { synchronisingUser } = state;

      synchronisingUser[userId] = true;

      return {
        ...state,
        synchronisingUser,
        synchronisingUserErrors: [],
      };
    }
    case SYNCHRONISE_USER_FAILURE: {
      const { userId, errors: synchronisingUserErrors } = action.payload;
      const { synchronisingUser } = state;

      synchronisingUser[userId] = false;

      return {
        ...state,
        synchronisingUser,
        synchronisingUserErrors,
      };
    }
    case SYNCHRONISE_USER_SUCCESS: {
      const { user } = action.payload;
      const { synchronisingUser, users } = state;

      synchronisingUser[user.id] = false;

      user.lastSynchronised = new Date();
      users[user.id] = user;

      return {
        ...state,
        synchronisingUser,
        synchronisingUserErrors: [],
        users,
      };
    }
    default:
      return state;
  }
};

export default reducer;
