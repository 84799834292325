/* eslint-disable default-param-last */
import { CLEAR_STORE, LOGGED_IN, LOGGED_OUT, RESET_AUTH_STATE, SET_SILENT_RENEW } from './actionTypes';

export const authenticationState = {
  isSilentRenew: false,
  loggingOut: false,
  user: null,
};

const reducer = (state = authenticationState, action) => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case CLEAR_STORE: {
      return authenticationState;
    }
    case LOGGED_IN: {
      const { user } = action.payload;

      return {
        ...state,
        user,
      };
    }
    case LOGGED_OUT:
      return {
        ...state,
        user: null,
      };
    case RESET_AUTH_STATE: {
      return {
        ...authenticationState,
      };
    }
    case SET_SILENT_RENEW: {
      const { isSilentRenew } = action.payload;

      return {
        ...state,
        isSilentRenew,
      };
    }
    default:
      return state;
  }
};

export default reducer;
