import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AuthConsumer } from '../../providers';
import { actionCreators } from '../../redux/authentication';
import { Loader } from '../Loader';

const useStyles = makeStyles({
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

export function SilentRenew() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionCreators.setSilentRenew(true));
  }, [dispatch]);

  return (
    <AuthConsumer>
      {({ signinSilentCallback }) => {
        signinSilentCallback();

        return (
          <div className={classes.loader}>
            <Loader />
          </div>
        );
      }}
    </AuthConsumer>
  );
}
