/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { noop } from '../utils';

export function CheckboxCell({ className, disabled, onChange, ...props }) {
  function CellComponent({ row, value }) {
    const [checked, setChecked] = useState(value);

    const handleChange = (e) => {
      onChange(e, !checked, row);
      setChecked(!checked);
    };

    return (
      <Checkbox checked={checked} color="primary" disabled={disabled} className={className} onChange={handleChange} />
    );
  }

  CellComponent.propTypes = {
    row: PropTypes.object,
    value: PropTypes.bool,
  };

  CellComponent.defaultProps = {
    row: undefined,
    value: false,
  };

  return <DataTypeProvider formatterComponent={CellComponent} {...props} />;
}

CheckboxCell.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxCell.defaultProps = {
  className: undefined,
  disabled: false,
  onChange: noop,
};
