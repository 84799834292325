export const addEvent = (obj, type, fn, useCapture) => {
  if (obj.addEventListener) {
    obj.addEventListener(type, fn, useCapture);
  } else if (obj.attachEvent) {
    obj[`e${type}${fn}`] = fn;

    obj[type + fn] = () => {
      obj[`e${type}${fn}`](window.event);
    };

    obj.attachEvent(`on${type}`, obj[type + fn]);
  } else {
    obj[`on${type}`] = obj[`e${type}${fn}`];
  }
};

export const removeEvent = (obj, type, fn, useCapture) => {
  if (obj.removeEventListener) {
    obj.removeEventListener(type, fn, useCapture);
  } else if (obj.detachEvent) {
    obj.detachEvent(`on${type}`, obj[type + fn]);
  } else {
    obj[`on${type}`] = null;
  }
};
