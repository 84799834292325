export const IDENTITY_CONFIG = {
  // (string, default: 'openid'): The scope being requested from the OIDC provider.
  audience: process.env.REACT_APP_AUTH_URL,

  authority: process.env.REACT_APP_AUTH_URL,

  // The URI of your client application to receive a response from the OIDC provider.
  automaticSilentRenew: true,

  // (string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,

  // is there a way to specific the audience when making the jwt
  loadUserInfo: true,

  // (string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: `${process.env.REACT_APP_AUTH_URL}/logout/callback`,

  // (string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: `${process.env.REACT_APP_AUTH_URL}/signin-oidc`,

  // (string): The OIDC post-logout redirect URI.
  response_type: 'id_token token',

  // (string, default: 'id_token'): The type of response desired from the OIDC provider.
  scope: 'openid profile offline_access eyegym-api',

  // (boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  silent_redirect_uri: `${process.env.REACT_APP_AUTH_URL}/silent-renew`,

  // (boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silentRequestTimeout: 100000,
};

export const METADATA_OIDC = {
  authorization_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/authorize`,
  check_session_iframe: `${process.env.REACT_APP_AUTH_URL}/connect/checksession`,
  end_session_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/endsession`,
  introspection_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/introspect`,
  issuer: process.env.REACT_APP_AUTH_URL,
  jwks_uri: `${process.env.REACT_APP_AUTH_URL}/.well-known/openid-configuration/jwks`,
  revocation_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/revocation`,
  token_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/token`,
  userinfo_endpoint: `${process.env.REACT_APP_AUTH_URL}/connect/userinfo`,
};
