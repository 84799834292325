import { plainToClass } from 'class-transformer';
import is from 'is_js';
import moment from 'moment';

import { api } from '../../api';
import { User } from '../../models';
import { asyncForEach, extractRootFilters, fetchData, getExplainerImageUri } from '../../utils';

import {
  ADD_OFFLINE_TEAM,
  FETCH_OFFLINE_TEAM_USERS_BEGIN,
  FETCH_OFFLINE_TEAM_USERS_FAILURE,
  FETCH_OFFLINE_TEAM_USERS_SUCCESS,
  SET_SYNCHRONISED_USER_COUNT,
  SYNCHRONISE_ARROW_SET_BEGIN,
  SYNCHRONISE_ARROW_SET_FAILURE,
  SYNCHRONISE_ARROW_SET_SUCCESS,
  SYNCHRONISE_BACKGROUNDS_BEGIN,
  SYNCHRONISE_BACKGROUNDS_FAILURE,
  SYNCHRONISE_BACKGROUNDS_SUCCESS,
  SYNCHRONISE_DRILLS_BEGIN,
  SYNCHRONISE_DRILLS_FAILURE,
  SYNCHRONISE_DRILLS_SUCCESS,
  SYNCHRONISE_EXPLAINER_IMAGES_BEGIN,
  SYNCHRONISE_EXPLAINER_IMAGES_FAILURE,
  SYNCHRONISE_EXPLAINER_IMAGES_SUCCESS,
  SYNCHRONISE_ICON_SET_BEGIN,
  SYNCHRONISE_ICON_SET_FAILURE,
  SYNCHRONISE_ICON_SET_SUCCESS,
  SYNCHRONISE_PRODUCT_BEGIN,
  SYNCHRONISE_PRODUCT_FAILURE,
  SYNCHRONISE_PRODUCT_SUCCESS,
  SYNCHRONISE_USER_ASSESSMENTS_BEGIN,
  SYNCHRONISE_USER_ASSESSMENTS_FAILURE,
  SYNCHRONISE_USER_ASSESSMENTS_SUCCESS,
  SYNCHRONISE_USER_BEGIN,
  SYNCHRONISE_USER_FAILURE,
  SYNCHRONISE_USER_PRODUCT_BEGIN,
  SYNCHRONISE_USER_PRODUCT_FAILURE,
  SYNCHRONISE_USER_PRODUCT_SUCCESS,
  SYNCHRONISE_USER_SCORES_BEGIN,
  SYNCHRONISE_USER_SCORES_FAILURE,
  SYNCHRONISE_USER_SCORES_SUCCESS,
  SYNCHRONISE_USER_SUCCESS,
} from './actionTypes';

const addOfflineTeam = (team) => ({
  payload: {
    team,
  },
  type: ADD_OFFLINE_TEAM,
});

const fetchOfflineTeamUsersBegin = () => ({
  type: FETCH_OFFLINE_TEAM_USERS_BEGIN,
});

const fetchOfflineTeamUsersFailure = (error) => ({
  payload: {
    error,
  },
  type: FETCH_OFFLINE_TEAM_USERS_FAILURE,
});

const fetchOfflineTeamUsersSuccess = (users) => ({
  payload: {
    users,
  },
  type: FETCH_OFFLINE_TEAM_USERS_SUCCESS,
});

const setSynchronisedUserCount = (count) => ({
  payload: {
    count,
  },
  type: SET_SYNCHRONISED_USER_COUNT,
});

const synchroniseArrowSetBegin = () => ({
  type: SYNCHRONISE_ARROW_SET_BEGIN,
});

const synchroniseArrowSetFailure = (error) => ({
  payload: {
    error,
  },
  type: SYNCHRONISE_ARROW_SET_FAILURE,
});

const synchroniseArrowSetSuccess = (arrowSet) => ({
  payload: {
    arrowSet,
  },
  type: SYNCHRONISE_ARROW_SET_SUCCESS,
});

const synchroniseArrowSet = (arrowSetId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    // offlineUsers: { arrowSets: offlineArrowSets },
  } = getState();

  const { log } = context;

  if (!(auth && log && arrowSetId)) {
    return false;
  }

  // if (offlineArrowSets && offlineArrowSets[arrowSetId]) {
  //   return true;
  // }

  dispatch(synchroniseArrowSetBegin());

  try {
    const url = api.odata.arrowSet(arrowSetId, ['Up', 'Down', 'Left', 'Right']);
    const response = await fetchData(url, auth.access_token);
    dispatch(synchroniseArrowSetSuccess(response));

    return response;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseArrowSetFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseArrowSet' });

    return false;
  }
};

const synchroniseBackgroundsBegin = () => ({
  type: SYNCHRONISE_BACKGROUNDS_BEGIN,
});

const synchroniseBackgroundsFailure = (error) => ({
  payload: {
    error,
  },
  type: SYNCHRONISE_BACKGROUNDS_FAILURE,
});

const synchroniseBackgroundsSuccess = (teamId, backgrounds) => ({
  payload: {
    backgrounds,
    teamId,
  },
  type: SYNCHRONISE_BACKGROUNDS_SUCCESS,
});

const synchroniseBackgrounds = (teamId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    // backgrounds: offlineBackgrounds,
  } = getState();

  const { log } = context;

  if (!(auth && log && teamId)) {
    return false;
  }

  // if (offlineBackgrounds && offlineBackgrounds[teamId]) {
  //   return true;
  // }

  dispatch(synchroniseBackgroundsBegin());

  try {
    const url = api.rest.backgrounds(teamId);
    const response = await fetchData(url, auth.access_token);
    dispatch(synchroniseBackgroundsSuccess(teamId, response));

    return response;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseBackgroundsFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseBackgrounds' });

    return false;
  }
};

const synchroniseDrillsBegin = () => ({
  type: SYNCHRONISE_DRILLS_BEGIN,
});

const synchroniseDrillsFailure = (error) => ({
  payload: {
    error,
  },
  type: SYNCHRONISE_DRILLS_FAILURE,
});

const synchroniseDrillsSuccess = (drills) => ({
  payload: {
    drills,
  },
  type: SYNCHRONISE_DRILLS_SUCCESS,
});

const synchroniseDrills = (drillIds, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    offlineUsers: { drills: offlineDrills },
  } = getState();

  const { log } = context;

  if (!(auth && log)) {
    return false;
  }

  const drillsToLoad = drillIds.filter((id) => !offlineDrills[id]);

  if (!drillsToLoad.length) {
    return true;
  }

  dispatch(synchroniseDrillsBegin());

  const filter = [
    {
      columnName: 'Id',
      op: 'in',
      value: drillIds,
    },
  ];

  const url = api.odata.drills({
    expand: ['Details', 'Settings'],
    filter: extractRootFilters(filter, []),
  });

  try {
    const response = await fetchData(url, auth.access_token);
    const drills = response.value;
    dispatch(synchroniseDrillsSuccess(drills));

    return drills;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseDrillsFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseDrills' });

    return false;
  }
};

const synchroniseExplainerImagesBegin = () => ({
  type: SYNCHRONISE_EXPLAINER_IMAGES_BEGIN,
});

const synchroniseExplainerImagesFailure = (error) => ({
  payload: {
    error,
  },
  type: SYNCHRONISE_EXPLAINER_IMAGES_FAILURE,
});

const synchroniseExplainerImagesSuccess = (explainerImages) => ({
  payload: {
    explainerImages,
  },
  type: SYNCHRONISE_EXPLAINER_IMAGES_SUCCESS,
});

const synchroniseExplainerImages = (context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
  } = getState();

  const { log } = context;

  if (!(auth && log)) {
    return false;
  }

  dispatch(synchroniseExplainerImagesBegin());

  try {
    const filter = [['ImageType', 'eq', "'Explainers'"]];
    const url = api.odata.images({ filter });
    const response = await fetchData(url, auth.access_token);
    const explainerImages = response.value;

    await asyncForEach(explainerImages, async (explainerImage) => {
      const image = new Image();
      const imageUrl = getExplainerImageUri(explainerImage);
      image.src = imageUrl;
    });

    dispatch(synchroniseExplainerImagesSuccess(explainerImages));

    return explainerImages;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseExplainerImagesFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseExplainerImages' });
  }
};

const synchroniseIconSetBegin = () => ({
  type: SYNCHRONISE_ICON_SET_BEGIN,
});

const synchroniseIconSetFailure = (error) => ({
  payload: {
    error,
  },
  type: SYNCHRONISE_ICON_SET_FAILURE,
});

const synchroniseIconSetSuccess = (iconSet) => ({
  payload: {
    iconSet,
  },
  type: SYNCHRONISE_ICON_SET_SUCCESS,
});

const synchroniseIconSet = (iconSetId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    // offlineUsers: { iconSets: offlineIconSets },
  } = getState();

  const { log } = context;

  if (!(auth && log && iconSetId)) {
    return false;
  }

  // if (offlineIconSets && offlineIconSets[iconSetId]) {
  //   return true;
  // }

  dispatch(synchroniseIconSetBegin());

  const expansions = [{ IconSetImages: ['Image'] }];

  const url = api.odata.iconSet(iconSetId, expansions);

  try {
    const response = await fetchData(url, auth.access_token);
    dispatch(synchroniseIconSetSuccess(response));

    return response;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseIconSetFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseIconSet' });

    return false;
  }
};

const synchroniseProductBegin = (productId) => ({
  payload: {
    productId,
  },
  type: SYNCHRONISE_PRODUCT_BEGIN,
});

const synchroniseProductFailure = (productId, error) => ({
  payload: {
    error,
    productId,
  },
  type: SYNCHRONISE_PRODUCT_FAILURE,
});

const synchroniseProductSuccess = (product) => ({
  payload: {
    product,
  },
  type: SYNCHRONISE_PRODUCT_SUCCESS,
});

const synchroniseProduct = (productId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    offlineUsers: { loadingProduct, products },
  } = getState();

  const { log } = context;

  if (!(auth && log && productId) || loadingProduct[productId]) {
    return false;
  }

  if (products[productId]) {
    return products[productId];
  }

  dispatch(synchroniseProductBegin(productId));

  const url = api.odata.product(productId, ['ProductDrills']);

  try {
    const response = await fetchData(url, auth.access_token);
    const product = response;
    dispatch(synchroniseProductSuccess(product));

    return product;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseProductFailure(productId, message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseProduct' });

    return false;
  }
};

const synchroniseUserAssessmentsBegin = (userId) => ({
  payload: {
    userId,
  },
  type: SYNCHRONISE_USER_ASSESSMENTS_BEGIN,
});

const synchroniseUserAssessmentsFailure = (userId, error) => ({
  payload: {
    error,
    userId,
  },
  type: SYNCHRONISE_USER_ASSESSMENTS_FAILURE,
});

const synchroniseUserAssessmentsSuccess = (userId, assessments) => ({
  payload: {
    assessments,
    userId,
  },
  type: SYNCHRONISE_USER_ASSESSMENTS_SUCCESS,
});

const synchroniseUserAssessments = (userId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    offlineUsers: { loadingAssessments },
  } = getState();

  const { log } = context;

  if (!(auth && log) || loadingAssessments[userId]) {
    return false;
  }

  dispatch(synchroniseUserAssessmentsBegin(userId));

  const url = api.odata.userAssessments({
    filter: [
      ['UserId', 'eq', userId],
      ['IsCompleted', 'eq', 'false'],
    ],
  });

  try {
    const response = await fetchData(url, auth.access_token);
    const assessments = response.value;
    dispatch(synchroniseUserAssessmentsSuccess(userId, assessments));

    return assessments;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseUserAssessmentsFailure(userId, message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseUserAssessments' });

    return false;
  }
};

const synchronizeUserProductBegin = (userId) => ({
  payload: {
    userId,
  },
  type: SYNCHRONISE_USER_PRODUCT_BEGIN,
});

const synchroniseUserProductFailure = (userId, error) => ({
  payload: {
    error,
    userId,
  },
  type: SYNCHRONISE_USER_PRODUCT_FAILURE,
});

const synchroniseUserProductSuccess = (userId, userProduct) => ({
  payload: {
    userId,
    userProduct,
  },
  type: SYNCHRONISE_USER_PRODUCT_SUCCESS,
});

const synchroniseUserProduct = (userId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    offlineUsers: { users, loadingUserProduct },
  } = getState();

  const { log } = context;
  const user = users[userId];

  if (!(auth && log && user && user.currentUserProductId) || loadingUserProduct[userId]) {
    return false;
  }

  dispatch(synchronizeUserProductBegin(userId));

  const url = api.odata.userProducts({ expand: ['Levels'], filter: [['Id', 'eq', user.currentUserProductId]] });

  try {
    const response = await fetchData(url, auth.access_token);
    const userProduct = response.value[0];
    dispatch(synchroniseUserProductSuccess(userId, userProduct));

    return userProduct;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseUserProductFailure(userId, message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseUserProduct' });

    return false;
  }
};

const fetchOfflineTeamUsers = (teamId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    offlineUsers: { loadingUsers, users: offlineUsers, teams },
  } = getState();

  const { log } = context;

  if (is.not.online()) {
    return Object.values(offlineUsers);
  }

  if (!auth || !log || loadingUsers) {
    return;
  }

  dispatch(fetchOfflineTeamUsersBegin());

  const url = api.odata.users({ filter: [['TeamId', 'eq', teamId]] });

  try {
    const response = await fetchData(url, auth.access_token);
    const users = plainToClass(User, response.value);
    const team = teams[teamId];

    const validUsers = users.filter((user) => {
      user.team = team;

      return moment.utc().isSameOrBefore(moment(user.getExpiryDate()));
    });

    dispatch(fetchOfflineTeamUsersSuccess(validUsers));

    return validUsers;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchOfflineTeamUsersFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.fetchOfflineTeamUsers' });
  }
};

const synchroniseUserScoresBegin = (userProductId) => ({
  payload: {
    userProductId,
  },
  type: SYNCHRONISE_USER_SCORES_BEGIN,
});

const synchroniseUserScoresFailure = (userProductId, error) => ({
  payload: {
    error,
    userProductId,
  },
  type: SYNCHRONISE_USER_SCORES_FAILURE,
});

const synchroniseUserScoresSuccess = (userProductId, userScores) => ({
  payload: {
    userProductId,
    userScores,
  },
  type: SYNCHRONISE_USER_SCORES_SUCCESS,
});

const synchroniseUserScores = (userProductId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    offlineUsers: { loadingUserScores },
  } = getState();

  const { log } = context;

  if (!(auth && log && userProductId) || loadingUserScores[userProductId]) {
    return false;
  }

  dispatch(synchroniseUserScoresBegin(userProductId));

  const url = api.odata.userScores({ filter: [['UserProductId', 'eq', userProductId]] });

  try {
    const response = await fetchData(url, auth.access_token);
    const userScores = response.value;
    dispatch(synchroniseUserScoresSuccess(userProductId, userScores));

    return userScores;
  } catch (error) {
    const message = error.message || error;
    dispatch(synchroniseUserScoresFailure(userProductId, message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.offline.synchoniseUserScores' });

    return false;
  }
};

const synchroniseUserBegin = (userId) => ({
  payload: {
    userId,
  },
  type: SYNCHRONISE_USER_BEGIN,
});

const synchroniseUserFailure = (userId, errors) => ({
  payload: {
    errors,
    userId,
  },
  type: SYNCHRONISE_USER_FAILURE,
});

const synchroniseUserSuccess = (user) => ({
  payload: {
    user,
  },
  type: SYNCHRONISE_USER_SUCCESS,
});

const synchroniseUser = (userId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
  } = getState();

  dispatch(synchroniseUserBegin(userId));

  const url = api.odata.user(userId);

  const user = await fetchData(url, auth.access_token);

  await dispatch(synchroniseUserSuccess(user));

  return dispatch(synchroniseUserScores(user.currentUserProductId, context))
    .then(() =>
      dispatch(synchroniseUserProduct(userId, context))
        .then((userProduct) =>
          userProduct?.productId ? dispatch(synchroniseProduct(userProduct.productId, context)) : false
        )
        .then((product) => {
          if (product?.productDrills?.length) {
            const drillIds = product.productDrills.map((pd) => pd.drillId);

            return dispatch(synchroniseDrills(drillIds, context));
          }

          return false;
        })
    )
    .then(() =>
      dispatch(synchroniseUserAssessments(userId, context)).then((assessments) =>
        assessments?.map((assessment) =>
          dispatch(synchroniseProduct(assessment.productId, context)).then((product) => {
            assessment.product = product;

            if (product?.productDrills?.length) {
              const drillIds = product.productDrills.map((pd) => pd.drillId);

              return dispatch(synchroniseDrills(drillIds, context));
            }

            return false;
          })
        )
      )
    )
    .catch((error) => dispatch(synchroniseUserFailure(userId, [error])))
    .finally(() => dispatch(setSynchronisedUserCount((n) => n + 1)));
};

export const actionCreators = {
  addOfflineTeam,
  fetchOfflineTeamUsers,
  setSynchronisedUserCount,
  synchroniseArrowSet,
  synchroniseBackgrounds,
  synchroniseExplainerImages,
  synchroniseIconSet,
  synchroniseUser,
};
