export const extractRootFilters = (filters, columnConfig) => {
  if (!filters.length) {
    return [];
  }

  const params = [];

  for (let i = 0, l = filters.length; i < l; i += 1) {
    const { columnName, op: defaultOp = 'eq', value } = filters[i];
    const normalizedName = columnName.toLowerCase();

    const { name = normalizedName, op = defaultOp } = columnConfig[normalizedName] || {
      name: normalizedName,
      op: defaultOp,
    };

    if (value !== null) {
      params.push([name, op, value]);
    }
  }

  return params;
};
