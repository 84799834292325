import { api } from '../api';
import { fetchData } from '../utils';

export const fetchVersion = (context) => async () => {
  if (!context) {
    return;
  }

  const url = api.rest.version;
  const { log } = context;

  try {
    const response = await fetchData(url);

    return response.version;
  } catch (error) {
    log.error('Error: {error} occurred at {source}', {
      error,
      source: 'services.VersionService.fetchVersion',
    });
  }
};
