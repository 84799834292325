import { buildODataUri, getApiRoot, getEntityRoot, getLookupRoot } from './utils/uri';

const REST_API_VERSION = 3;

const arrowSetsRoot = getEntityRoot('arrowSets');
const drillsRoot = getEntityRoot('drills');
const iconSetsRoot = getEntityRoot('iconSets');
const imagesRoot = getEntityRoot('images');
const productsRoot = getEntityRoot('products');
const searchDrillsRoot = getEntityRoot('drills');
const searchProductsRoot = getEntityRoot('products');
const searchTeamsRoot = getEntityRoot('searchTeams');
const searchUsersRoot = getEntityRoot('searchUsers');
const sportsRoot = getEntityRoot('sports');
const teamsRoot = getEntityRoot('teams');
const usersRoot = getEntityRoot('users');
const userAssessmentsRoot = getEntityRoot('userAssessments');
const userProductRoot = getEntityRoot('userProducts');
const userScoresRoot = getEntityRoot('userScores');

const arrowSetsUri = getLookupRoot('arrow-sets', REST_API_VERSION);
const backgroundsUri = getApiRoot('backgrounds', REST_API_VERSION);
const backgroundTypesUri = getLookupRoot('background-types', REST_API_VERSION);
const behavioursUri = getLookupRoot('behaviours', REST_API_VERSION);
const drillTypesUri = getLookupRoot('drill-types', REST_API_VERSION);
const explainerImageAlignmentsUri = getLookupRoot('explainer-image-alignments', REST_API_VERSION);
const goalTypesUri = getLookupRoot('goal-types', REST_API_VERSION);
const iconSetsUri = getLookupRoot('icon-sets', REST_API_VERSION);
const inputTypesUri = getLookupRoot('input-types', REST_API_VERSION);
const languagesUri = getLookupRoot('languages', REST_API_VERSION);
const objectVarietiesUri = getLookupRoot('object-varieties', REST_API_VERSION);

const versionUri = getApiRoot('version', REST_API_VERSION);
const signInUri = getApiRoot('accounts/signin', REST_API_VERSION);
const signInWithTokenUri = getApiRoot('accounts/signin-token', REST_API_VERSION);
const signOutUri = getApiRoot('accounts/signout', REST_API_VERSION);
const postScoreUri = getApiRoot('userscores/create', REST_API_VERSION);
const increaseLevelUri = getApiRoot('userlevels/increase-level', REST_API_VERSION);
const completeUserAssessmentUrl = getApiRoot('userassessments/complete-assessment', REST_API_VERSION);
const acceptDisclaimerUrl = getApiRoot('users/accept-disclaimer', REST_API_VERSION);
const settingsUri = getApiRoot('settings', REST_API_VERSION);

const defaultArrowSetExpansions = ['Up', 'Down', 'Left', 'Right'];
const defaultDrillExpansions = [];
const defaultIconSetExpansions = [{ IconSetImages: ['Image'] }];
const defaultProductExpansions = [];
const defaultTeamExpansions = [];
const defaultUserExpansions = [];
const defaultUserProductExpansions = ['Levels'];
const defaultUserAssessmentExpansions = [];

export const api = {
  odata: {
    arrowSet: (arrowSetId, expand = defaultArrowSetExpansions) =>
      buildODataUri(`${arrowSetsRoot}(${arrowSetId})`, {
        $expand: expand,
      }),
    drill: (drillId, expand = defaultDrillExpansions) =>
      buildODataUri(`${drillsRoot}(${drillId})`, {
        $expand: expand,
      }),
    drills: (
      { filter, expand = defaultDrillExpansions } = {
        expand: defaultDrillExpansions,
        filter: [],
      }
    ) =>
      buildODataUri(drillsRoot, {
        $expand: expand,
        $filter: filter,
      }),
    drillsLookup: (sort = 'Name') =>
      buildODataUri(drillsRoot, {
        $orderBy: sort,
        $select: ['Id', 'Name'],
      }),
    iconSet: (iconSetId, expand = defaultIconSetExpansions) =>
      buildODataUri(`${iconSetsRoot}(${iconSetId})`, {
        $expand: expand,
      }),
    image: (imageId) => buildODataUri(`${imagesRoot}(${imageId})`),
    images: ({ filter } = { filter: [] }) => buildODataUri(imagesRoot, { $filter: filter }),
    product: (productId, expand = defaultProductExpansions) =>
      buildODataUri(`${productsRoot}(${productId})`, {
        $expand: expand,
      }),
    products: (
      pageSize,
      currentPage,
      { sort, expand, filter } = {
        expand: defaultProductExpansions,
        filter: [],
        sort: 'Name',
      }
    ) =>
      buildODataUri(productsRoot, {
        $count: true,
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
        $skip: pageSize * currentPage,
        $top: pageSize,
      }),
    productsLookup: (sort = 'Name') =>
      buildODataUri(productsRoot, {
        $orderBy: sort,
        $select: ['Id', 'Name'],
      }),
    searchDrills: (
      pageSize,
      currentPage,
      { sort, expand, filter } = {
        expand: defaultDrillExpansions,
        filter: [],
        sort: 'Name',
      }
    ) =>
      buildODataUri(searchDrillsRoot, {
        $count: true,
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
        $skip: pageSize * currentPage,
        $top: pageSize,
      }),
    searchProducts: (
      pageSize,
      currentPage,
      { sort, expand, filter } = {
        expand: defaultProductExpansions,
        filter: [],
        sort: 'Name',
      }
    ) =>
      buildODataUri(searchProductsRoot, {
        $count: true,
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
        $skip: pageSize * currentPage,
        $top: pageSize,
      }),
    searchTeams: (
      pageSize,
      currentPage,
      { sort, expand, filter } = {
        expand: defaultTeamExpansions,
        filter: [],
        sort: 'Name',
      }
    ) =>
      buildODataUri(searchTeamsRoot, {
        $count: true,
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
        $skip: pageSize * currentPage,
        $top: pageSize,
      }),
    searchUsers: (
      pageSize,
      currentPage,
      { sort, expand, filter } = {
        expand: defaultUserExpansions,
        filter: [],
        sort: 'Name',
      }
    ) =>
      buildODataUri(searchUsersRoot, {
        $count: true,
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
        $skip: pageSize * currentPage,
        $top: pageSize,
      }),
    sportsLookup: (sort = 'Name') =>
      buildODataUri(sportsRoot, {
        $orderBy: sort,
        $select: ['Id', 'Name'],
      }),
    team: (teamId, expand = defaultTeamExpansions) =>
      buildODataUri(`${teamsRoot}(${teamId})`, {
        $expand: expand,
      }),
    teams: (
      pageSize,
      currentPage,
      { sort, expand, filter } = {
        expand: defaultTeamExpansions,
        filter: [],
        sort: 'Name',
      }
    ) =>
      buildODataUri(teamsRoot, {
        $count: true,
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
        $skip: pageSize * currentPage,
        $top: pageSize,
      }),
    teamsLookup: (sort = 'Name') =>
      buildODataUri(teamsRoot, {
        $orderBy: sort,
        $select: ['Id', 'Name'],
      }),
    user: (userId, expand = defaultUserExpansions) =>
      buildODataUri(`${usersRoot}(${userId})`, {
        $expand: expand,
      }),
    userAssessments: (
      { sort, expand, filter } = {
        expand: defaultUserAssessmentExpansions,
        filter: [],
        sort: 'AssessmentDate',
      }
    ) =>
      buildODataUri(userAssessmentsRoot, {
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
      }),
    userProducts: (
      { sort, expand, filter } = {
        expand: defaultUserProductExpansions,
        filter: [],
        sort: 'StartDate',
      }
    ) =>
      buildODataUri(userProductRoot, {
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
      }),
    userScores: (
      { filter } = {
        filter: [],
      }
    ) => buildODataUri(userScoresRoot, { $filter: filter }),
    users: (
      { sort = 'Surname', expand, filter } = {
        expand: defaultUserExpansions,
        filter: [],
        sort: 'Surname',
      }
    ) =>
      buildODataUri(usersRoot, {
        $expand: expand,
        $filter: filter,
        $orderBy: sort,
      }),
  },
  rest: {
    accounts: {
      signIn: signInUri,
      signInWithToken: signInWithTokenUri,
      signOut: (returnUri) => `${signOutUri}?returnUrl=${encodeURIComponent(returnUri)}`,
    },
    backgrounds: (teamId) => `${backgroundsUri}?teamId=${teamId}`,
    lookups: {
      arrowSets: arrowSetsUri,
      backgroundTypes: backgroundTypesUri,
      behaviours: behavioursUri,
      drillTypes: drillTypesUri,
      explainerImageAlignments: explainerImageAlignmentsUri,
      goalTypes: goalTypesUri,
      iconSets: iconSetsUri,
      inputTypes: inputTypesUri,
      languages: languagesUri,
      objectVarieties: objectVarietiesUri,
    },
    settings: settingsUri,
    userAssessments: {
      complete: completeUserAssessmentUrl,
    },
    userlevels: {
      increase: increaseLevelUri,
    },
    users: {
      acceptDisclaimer: acceptDisclaimerUrl,
    },
    userscores: {
      post: postScoreUri,
    },
    version: versionUri,
  },
};
