/* eslint-disable no-console */
import { Container } from '@inlet/react-pixi';
import * as TWEEN from '@tweenjs/tween.js';
import random from 'lodash/random';
import range from 'lodash/range';
import moment from 'moment';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Icon } from '../../../models';
import { DrillContext, LogContext } from '../../../providers';
import { actionCreators as drillActionCreators } from '../../../redux/drill';
import { noop } from '../../../utils';
import { Background } from '../Background';
import { Canvas } from '../Canvas';

import { CaptureAnswer } from './CaptureAnswer';

export function EyeSpeed(props) {
  const { width, height, onComplete } = props;
  const [showDrill, setShowDrill] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const dispatch = useDispatch();

  const iconContainer = useRef(null);
  const handleAnswer = useRef(noop);
  const handlePass = useRef(noop);

  const {
    drill,
    getIcon,
    getSymbol,
    iconSet,
    postScore,
    setStartPosition,
    settings,
    targetMovement,
    userAssessmentId,
    userProductId,
  } = useContext(DrillContext);

  const { log } = useContext(LogContext);

  const ticker = PIXI.Ticker.shared;

  useEffect(() => {
    if (!(settings && iconContainer.current)) {
      return;
    }

    let correctCount = 0;
    let incorrectCount = 0;
    let paneInterval = 0;
    let paneNumber = 0;
    let paneStart;
    let slowCount = 0;
    let start;
    let startDelay = 0;
    let totalResponseTime = 0;
    let icons = [];

    const initialiseIcons = () => {
      icons = range(settings.totalObjects).map((i) => {
        const icon = new Icon(
          width,
          height,
          iconSet?.flipHorizontal,
          drill.goalType,
          iconSet?.overlayOpacity,
          iconSet?.rotateSpeed,
          settings,
          drill.showSymbolOnly,
          targetMovement,
          log
        );

        icon.setSize(i);
        const iconRef = getIcon(i);
        const symbolRef = getSymbol(i);

        icon.setImages(iconRef, symbolRef);

        setStartPosition(icon, i, width, height, settings.numberOfPanes);

        return icon;
      });

      iconContainer.current.zIndex = 1000;
      iconContainer.current.removeChildren();

      let zIndex = 1001;

      icons.forEach((icon) => {
        icon.zIndex = zIndex;
        iconContainer.current.addChild(icon.container);
        zIndex += 1;
      });
    };

    const setStartTimes = () => {
      paneStart = moment.utc();

      if (!start) {
        start = paneStart;
      }

      icons.forEach((icon) => {
        icon.startTime = paneStart;
      });
    };

    const updateResponseTime = (responseTime) => {
      if (responseTime > 0) {
        totalResponseTime += responseTime;
      }
    };

    const initialiseTimingParameters = () => {
      paneInterval = random(settings.paneTimeout * 1000, settings.paneTimeoutMaximum * 1000);
      startDelay = random(settings.startDelayMinimum * 1000, settings.startDelayMaximum * 1000);
      // minimumDisplayTime = random(settings.minimumDisplayTime * 1000, settings.minimumDisplayTime * 4 * 1000);

      if (drill.objectIncrement) {
        if (paneNumber > 0 && paneNumber % drill.objectIncrementInterval === 0) {
          settings.totalObjects += 1;
        } else if (paneNumber === 0 && drill.objectIncrementInterval === 1) {
          settings.totalObjects += 1;
        }
      }
    };

    const captureAnswer = () => {
      ticker.stop();
      setShowDrill(false);
      setShowAnswer(true);
    };

    const timingLoop = (e) => {
      if (e.paused) {
        return;
      }

      let elapsedTime;

      if (paneInterval) {
        elapsedTime = moment.utc().diff(paneStart);

        if (startDelay > 0 && elapsedTime < startDelay) {
          elapsedTime = 0;
        } else {
          elapsedTime -= startDelay;
        }

        if (elapsedTime > paneInterval) {
          captureAnswer();
        }
      }
    };

    const gameLoop = (e) => {
      const params = {
        delta: e,
        paused: !ticker.started,
      };

      timingLoop(params);
      TWEEN.update();
    };

    const resetStage = () => {
      // eslint-disable-next-line no-use-before-define
      ticker.remove(gameLoop);
      iconContainer.current.removeChildren();
    };

    const finish = () => {
      resetStage();
      postScore({
        correctCount,
        drill,
        incorrectCount,
        settings,
        slowCount,
        start,
        totalResponseTime,
        userAssessmentId,
        userProductId,
      });

      ticker.stop();
      // eslint-disable-next-line no-use-before-define
      ticker.remove(gameLoop);
      onComplete();
    };

    const nextPane = () => {
      ticker.stop();

      if (paneNumber + 1 < settings.numberOfPanes) {
        paneNumber += 1;
        dispatch(drillActionCreators.triggerBackgroundUpdate());
        initialiseTimingParameters();
        initialiseIcons();
        setStartTimes();
        ticker.start();
      } else {
        finish();
      }
    };

    handleAnswer.current = (e) => {
      const responseTime = moment.utc().diff(paneStart);
      const targetTime = settings.targetTime * 1000 || 0;
      const answer = Number(e);
      updateResponseTime(responseTime);
      let value = '';
      icons.forEach((icon) => {
        value = `${value}${icon.symbolIndex}`;
      });

      value = Number(value);

      if (answer === value) {
        if (targetTime && responseTime > targetTime) {
          slowCount += 1;
        }

        correctCount += 1;
      } else {
        incorrectCount += 1;
      }

      setShowAnswer(false);
      setShowDrill(true);
      nextPane();
    };

    handlePass.current = () => {
      incorrectCount += 1;
      setShowAnswer(false);
      setShowDrill(true);
      nextPane();
    };

    initialiseTimingParameters();
    initialiseIcons();
    setStartTimes();

    ticker.add(gameLoop);
    ticker.start();

    return () => {
      ticker.stop();
      ticker.remove(gameLoop);
    };
  }, [
    dispatch,
    drill,
    getIcon,
    getSymbol,
    height,
    iconSet?.flipHorizontal,
    iconSet?.overlayOpacity,
    iconSet?.rotateSpeed,
    onComplete,
    postScore,
    setStartPosition,
    settings,
    targetMovement,
    ticker,
    userAssessmentId,
    userProductId,
    width,
    log,
  ]);

  const handleMount = (options) => {
    options.events.destroy();
  };

  return (
    <>
      <Canvas width={width} height={height} show={showDrill} onMount={handleMount}>
        <Background width={width} height={height} />
        <Container ref={iconContainer} />
      </Canvas>
      {showAnswer && <CaptureAnswer onAnswer={handleAnswer.current} onPass={handlePass.current} />}
    </>
  );
}

EyeSpeed.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onComplete: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

EyeSpeed.defaultProps = {
  onComplete: noop,
};
