import { Box, Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import { Requirements } from '../Requirements';

import { LoginForm } from './LoginForm';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
}));

export function Login(props) {
  const classes = useStyles();

  useEffect(() => {
    if (document) {
      document.title = 'EyeGym | Sign in';
    }
  }, []);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box className={clsx(classes.card, classes.column)}>
        <Box className={classes.row}>
          <Box className={classes.stretched}>
            <Grid container>
              <Grid item xs={12} md={7}>
                <Requirements />
              </Grid>
              <Grid item xs={12} md={5}>
                <LoginForm {...props} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
