import { SeqLogger } from '@mad-dogs/seq-logger';
import { MessageTemplate } from '@mad-dogs/structured-log';
import debug from 'debug';
import is from 'is_js';

import { noop } from './noop';

const nullSeq = {
  apiKey: undefined,
  appName: undefined,
  host: undefined,
  level: undefined,
  url: undefined,
  version: undefined,
};

export const defaultOptions = {
  base: 'eyegym',
  colours: {
    debug: 'lightblue',
    error: 'red',
    fatal: 'darkred',
    info: 'blue',
    verbose: 'yellow',
    warn: 'orange',
  },
  seqOptions: nullSeq,
};

const nullLogger = {
  debug: noop,
  error: noop,
  fatal: noop,
  info: noop,
  verbose: noop,
  warn: noop,
};

export class Log {
  constructor(options) {
    const {
      base,
      colours,
      seqOptions: { host, appName, version, level, url, apiKey },
    } = options;

    this.colours = colours;
    this.base = base;
    this.logger = options.seqOptions && host ? new SeqLogger(host, appName, version, level, url, apiKey) : nullLogger;
  }

  generateMessage(level, message, props) {
    const { source, ...other } = props || { source: undefined };
    const namespace = source ? `${this.base}:${source}` : `${this.base}`;
    const createDebug = debug(namespace);

    createDebug.color = this.colours[level];

    const unboundProperties = Object.values(other);

    const messageTemplate = new MessageTemplate(message);
    const properties = messageTemplate.bindProperties(unboundProperties);
    const renderedMessage = messageTemplate.render(properties);

    // this will output to the console
    if (source) {
      createDebug(source, renderedMessage);
    } else {
      createDebug(renderedMessage);
    }

    if (is.online()) {
      // output to seq
      this.logger[level](message, unboundProperties);
    }
  }

  fatal(message, props) {
    this.generateMessage('fatal', message, props);
  }

  error(message, props) {
    this.generateMessage('error', message, props);
  }

  warn(message, props) {
    this.generateMessage('warn', message, props);
  }

  info(message, props) {
    this.generateMessage('info', message, props);
  }

  debug(message, props) {
    this.generateMessage('debug', message, props);
  }

  verbose(message, props) {
    this.generateMessage('verbose', message, props);
  }
}

export const DefaultLog = new Log(defaultOptions);
