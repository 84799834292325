/* eslint-disable no-console */
import { Container } from '@inlet/react-pixi';
import * as TWEEN from '@tweenjs/tween.js';
import random from 'lodash/random';
import range from 'lodash/range';
import moment from 'moment';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Behaviour, Icon } from '../../../models';
import { DrillContext, LogContext } from '../../../providers';
import { noop, randomEx } from '../../../utils';
import { Background } from '../Background';
import { Canvas } from '../Canvas';

import { CaptureAnswer } from './CaptureAnswer';

export function EyeBounce(props) {
  const { width, height, onComplete } = props;
  const [showDrill, setShowDrill] = useState(true);
  const [showAnswer, setShowAnswer] = useState(false);
  const dispatch = useDispatch();

  const iconContainer = useRef(null);
  const handleAnswer = useRef(noop);
  const handlePass = useRef(noop);
  const numberOfTargetIcons = useRef(0);
  const correctAnswer = useRef(0);
  const userAnswer = useRef(0);

  const {
    drill,
    getIcon,
    getSymbol,
    iconSet,
    postScore,
    setStartPosition,
    settings,
    targetMovement,
    userAssessmentId,
    userProductId,
  } = useContext(DrillContext);

  const { log } = useContext(LogContext);

  const ticker = PIXI.Ticker.shared;

  useEffect(() => {
    if (!(settings && iconContainer.current)) {
      return;
    }

    let correctCount = 0;
    let incorrectCount = 0;
    let paneInterval = 0;
    let paneStart;
    let slowCount = 0;
    let start;
    let startDelay = 0;
    let totalResponseTime = 0;
    let icons = [];

    const incrementNumberOfTargetIcons = () => {
      numberOfTargetIcons.current += 1;
    };

    const initialiseIcons = () => {
      icons = range(settings.totalObjects).map((i) => {
        const icon = new Icon(
          width,
          height,
          iconSet?.flipHorizontal,
          drill.goalType,
          iconSet.overlayOpacity,
          iconSet.rotateSpeed,
          settings,
          drill.showSymbolOnly,
          targetMovement,
          log
        );

        icon.setSize(i);
        const iconRef = getIcon(i, incrementNumberOfTargetIcons);
        const symbolRef = getSymbol(i);

        icon.setImages(iconRef, symbolRef);

        setStartPosition(icon, i, width, height, settings.numberOfPanes);

        icon.speed =
          i === 0
            ? settings.objectSpeed
            : random(settings.objectSpeedMinimum * 1000, settings.objectSpeedMaximum * 1000) / 1000;

        if (i === 0) {
          icon.shapeShiftInterval = 200;
        }

        return icon;
      });

      iconContainer.current.zIndex = 1000;
      iconContainer.current.removeChildren();

      let zIndex = 1001;

      for (let i = icons.length - 1; i >= 0; i -= 1) {
        icons[i].zIndex = zIndex;
        iconContainer.current.addChild(icons[i].container);
        zIndex += 1;
      }
    };

    const updateResponseTime = (responseTime) => {
      if (responseTime > 0) {
        totalResponseTime += responseTime;
      }
    };

    const initialiseTimingParameters = () => {
      paneInterval = random(settings.paneTimeout * 1000, settings.paneTimeoutMaximum * 1000);
      startDelay = random(settings.startDelayMinimum * 1000, settings.startDelayMaximum * 1000);
    };

    const setStartTimes = () => {
      paneStart = moment.utc();

      if (!start) {
        start = paneStart;
      }

      icons.forEach((icon) => {
        icon.startTime = paneStart;
      });
    };

    const resetStage = () => {
      // eslint-disable-next-line no-use-before-define
      ticker.remove(gameLoop);
      iconContainer.current.removeChildren();
    };

    const finish = () => {
      resetStage();
      postScore({
        correctCount,
        drill,
        incorrectCount,
        settings,
        slowCount,
        start,
        totalResponseTime,
        userAssessmentId,
        userProductId,
      });

      ticker.stop();
      // eslint-disable-next-line no-use-before-define
      ticker.remove(gameLoop);
      onComplete({ answer: userAnswer.current, correct: correctAnswer.current });
    };

    const movementLoop = (e) => {
      if (e.paused) {
        return;
      }

      if (icons && icons.length > 0) {
        icons.forEach((icon) => icon.update(e));
      }
    };

    const captureAnswer = () => {
      ticker.stop();
      setShowDrill(false);
      setShowAnswer(true);
    };

    const timingLoop = (e) => {
      if (e.paused) {
        return;
      }

      let elapsedTime;

      if (paneInterval) {
        elapsedTime = moment.utc().diff(paneStart);

        if (startDelay > 0 && elapsedTime < startDelay) {
          elapsedTime = 0;
        } else {
          elapsedTime -= startDelay;
        }

        if (elapsedTime > paneInterval) {
          updateResponseTime(elapsedTime);
          captureAnswer();
        }
      }
    };

    const shapeShiftIcon = (icon, delta) => {
      icon.shapeShift(delta, () => {
        const index = randomEx(0, iconSet.iconSetImages.length - 1, icon.iconIndex);
        const iconRef = { id: iconSet.iconSetImages[index].imageId, index };
        icon.setImages(iconRef);
      });
    };

    const shapeShiftLoop = (e) => {
      if (e.paused) {
        return;
      }

      if (drill.behaviour !== Behaviour.SHAPE_SHIFT_ALL && drill.behaviour !== Behaviour.SHAPE_SHIFT_TARGET) {
        return;
      }

      if (drill.behaviour === Behaviour.SHAPE_SHIFT_ALL) {
        icons.forEach((icon) => shapeShiftIcon(icon, e.delta));
      }

      if (drill.behaviour === Behaviour.SHAPE_SHIFT_TARGET) {
        shapeShiftIcon(icons[0], e.delta);
      }
    };

    const gameLoop = (e) => {
      const params = {
        delta: e,
        paused: !ticker.started,
      };

      timingLoop(params);
      shapeShiftLoop(params);
      movementLoop(params);
      TWEEN.update();
    };

    handleAnswer.current = (e) => {
      const responseTime = moment.utc().diff(paneStart);
      const targetTime = settings.targetTime * 1000 || 0;
      const answer = Number(e);
      updateResponseTime(responseTime);
      let total;

      if (drill.behaviour === Behaviour.SHAPE_SHIFT_ALL || drill.behaviour === Behaviour.SHAPE_SHIFT_TARGET) {
        const target = icons[0];
        total = target.shapeShiftCount;
      } else {
        total = numberOfTargetIcons.current;
      }

      correctAnswer.current = total;
      userAnswer.current = answer;

      const difference = Math.abs(answer - total);
      let correct = answer;

      if (answer > total) {
        correct = answer - difference;
      }

      if (targetTime && responseTime > targetTime) {
        slowCount += 1;
      }

      correctCount = correct;
      incorrectCount = difference;

      finish();
    };

    handlePass.current = () => {
      const responseTime = moment.utc().diff(paneStart);
      const targetTime = settings.targetTime * 1000 || 0;
      updateResponseTime(responseTime);
      let total;

      if (drill.Behaviour === Behaviour.SHAPE_SHIFT_ALL || drill.Behaviour === Behaviour.SHAPE_SHIFT_TARGET) {
        const target = icons[icons.length - 1];
        total = target.shapeShiftCount;
      } else {
        total = numberOfTargetIcons.current;
      }

      correctAnswer.current = total;
      userAnswer.current = "I don't know";

      if (targetTime && responseTime > targetTime) {
        slowCount += 1;
      }

      correctCount = 0;
      incorrectCount = total;

      finish();
    };

    initialiseTimingParameters();
    initialiseIcons();
    setStartTimes();

    ticker.add(gameLoop);
    ticker.start();

    return () => {
      ticker.stop();
      ticker.remove(gameLoop);
    };
  }, [
    dispatch,
    drill,
    getIcon,
    getSymbol,
    height,
    iconSet?.flipHorizontal,
    iconSet?.icons?.length,
    iconSet?.iconSetImages,
    iconSet?.overlayOpacity,
    iconSet?.rotateSpeed,
    onComplete,
    postScore,
    setStartPosition,
    settings,
    targetMovement,
    ticker,
    userAssessmentId,
    userProductId,
    width,
    log,
  ]);

  const handleMount = (options) => {
    options.events.destroy();
  };

  return (
    <>
      <Canvas width={width} height={height} show={showDrill} onMount={handleMount}>
        <Background width={width} height={height} />
        <Container ref={iconContainer} />
      </Canvas>
      {showAnswer && <CaptureAnswer onAnswer={handleAnswer.current} onPass={handlePass.current} />}
    </>
  );
}

EyeBounce.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onComplete: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

EyeBounce.defaultProps = {
  onComplete: noop,
};
