import { UserRole } from '../constants/users';

export class AuthUser {
  get hasRoles() {
    return this?.profile?.role?.length;
  }

  get isEyeGymUser() {
    return this.isInRole(UserRole.EYEGYM_USER);
  }

  get isDriverBureauUser() {
    return this.isInRole(UserRole.DRIVER_BUREAU_USER);
  }

  get role() {
    return (this.hasRoles && this.profile.role) || [];
  }

  isInRole(role) {
    return this.role.includes(role);
  }
}
