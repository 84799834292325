import { Texture } from 'pixi.js';

export const gradient = (colour1, colour2, width, height) => {
  const colours = [colour1, colour2, colour1, colour2, colour1];
  const stops = [0, 0.25, 0.5, 0.75, 1];

  let c = document.createElement('canvas');
  c.width = width;
  c.height = height;
  const ctx = c.getContext('2d');
  const grd = ctx.createLinearGradient(0, 0, width, height);

  for (let i = 0, l = stops.length; i < l; i += 1) {
    grd.addColorStop(stops[i], colours[i]);
  }

  ctx.fillStyle = grd;
  ctx.fillRect(0, 0, width, height);

  // eslint-disable-next-line new-cap
  const texture = Texture.from(c);

  c = null;

  return texture;
};
