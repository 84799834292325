/* eslint-disable no-console */
import { Container } from '@inlet/react-pixi';
import * as TWEEN from '@tweenjs/tween.js';
import detectTouchEvents from 'detect-touch-events';
import random from 'lodash/random';
import range from 'lodash/range';
import moment from 'moment';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Icon, Key, Mouse } from '../../../models';
import { DrillContext, LogContext } from '../../../providers';
import { actionCreators as drillActionCreators } from '../../../redux/drill';
import { noop } from '../../../utils';
import { Background } from '../Background';
import { Canvas } from '../Canvas';

export function EyeHandReaction(props) {
  const { width, height, onComplete } = props;
  const [, setApp] = useState();
  const [events, setEvents] = useState();
  const iconContainer = useRef(null);
  const dispatch = useDispatch();

  const {
    drill,
    getIcon,
    getSymbol,
    iconSet,
    postScore,
    setStartPosition,
    settings,
    targetMovement,
    userAssessmentId,
    userProductId,
  } = useContext(DrillContext);

  const { log } = useContext(LogContext);

  const ticker = PIXI.Ticker.shared;

  useEffect(() => {
    if (!(settings && iconContainer.current && events)) {
      return;
    }

    let correctCount = 0;
    let incorrectCount = 0;
    let paneInterval = 0;
    let paneNumber = 0;
    let paneStart;
    let slowCount = 0;
    let start;
    let startDelay = 0;
    let totalResponseTime = 0;
    let icons = [];
    const paneScores = [];

    const initialiseIcons = () => {
      icons = range(settings.totalObjects).map((i) => {
        const icon = new Icon(
          width,
          height,
          iconSet?.flipHorizontal,
          drill?.goalType,
          iconSet?.overlayOpacity,
          iconSet?.rotateSpeed,
          settings,
          drill?.showSymbolOnly,
          targetMovement,
          log
        );

        icon.setSize(i);
        const iconRef = getIcon(i);
        const symbolRef = getSymbol(i);

        icon.setImages(iconRef, symbolRef);

        setStartPosition(icon, i, width, height, settings.numberOfPanes);

        icon.speed =
          i === 0
            ? settings.objectSpeed
            : random(settings.objectSpeedMinimum * 1000, settings.objectSpeedMaximum * 1000) / 1000;

        return icon;
      });

      iconContainer.current.zIndex = 1000;
      iconContainer.current.removeChildren();

      let zIndex = 1001;

      for (let i = icons.length - 1; i >= 0; i -= 1) {
        icons[i].zIndex = zIndex;
        iconContainer.current.addChild(icons[i].container);
        zIndex += 1;
      }
    };

    const paneScoreExists = (pane) => {
      if (!paneScores.length) {
        return false;
      }

      return !!paneScores.find((s) => s.paneNumber === pane);
    };

    const updateResponseTime = (responseTime) => {
      if (responseTime > 0) {
        totalResponseTime += responseTime;
      }
    };

    const initialiseTimingParameters = () => {
      paneInterval = random(settings.paneTimeout * 1000, settings.paneTimeoutMaximum * 1000);
      startDelay = random(settings.startDelayMinimum * 1000, settings.startDelayMaximum * 1000);

      if (drill.objectIncrement) {
        if (paneNumber > 0 && paneNumber % drill.objectIncrementInterval === 0) {
          settings.totalObjects += 1;
        } else if (paneNumber === 0 && drill.objectIncrementInterval === 1) {
          settings.totalObjects += 1;
        }
      }
    };

    const setStartTimes = () => {
      paneStart = moment.utc();

      if (!start) {
        start = paneStart;
      }

      icons.forEach((icon) => {
        icon.startTime = paneStart;
      });
    };

    const resetStage = () => {
      // eslint-disable-next-line no-use-before-define
      ticker.remove(gameLoop);
      iconContainer.current.removeChildren();
    };

    const finish = () => {
      resetStage();
      postScore({
        correctCount,
        drill,
        incorrectCount,
        settings,
        slowCount,
        start,
        totalResponseTime,
        userAssessmentId,
        userProductId,
      });

      ticker.stop();
      // eslint-disable-next-line no-use-before-define
      ticker.remove(gameLoop);
      onComplete();
    };

    const nextPane = () => {
      ticker.stop();

      if (paneNumber + 1 < settings.numberOfPanes) {
        paneNumber += 1;
        dispatch(drillActionCreators.triggerBackgroundUpdate());
        initialiseTimingParameters();
        initialiseIcons();
        setStartTimes();
        ticker.start();
      } else {
        finish();
      }
    };

    const handleCorrect = (responseTime) => {
      let slow = false;
      const targetTime = (settings.targetTime || 0) * 1000;

      if (targetTime > 0 && responseTime > targetTime) {
        slow = true;
        slowCount += 1;
      }

      if (!paneScoreExists(paneNumber)) {
        correctCount += 1;

        paneScores.push({
          correct: true,
          paneNumber,
          responseTime,
          slow,
          targetTime,
          trackTime: 0,
        });
      }

      updateResponseTime(responseTime);
      nextPane();
    };

    const handleIncorrect = () => {
      incorrectCount += 1;
    };

    const handleExpectedInput = (event) => {
      const target = icons[0];
      const targetStartTime = target.startTime;
      const targetStartDelay = target.startDelay;
      let responseTime;

      if (event.handled) {
        return;
      }

      event.handled = true;

      if (targetStartTime) {
        responseTime = moment.utc().diff(targetStartTime);

        if (targetStartDelay) {
          responseTime -= targetStartDelay;
        }
      }

      if (responseTime && responseTime >= 0) {
        handleCorrect(responseTime);
      } else {
        handleIncorrect();
      }
    };

    const inputLoop = (e) => {
      if (e.paused) {
        return;
      }

      const eventQueue = events.getQueue();

      eventQueue.forEach((event) => {
        if (event.type === Mouse.MOUSE_DOWN) {
          handleExpectedInput(event);
        } else if (event.type === Key.KEY_DOWN) {
          if (event.key === Key.K_SPACE) {
            handleExpectedInput(event);
          }
        }
      });
    };

    const movementLoop = (e) => {
      if (e.paused) {
        return;
      }

      if (icons && icons.length > 0) {
        icons.forEach((icon) => icon.update(e));
      }
    };

    const timingLoop = (e) => {
      if (e.paused) {
        return;
      }

      let elapsedTime;

      if (paneInterval) {
        elapsedTime = moment.utc().diff(paneStart);

        if (startDelay > 0 && elapsedTime < startDelay) {
          elapsedTime = 0;
        } else {
          elapsedTime -= startDelay;
        }

        if (elapsedTime > paneInterval) {
          updateResponseTime(elapsedTime);
          nextPane();
        }
      }
    };

    const gameLoop = (e) => {
      const params = {
        delta: e,
        paused: !ticker.started,
      };

      timingLoop(params);
      inputLoop(params);
      movementLoop(params);
      TWEEN.update();
    };

    if (detectTouchEvents.hasSupport) {
      events.clickOnTouchStart = true;
    }

    initialiseTimingParameters();
    initialiseIcons();
    setStartTimes();

    ticker.add(gameLoop);
    ticker.start();

    return () => {
      events.clickOnTouchStart = false;
      ticker.stop();
      ticker.remove(gameLoop);
    };
  }, [
    dispatch,
    drill,
    events,
    getIcon,
    getSymbol,
    height,
    iconSet?.flipHorizontal,
    iconSet?.overlayOpacity,
    iconSet?.rotateSpeed,
    onComplete,
    postScore,
    setStartPosition,
    settings,
    targetMovement,
    ticker,
    userAssessmentId,
    userProductId,
    width,
    log,
  ]);

  const handleMount = (options) => {
    setApp(options.app);
    setEvents(options.events);
  };

  return (
    <Canvas width={width} height={height} onMount={handleMount}>
      <Background width={width} height={height} />
      <Container ref={iconContainer} />
    </Canvas>
  );
}

EyeHandReaction.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onComplete: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

EyeHandReaction.defaultProps = {
  onComplete: noop,
};
