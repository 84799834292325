/* eslint-disable default-param-last */
import { DrillStage } from '../../models';

import {
  CLEAR_STORE,
  SET_DRILL_STAGE,
  SET_HAZARD_ICON,
  SET_INPUT_DIRECTION,
  SET_LAST_BACKGROUND_INDEX,
  SET_LAST_DIRECTION,
  SET_SAFE_ICON,
  SET_SHOW_SCORE,
  SET_SHOW_TARGET,
  SET_TARGET_ICON,
  SET_TARGET_MOVEMENT,
  SET_TASK,
  SET_UNSAFE_ICON,
  TRIGGER_BACKGROUND_UPDATE,
  TRIGGER_DRILL_RESET,
} from './actionTypes';

const validStages = [DrillStage.START, DrillStage.PLAY, DrillStage.SCORE];

export const drillState = {
  backgroundCount: 0,
  drillResetCount: 0,
  hazardIcon: null,
  hazardIconIndex: undefined,
  inputDirection: 0,
  lastBackgroundIndex: -1,
  lastDirection: 0,
  safeIcon: null,
  safeIconIndex: undefined,
  score: null,
  showScore: false,
  showTarget: false,
  stage: 'start',
  targetIcon: null,
  targetIconId: '',
  targetIconIndex: undefined,
  targetMovement: 0,
  task: '',
  unsafeIcon: null,
  unsafeIconIndex: undefined,
};

const reducer = (state = drillState, action) => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case CLEAR_STORE: {
      return drillState;
    }
    case SET_DRILL_STAGE: {
      const { stage } = action.payload;

      if (validStages.includes(stage)) {
        return {
          ...state,
          stage,
        };
      }

      return state;
    }
    case SET_HAZARD_ICON: {
      const { hazardIcon, hazardIconIndex } = action.payload;

      return {
        ...state,
        hazardIcon,
        hazardIconIndex,
      };
    }
    case SET_INPUT_DIRECTION: {
      const { inputMovement } = action.payload;

      return {
        ...state,
        inputMovement,
      };
    }
    case SET_LAST_BACKGROUND_INDEX: {
      const { lastBackgroundIndex } = action.payload;

      return {
        ...state,
        lastBackgroundIndex,
      };
    }
    case SET_LAST_DIRECTION: {
      const { lastDirection } = action.payload;

      return {
        ...state,
        lastDirection,
      };
    }
    case SET_SAFE_ICON: {
      const { safeIcon, safeIconIndex } = action.payload;

      return {
        ...state,
        safeIcon,
        safeIconIndex,
      };
    }
    case SET_SHOW_SCORE: {
      const { showScore, score } = action.payload;

      return {
        ...state,
        score,
        showScore,
      };
    }
    case SET_SHOW_TARGET: {
      const { showTarget } = action.payload;

      return {
        ...state,
        showTarget,
      };
    }
    case SET_TARGET_ICON: {
      const { targetIcon, targetIconId, targetIconIndex } = action.payload;

      return {
        ...state,
        targetIcon,
        targetIconId,
        targetIconIndex,
      };
    }
    case SET_TARGET_MOVEMENT: {
      const { targetMovement } = action.payload;

      return {
        ...state,
        targetMovement,
      };
    }
    case SET_TASK: {
      const { task } = action.payload;

      return {
        ...state,
        task,
      };
    }
    case SET_UNSAFE_ICON: {
      const { unsafeIcon, unsafeIconIndex } = action.payload;

      return {
        ...state,
        unsafeIcon,
        unsafeIconIndex,
      };
    }
    case TRIGGER_BACKGROUND_UPDATE:
      return {
        ...state,
        backgroundCount: state.backgroundCount + 1,
      };
    case TRIGGER_DRILL_RESET:
      return {
        ...state,
        drillResetCount: state.drillResetCount + 1,
      };
    default:
      return state;
  }
};

export default reducer;
