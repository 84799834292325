import { Backdrop, Fade, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 9999,
  },
  countdown: {
    color: '#eeeeee',
    fontSize: '200px',
    fontWeight: 'bold',
  },
});

const stage = ['Go!', 'Set...', 'Ready?'];

export function Countdown(props) {
  const { visible, onCountdownComplete } = props;
  const [fadeIn, setFadeIn] = useState(true);
  const [count, setCount] = useState(2);
  const classes = useStyles();

  const timer = useRef(null);

  useEffect(() => {
    if (!visible) {
      return;
    }

    timer.current = setTimeout(
      () => {
        if (!fadeIn) {
          setCount(count - 1);
        }

        setFadeIn(!fadeIn);
      },
      fadeIn ? 800 : 200
    );

    return () => clearTimeout(timer.current);
  }, [visible, count, fadeIn]);

  useEffect(() => {
    if (visible) {
      setCount(2);
    }
  }, [visible]);

  useEffect(() => {
    if (count < 0) {
      clearTimeout(timer.current);
      onCountdownComplete();
    }
  }, [count, onCountdownComplete]);

  return (
    <Backdrop open={visible} className={classes.backdrop}>
      <Fade in={fadeIn} timeout={{ enter: 800, exit: 200 }}>
        <Typography component="h1" variant="h1" className={classes.countdown}>
          {stage[count]}
        </Typography>
      </Fade>
    </Backdrop>
  );
}

Countdown.propTypes = {
  onCountdownComplete: PropTypes.func,
  visible: PropTypes.bool,
};

Countdown.defaultProps = {
  onCountdownComplete: () => {},
  visible: false,
};
