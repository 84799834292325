import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { plainToClass } from 'class-transformer';
import is from 'is_js';
import kebabCase from 'lodash/kebabCase';
import minBy from 'lodash/minBy';
import { reverse } from 'named-urls';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DrillStage, TrainingFrequency, User } from '../../models';
import { AuthContext, DrillProvider, EyeGymContext } from '../../providers';
import { actionCreators as drillActionCreators } from '../../redux/drill';
import { actionCreators as eyegymActionCreators } from '../../redux/eyegym';
import { routes } from '../../routes';
import { MaximumLevelReachedModal, TimeExhaustedModal } from '../Modals';

import { Countdown } from './Countdown';
import { Game } from './Game';
import { Score } from './Score';
import { Task } from './Task';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
}));

export function Drill(props) {
  const { drillId, drillName, level } = props;
  const { stage } = useSelector((state) => state.drill);

  const {
    assessment,
    assessmentDrillNumber,
    drills,
    user: user_,
    maximumLevelReachedModalShown,
  } = useSelector((state) => state.eyegym);

  const { logout } = useContext(AuthContext);
  const [maximumLevelReachedOpen, setMaximumLevelReachedOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [timeExhaustedOpen, setTimeExhaustedOpen] = useState(false);
  const { timeRemaining, trainingFrequency } = useContext(EyeGymContext);
  const classes = useStyles();
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [userAnswer, setUserAnswer] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleStart = useCallback(() => {
    setShowCountdown(true);
  }, []);

  const handleCountdownComplete = useCallback(() => {
    setShowCountdown(false);
    dispatch(drillActionCreators.setDrillStage(DrillStage.PLAY));
  }, [dispatch]);

  const handleComplete = useCallback(
    (options) => {
      if (is.object(options)) {
        const { correct, answer } = options;

        setShowAnswer(true);
        setCorrectAnswer(correct);
        setUserAnswer(is.number(answer) ? answer : "I don't know");
      }

      dispatch(drillActionCreators.setDrillStage(DrillStage.SCORE));
    },
    [dispatch]
  );

  const maxLevelReachedShown = useMemo(
    () => !!maximumLevelReachedModalShown[drillId],
    [drillId, maximumLevelReachedModalShown]
  );

  const user = useMemo(() => plainToClass(User, user_), [user_]);

  const trainingTimeExhaustedMessage = useMemo(() => {
    switch (trainingFrequency) {
      case TrainingFrequency.PER_WEEK:
        return 'You have finished your training for this week. Please come back next week to continue training.';
      case TrainingFrequency.PER_MONTH:
        return 'You have finished your training for this month. Please come back next month to continue training.';
      default:
        return 'You have finished your training for today. Please come back tomorrow to continue training.';
    }
  }, [trainingFrequency]);

  const handleReplay = useCallback(() => {
    if (!assessment && timeRemaining <= 0) {
      setTimeExhaustedOpen(true);
    } else {
      dispatch(drillActionCreators.setDrillStage(DrillStage.START));
      dispatch(drillActionCreators.triggerDrillReset());
    }
  }, [assessment, dispatch, timeRemaining]);

  const handleContinue = useCallback(() => {
    const remainingDrills = assessment.product.productDrills.filter((pd) => pd.order >= assessmentDrillNumber);
    const nextProductDrill = minBy(remainingDrills, (pd) => pd.order);
    const nextDrill = drills.find((d) => d.id === nextProductDrill.drillId);

    const route = reverse(`${routes.drill}`, {
      drillId: nextDrill.id,
      drillName: kebabCase(nextDrill.name),
      level: assessment.level,
    });

    dispatch(drillActionCreators.setDrillStage(DrillStage.START));

    navigate(route);
  }, [dispatch, assessment, drills, assessmentDrillNumber, navigate]);

  const handleFinish = useCallback(() => {
    if (user?.redirectAfterAssessment) {
      if (is.not.online()) {
        dispatch(eyegymActionCreators.setPostLogoutUrl(routes.default));
        logout();

        return;
      } else if (user?.redirectUrl) {
        const url = user?.token ? `${user.redirectUrl}${user.token}` : user.redirectUrl.split('?')[0];
        dispatch(eyegymActionCreators.setPostLogoutUrl(url));
        logout();

        return;
      }
    }

    dispatch(eyegymActionCreators.setCurrentAssessment(null));
    navigate(routes.default);
  }, [dispatch, navigate, logout, user?.redirectAfterAssessment, user?.redirectUrl, user?.token]);

  const handleNext = useCallback(() => {
    if (!assessment && timeRemaining < 0) {
      setTimeExhaustedOpen(true);
    } else if (level === user.getMaximumLevel() && !maxLevelReachedShown) {
      setMaximumLevelReachedOpen(true);
    } else {
      const nextLevel = level === user.getMaximumLevel() ? level : level + 1;

      const route = reverse(`${routes.drill}`, {
        drillId,
        drillName,
        level: nextLevel,
      });

      dispatch(drillActionCreators.setDrillStage(DrillStage.START));

      navigate(route);
    }
  }, [dispatch, assessment, drillId, drillName, navigate, level, maxLevelReachedShown, timeRemaining, user]);

  const handleTimeExhaustedClose = () => {
    setTimeExhaustedOpen(false);
  };

  const handleMaximumLevelReachedClose = () => {
    setMaximumLevelReachedOpen(false);
    dispatch(eyegymActionCreators.setMaximumLevelReachedModalShown(drillId));
  };

  useEffect(
    () => () => {
      dispatch(drillActionCreators.setDrillStage(DrillStage.START));
    },
    [dispatch]
  );

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={2}>
        <DrillProvider drillId={drillId} level={level}>
          {!stage || (stage === 'start' && <Task onStart={handleStart} />)}
          <Countdown visible={showCountdown} onCountdownComplete={handleCountdownComplete} />
          {stage === 'play' && <Game onComplete={handleComplete} />}
          {stage === 'score' && (
            <Score
              showAnswer={showAnswer}
              correctAnswer={correctAnswer}
              userAnswer={userAnswer}
              onReplay={handleReplay}
              onContinue={handleContinue}
              onNext={handleNext}
              onFinish={handleFinish}
            />
          )}
        </DrillProvider>
        <TimeExhaustedModal
          keepMounted
          message={trainingTimeExhaustedMessage}
          open={timeExhaustedOpen}
          onClose={handleTimeExhaustedClose}
        />
        <MaximumLevelReachedModal open={maximumLevelReachedOpen} onClose={handleMaximumLevelReachedClose} />
      </Grid>
    </Container>
  );
}

Drill.propTypes = {
  drillId: PropTypes.string.isRequired,
  drillName: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
};
