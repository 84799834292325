/* eslint-disable max-classes-per-file */
import _random from 'lodash/random';

const getHex = (d, padding) => {
  let hex = Number(d).toString(16);
  const p = padding || 2;

  while (hex.length < p) {
    hex = `0${hex}`;
  }

  return hex;
};

export class RgbColour {
  constructor(r = 0, g = 0, b = 0) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  getWebColour(prefix = '') {
    const self = this;

    return `${prefix}${getHex(self.r)}${getHex(self.g)}${getHex(self.b)}`;
  }

  static random = () => new RgbColour(_random(0, 255), _random(0, 255), _random(0, 255));
}

const hueToRgb = (p, q, t) => {
  let _t = t;

  if (_t < 0) {
    _t += 1;
  }

  if (_t > 1) {
    _t -= 1;
  }

  if (_t < 1 / 6) {
    return p + (q - p) * 6 * _t;
  }

  if (_t < 1 / 2) {
    return q;
  }

  if (_t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }

  return p;
};

const rgbToHsl = (r, g, b) => {
  const red = r / 255;
  const green = g / 255;
  const blue = b / 255;

  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);

  const lightness = (max + min) / 2;
  let hue;
  let saturation;

  if (max === min) {
    hue = 0;
    saturation = 0;
  } else {
    const delta = max - min;
    saturation = lightness > 0.5 ? delta / (2 - min - max) : delta / (max + min);

    switch (max) {
      case red:
        hue = (green - blue) / delta + (green < blue ? 6 : 0);
        break;
      case green:
        hue = (blue - red) / delta + 2;
        break;
      default:
        hue = (red - green) / delta + 4;
        break;
    }

    hue /= 6;
  }

  return [hue, lightness, saturation];
};

export class HslColour {
  constructor(h = 1, s = 1, l = 1) {
    this.hue = h;
    this.saturation = s;
    this.lightness = l;
  }

  toRgbColour() {
    const self = this;

    if (self.saturation === 0) {
      return new RgbColour(self.lightness, self.lightness, self.lightness);
    }

    const q =
      self.lightness < 0.5
        ? self.lightness * (1 + self.saturation)
        : self.lightness + self.saturation - self.lightness * self.saturation;

    const p = 2 * self.lightness - q;
    const red = hueToRgb(p, q, self.hue + 1 / 3);
    const green = hueToRgb(p, q, self.hue);
    const blue = hueToRgb(p, q, self.hue - 1 / 3);

    return new RgbColour(Math.floor(red * 255), Math.floor(green * 255), Math.floor(blue * 255));
  }

  getWebColour(prefix = '') {
    const self = this;

    return self.toRgbColour().getWebColour(prefix);
  }

  static random = () => new HslColour(_random(0, 360) / 360, _random(0, 360) / 360, _random(0, 360) / 360);

  static fromRgbColour = (rgbColour) => {
    const { r, g, b } = rgbColour;
    const [hue, saturation, lightness] = rgbToHsl(r, g, b);

    return new HslColour(hue, saturation, lightness);
  };
}
