/* eslint-disable class-methods-use-this */
export const Arrow = {
  down: { index: 22, name: 'Down' },
  left: { index: 23, name: 'Left' },
  right: { index: 24, name: 'Right' },
  up: { index: 21, name: 'Up' },
};

export const Behaviour = {
  NORMAL: 'Normal',
  SHAPE_SHIFT_ALL: 'ShapeShiftAll',
  SHAPE_SHIFT_TARGET: 'ShapeShiftTarget',
  UNKNOWN: 'Unknown',
};

export const DrillStage = {
  PLAY: 'play',
  SCORE: 'score',
  START: 'start',
  TASK: 'task',
};

export const DrillType = {
  BATAK: 'Batak',
  EYE_BOUNCE: 'EyeBounce',
  EYE_HAND_REACTION: 'EyeHandReaction',
  EYE_HAND_TIMING: 'EyeHandTiming',
  EYE_JUDGE: 'EyeJudge',
  EYE_JUDGE_LOWEST: 'EyeJudgeLowest',
  EYE_JUMPS: 'EyeJumps',
  EYE_PUSHUPS: 'EyePushups',
  EYE_RUN: 'EyeRun',
  EYE_SITUPS: 'EyeSitups',
  EYE_SKIPPING: 'EyeSkipping',
  EYE_SPEED: 'EyeSpeed',
  EYE_SPIN: 'EyeSpin',
  EYE_STRETCH: 'EyeStretch',
  EYE_TRACKING: 'EyeTracking',
  GREEN_RED_LIGHT: 'GreenRedLight',
  LINE_TRACKING: 'LineTracking',
  LINE_TRACKING_MULTIPLE: 'LineTrackingMultiple',
  PERCEPTION_SPEED: 'PerceptionSpeed',
  PERIPHERAL_ARROWS: 'PeripheralArrows',
  PERIPHERAL_AWARENESS: 'PeripheralAwareness',
  PERIPHERAL_RESPONSE: 'PeripheralResponse',
  SCAN_REACT: 'ScanReact',
  START_LIGHTS: 'StartLights',
  STEREOGRAM: 'Stereogram',
  TRACK_CORRECT: 'TrackCorrect',
  WARM_UP: 'WarmUp',
};

export const GoalType = {
  BATAK: 'Batak',
  COUNT_CORRECT: 'CountCorrect',
  EYE_PUSHUPS: 'EyePushups',
  EYE_RUN: 'EyeRun',
  EYE_SITUPS: 'EyeSitUps',
  EYE_SPIN: 'EyeSpin',
  EYE_SQUATS: 'EyeSquats',
  EYE_STRETCH: 'EyeStretch',
  GREEN_RED_LIGHT: 'GreenRedLight',
  IDENTIFY_CORRECT: 'IdentifyCorrect',
  IDENTIFY_HIGHEST: 'IdentifyHighest',
  IDENTIFY_LOWEST: 'IdentifyLowest',
  IDENTIFY_NEXT: 'IdentifyNext',
  IDENTIFY_SMALLEST: 'IdentifySmallest',
  LINE_TRACKING: 'LineTracking',
  LINE_TRACKING_MULTIPLE: 'LineTrackingMultiple',
  REMEMBER: 'Remember',
  STOP_CENTRE: 'StopCentre',
  TRACK_CORRECT: 'TrackCorrect',
  TRAFFIC_LIGHTS: 'TrafficLights',
  UNKNOWN: 'Unknown',
  WARMUP: 'Warmup',
};

export const ObjectSymbol = {
  ARROW: 'Arrow',
  NONE: 'None',
  NUMBER: 'Number',
};

export const ObjectMovement = {
  BOUNCE: 'Bounce',
  CIRCULAR: 'Circular',
  HORIZONTAL: 'Horizontal',
  MOVE_DOWN: 'MoveDown',
  MOVE_LEFT: 'MoveLeft',
  MOVE_LINEAR: 'MoveLinear',
  MOVE_OUTWARDS: 'MoveOutwards',
  MOVE_RIGHT: 'MoveRight',
  MOVE_UP: 'MoveUp',
  NONE: 'None',
  PULSE: 'Pulse',
  RANDOM_BOUNCE: 'RandomBounce',
  VERTICAL: 'Vertical',
};

export const ObjectVariety = {
  ALL_CORRECT: 'AllCorrect',
  ALL_RANDOM: 'AllRandom',
  CORRECT_CORRECT_RANDOM: 'CorrectCorrectRandom',
  CORRECT_RANDOM: 'CorrectRandom',
  CORRECT_SAME: 'CorrectSame',
  UNKNOWN: 'Unknown',
};

export const InputDirection = {
  BOTTOM_TOP: 4,
  LEFT_RIGHT: 1,
  RIGHT_LEFT: 2,
  TOP_BOTTOM: 3,
  UNKNOWN: 0,
};

export const InputType = {
  ARROWS: 'Arrows',
  LEFT_RIGHT: 'LeftRight',
  MOUSE: 'Mouse',
  NONE: 'None',
  NUMBERS: 'Numbers',
  QUESTION: 'Question',
  SPACEBAR: 'Spacebar',
  UNKNOWN: 'Unknown',
};

export const BackgroundType = {
  GRADIENT: 'Gradient',
  IMAGE: 'Image',
  PLAIN_COLOUR: 'PlainColour',
  RANDOM: 'Random',
  UNKNOWN: 'Unknown',
};

export const StartPosition = {
  BORDER: 'Border',
  CENTRE: 'Centre',
  PERIPHERAL: 'Peripheral',
  PERIPHERAL_ROWS: 'PeripheralRows',
  PUZZLE: 'Puzzle',
  RANDOM: 'Random',
  READING_ROWS: 'ReadingRows',
};

export class Drill {
  constructor() {
    this._inputDirection = null;
    this._showTarget = null;
    this._targetIcon = null;
    this._targetIconId = null;
    this._targetIconIndex = null;
    this._targetMovement = null;
    this._task = null;
  }

  get inputDirection() {
    return this._inputDirection;
  }

  set inputDirection(value) {
    this._inputDirection = value;
  }

  get objectSymbol() {
    switch (this.drillType) {
      case DrillType.EYE_SPEED:
      case DrillType.EYE_TRACKING:
      case DrillType.SCAN_REACT:
        return ObjectSymbol.NUMBER;
      case DrillType.EYE_JUDGE_LOWEST:
      case DrillType.EYE_JUMPS:
      case DrillType.EYE_SKIPPING:
      case DrillType.EYE_SPIN:
      case DrillType.PERIPHERAL_ARROWS:
        return ObjectSymbol.ARROW;
      default:
        return ObjectSymbol.NONE;
    }
  }

  get showCanHaveCustomIconSetOption() {
    return [
      DrillType.EYE_BOUNCE,
      DrillType.EYE_HAND_REACTION,
      DrillType.EYE_HAND_TIMING,
      DrillType.EYE_JUMPS,
      DrillType.EYE_SPEED,
      DrillType.EYE_TRACKING,
      DrillType.PERCEPTION_SPEED,
      DrillType.PERIPHERAL_RESPONSE,
    ].includes(this.drillType);
  }

  get needsTarget() {
    if (
      [
        GoalType.COUNT_CORRECT,
        GoalType.EYE_PUSHUPS,
        GoalType.EYE_STRETCH,
        GoalType.IDENTIFY_CORRECT,
        GoalType.IDENTIFY_SMALLEST,
        GoalType.LINE_TRACKING_MULTIPLE,
        GoalType.STOP_CENTRE,
        GoalType.TRACK_CORRECT,
      ].includes(this.goalType) &&
      !(this.showCorrect || this.backgroundType === 'Stereogram')
    ) {
      return true;
    }

    if (
      [GoalType.EYE_SPIN, GoalType.EYE_RUN, GoalType.TRAFFIC_LIGHTS, GoalType.LINE_TRACKING].includes(this.goalType)
    ) {
      return true;
    }

    return false;
  }

  get showArrowSetOptions() {
    return ![DrillType.LINE_TRACKING_MULTIPLE, DrillType.BATAK, DrillType.GREEN_RED_LIGHT].includes(this.drillType);
  }

  get showBehaviourOptions() {
    return this.drillType === DrillType.EYE_BOUNCE;
  }

  get showGoalOptions() {
    return this.drillType === DrillType.EYE_JUMPS;
  }

  get showHideIconOption() {
    return this.drillType === DrillType.EYE_BOUNCE;
  }

  get showInputOptions() {
    return [DrillType.EYE_STRETCH, DrillType.LINE_TRACKING].includes(this.drillType);
  }

  get showObjectIncrementOption() {
    return [DrillType.EYE_SPEED].includes(this.drillType);
  }

  get showObjectVarietyOptions() {
    return [DrillType.EYE_BOUNCE, DrillType.EYE_JUMPS].includes(this.drillType);
  }

  get showOptions() {
    return this.drillType !== DrillType.STEREOGRAM;
  }

  get showSymbolOptions() {
    return this.showOptions && (this.objectSymbol !== ObjectSymbol.NONE || this.drillType === DrillType.EYE_RUN);
  }

  get showShowCentreOption() {
    return this.showOptions && [DrillType.EYE_JUMPS, DrillType.EYE_SKIPPING].includes(this.drillType);
  }

  get showShowCorrectOption() {
    return this.showOptions && this.drillType === DrillType.EYE_JUMPS;
  }

  get showTarget() {
    return this._showTarget;
  }

  set showTarget(value) {
    this._showTarget = value;
  }

  get showRemoveOption() {
    return this.showOptions && this.drillType === DrillType.EYE_TRACKING;
  }

  get showShowSymbolOnlyOption() {
    return this.showOptions && [DrillType.EYE_JUMPS, DrillType.EYE_TRACKING].includes(this.drillType);
  }

  get showShowSymbolTargetOption() {
    return this.showOptions && this.validInputTypes.includes(this.inputType);
  }

  get targetIcon() {
    return this._targetIcon;
  }

  set targetIcon(value) {
    this._targetIcon = value;
  }

  get targetIconId() {
    return this._targetIconId;
  }

  set targetIconId(value) {
    this._targetIconId = value;
  }

  get targetIconIndex() {
    return this._targetIconIndex;
  }

  set targetIconIndex(value) {
    this._targetIconIndex = value;
  }

  get targetMovement() {
    return this._targetMovement;
  }

  set targetMovement(value) {
    this._targetMovement = value;
  }

  get task() {
    return this._task;
  }

  set task(value) {
    this._task = value;
  }

  get hasRows() {
    return [DrillType.LINE_TRACKING, DrillType.LINE_TRACKING_MULTIPLE].includes(this.drillType);
  }

  get validGoalTypes() {
    return [GoalType.IDENTIFY_CORRECT, GoalType.IDENTIFY_NEXT];
  }

  get validInputTypes() {
    return [InputType.ARROWS, InputType.MOUSE];
  }

  get validObjectVarieties() {
    return [ObjectVariety.ALL_RANDOM, ObjectVariety.CORRECT_RANDOM];
  }
}
