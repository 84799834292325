import is from 'is_js';

import { api } from '../api';
import { actionCreators } from '../redux/authentication';
import { routes } from '../routes';
import { postData } from '../utils';

import { PasswordHasher } from './PasswordHasher';

export const signInWithToken = async (token, returnUrl, context) => {
  if (!context) {
    return false;
  }

  if (is.not.online()) {
    return false;
  }

  const uri = api.rest.accounts.signInWithToken;
  const { log } = context;

  const body = {
    returnUrl,
    token,
  };

  try {
    const response = await postData(uri, body);

    return response;
  } catch (error) {
    log.error('Error: {error} occurred at {source}', {
      error,
      source: 'services.accountsService.signInWithToken',
    });

    return error;
  }
};

export const signIn = async (username, password, rememberMe, returnUrl, context) => {
  if (!context) {
    return false;
  }

  if (is.not.online()) {
    const { store } = context;

    const {
      offlineUsers: { users },
    } = store.getState();

    const user = Object.values(users).find((u) => u.username.toLowerCase() === username.toLowerCase());

    if (user) {
      const hasher = new PasswordHasher();
      const authenticated = hasher.verifyHashedPassword(user.passwordHash, password);

      if (authenticated) {
        user.profile = { role: user.roles, sub: user.id };
        await store.dispatch(actionCreators.loggedIn(user));

        return { returnUrl: routes.default };
      }
    }
  }

  const uri = api.rest.accounts.signIn;
  const { log } = context;

  const body = {
    password,
    rememberMe,
    returnUrl,
    username,
  };

  try {
    const response = await postData(uri, body);

    return response;
  } catch (error) {
    log.error('Error: {error} occurred at {source}', {
      error,
      source: 'services.AccountsService.signIn',
    });

    return error;
  }
};

export const signOut = (returnUrl, context) => async () => {
  const uri = api.rest.accounts.signOut(returnUrl);
  const { log } = context;

  try {
    const response = await postData(uri);

    return response;
  } catch (error) {
    log.error('Error: {error} occurred at {source}', {
      error,
      source: 'services.AccountsService.signOut',
    });

    return error;
  }
};
