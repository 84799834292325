import { Container } from '@inlet/react-pixi';
import detectTouchEvents from 'detect-touch-events';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import seedrandom from 'seedrandom';

import { BackgroundType, GoalType, RgbColour } from '../../../models';
import { DrillContext, EyeGymContext } from '../../../providers';

import { GradientBackground } from './GradientBackground';
import { ImageBackground } from './ImageBackground';
import { PlainBackground } from './PlainBackground';
import { RandomBackground } from './RandomBackground';

export function Background(props) {
  const { width, height } = props;
  const { drill, backgrounds } = useContext(DrillContext);
  const { assessment, user } = useContext(EyeGymContext);
  const { backgroundCount } = useSelector((state) => state.drill);

  const getBackgroundComponent = useCallback(() => {
    seedrandom(backgroundCount);

    if (assessment && !assessment.isCompleted) {
      return <PlainBackground width={width} height={height} colour="0x00102a" />;
    }

    if (drill?.backgroundType === BackgroundType.RANDOM) {
      return <RandomBackground width={width} height={height} />;
    }

    if (
      detectTouchEvents.hasSupport &&
      drill?.backgroundType === BackgroundType.GRADIENT &&
      drill?.goalType !== GoalType.EYE_SITUPS
    ) {
      const colour = RgbColour.random().getWebColour('0x');

      return <PlainBackground width={width} height={height} colour={colour} />;
    }

    if (
      user?.lowPerformance ||
      (user?.team?.lowPerformance &&
        drill?.backgroundType === BackgroundType.GRADIENT &&
        drill?.goalType !== GoalType.EYE_SITUPS)
    ) {
      const colour = RgbColour.random().getWebColour('0x');

      return <PlainBackground width={width} height={height} colour={colour} />;
    }

    switch (drill?.backgroundType) {
      case BackgroundType.GRADIENT: {
        const colour1 = RgbColour.random().getWebColour('#');
        const colour2 = RgbColour.random().getWebColour('#');

        return <GradientBackground width={width} height={height} colour1={colour1} colour2={colour2} />;
      }
      case BackgroundType.IMAGE: {
        if (backgrounds.length) {
          return <ImageBackground width={width} height={height} />;
        }

        const colour = RgbColour.random().getWebColour('0x');

        return <PlainBackground width={width} height={height} colour={colour} />;
      }
      default: {
        const colour = RgbColour.random().getWebColour('0x');

        return <PlainBackground width={width} height={height} colour={colour} />;
      }
    }
  }, [assessment, backgrounds?.length, drill, height, user, width, backgroundCount]);

  return <Container zIndex={100}>{getBackgroundComponent()}</Container>;
}

Background.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
