/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-curly-newline */
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Tooltip } from '@mui/material';
import React from 'react';

export function TooltipTableCell(props) {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        value ? (
          <Tooltip title={value} aria-label={value}>
            <span>{value}</span>
          </Tooltip>
        ) : (
          ''
        )
      }
      {...props}
    />
  );
}
