/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-fragments */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Log } from 'oidc-client';
import React from 'react';
import { Route, Routes } from 'react-router';

import { Assessment, Default, Disclaimer, Drill, LoggedOut, Team } from '../pages';
import { routes } from '../routes';

import { AuthorizeRoute, Callback, Login, Logout, LogoutCallback, SilentRenew } from './Auth';
import { Layout } from './Layout';
import './root.scss';





Log.reset();

// Log.logger = console;
// Log.level = Log.DEBUG;

export function Root() {
  return (
    <Layout>
      <Routes>
        <Route
          path={routes.default}
          element={(
            <AuthorizeRoute>
              <Default />
            </AuthorizeRoute>
          )}
        />
        <Route
          path={routes.assessment}
          element={(
            <AuthorizeRoute>
              <Assessment />
            </AuthorizeRoute>
          )}
        />
        <Route
          path={routes.disclaimer}
          element={(
            <AuthorizeRoute>
              <Disclaimer />
            </AuthorizeRoute>
          )}
        />
        <Route
          path={routes.drill}
          element={(
            <AuthorizeRoute>
              <Drill />
            </AuthorizeRoute>
          )}
        />
        <Route
          path={routes.team}
          element={(
            <AuthorizeRoute>
              <Team />
            </AuthorizeRoute>
          )}
        />
        <Route path={routes.loggedOut} element={<LoggedOut />} />
        <Route path={routes.account.login} element={<Login />} />
        <Route path={routes.account.loginCallback} element={<Callback />} />
        <Route path={routes.account.logout} element={<Logout />} />
        <Route path={routes.account.logoutCallback} element={<LogoutCallback />} />
        <Route path={routes.account.signinOidc} element={<Callback />} />
        <Route path={routes.account.silentRenew} element={<SilentRenew />} />
      </Routes>
    </Layout>
  );
}
