import difference from 'lodash/difference';
import random from 'lodash/random';
import range from 'lodash/range';

export const randomEx = (min, max, exclude) => {
  const numbers = range(min, max + 1);

  if (!Array.isArray(exclude)) {
    // eslint-disable-next-line no-param-reassign
    exclude = [exclude];
  }

  const validNumbers = difference(numbers, exclude);
  const index = random(0, validNumbers.length - 1);

  return validNumbers[index];
};
