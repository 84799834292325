import {
  CLEAR_STORE,
  SET_DRILL_STAGE,
  SET_HAZARD_ICON,
  SET_INPUT_DIRECTION,
  SET_LAST_BACKGROUND_INDEX,
  SET_LAST_DIRECTION,
  SET_SAFE_ICON,
  SET_SHOW_SCORE,
  SET_SHOW_TARGET,
  SET_TARGET_ICON,
  SET_TARGET_MOVEMENT,
  SET_TASK,
  SET_UNSAFE_ICON,
  TRIGGER_BACKGROUND_UPDATE,
  TRIGGER_DRILL_RESET,
} from './actionTypes';

const clearStore = () => ({
  type: CLEAR_STORE,
});

const setDrillStage = (stage) => ({
  payload: {
    stage,
  },
  type: SET_DRILL_STAGE,
});

const setHazardIcon = (hazardIcon, hazardIconIndex) => ({
  payload: {
    hazardIcon,
    hazardIconIndex,
  },
  type: SET_HAZARD_ICON,
});

const setInputDirection = (inputDirection) => ({
  payload: {
    inputDirection,
  },
  type: SET_INPUT_DIRECTION,
});

const setLastBackgroundIndex = (lastBackgroundIndex) => ({
  payload: {
    lastBackgroundIndex,
  },
  type: SET_LAST_BACKGROUND_INDEX,
});

const setLastDirection = (lastDirection) => ({
  payload: {
    lastDirection,
  },
  type: SET_LAST_DIRECTION,
});

const setSafeIcon = (safeIcon, safeIconIndex) => ({
  payload: {
    safeIcon,
    safeIconIndex,
  },
  type: SET_SAFE_ICON,
});

const setShowScore = (showScore, score) => ({
  payload: {
    score,
    showScore,
  },
  type: SET_SHOW_SCORE,
});

const setShowTarget = (showTarget) => ({
  payload: {
    showTarget,
  },
  type: SET_SHOW_TARGET,
});

const setTargetIcon = (targetIcon, targetIconId, targetIconIndex) => ({
  payload: {
    targetIcon,
    targetIconId,
    targetIconIndex,
  },
  type: SET_TARGET_ICON,
});

const setTargetMovement = (targetMovement) => ({
  payload: {
    targetMovement,
  },
  type: SET_TARGET_MOVEMENT,
});

const setTask = (task) => ({
  payload: {
    task,
  },
  type: SET_TASK,
});

const setUnsafeIcon = (unsafeIcon, unsafeIconIndex) => ({
  payload: {
    unsafeIcon,
    unsafeIconIndex,
  },
  type: SET_UNSAFE_ICON,
});

const triggerBackgroundUpdate = () => ({
  type: TRIGGER_BACKGROUND_UPDATE,
});

const triggerDrillReset = () => ({
  type: TRIGGER_DRILL_RESET,
});

export const actionCreators = {
  clearStore,
  setDrillStage,
  setHazardIcon,
  setInputDirection,
  setLastBackgroundIndex,
  setLastDirection,
  setSafeIcon,
  setShowScore,
  setShowTarget,
  setTargetIcon,
  setTargetMovement,
  setTask,
  setUnsafeIcon,
  triggerBackgroundUpdate,
  triggerDrillReset,
};
