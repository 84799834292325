import { Graphics } from '@inlet/react-pixi';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { RgbColour } from '../../../models';
import { gradient } from '../../../utils';

export function GradientBackground(props) {
  const { colour1, colour2 } = props;

  const { height, width } = props;

  const draw = useCallback(
    (g) => {
      g.clear();
      g.beginTextureFill({ texture: gradient(colour1, colour2, width * 3, height * 3) });
      g.drawRect(0, 0, width * 3, height * 3);
      g.endFill();
    },
    [colour1, colour2, width, height]
  );

  return <Graphics draw={draw} />;
}

GradientBackground.propTypes = {
  colour1: PropTypes.string,
  colour2: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

GradientBackground.defaultProps = {
  colour1: RgbColour.random().getWebColour('#'),
  colour2: RgbColour.random().getWebColour('#'),
};
