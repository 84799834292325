export class Event {
  constructor(name) {
    this._name = name;
    this._callbacks = {};
  }

  addHandler(key, cb) {
    if (!this._callbacks[key]) {
      this._callbacks[key] = cb;
    }
  }

  removeHandler(key) {
    delete this._callbacks[key];
  }

  raise(...params) {
    let promises = [];

    for (let key in this._callbacks) {
      const promise = new Promise((resolve, reject) => {
        try {
          const result = this._callbacks[key](...params);
          resolve(result);
        } catch {
          reject();
        }
      });

      promises.push(promise);
    }

    return Promise.all(promises);
  }
}
