import random from 'lodash/random';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import seedrandom from 'seedrandom';

import { RgbColour } from '../../../models';
import { DrillContext } from '../../../providers';

import { GradientBackground } from './GradientBackground';
import { ImageBackground } from './ImageBackground';
import { PlainBackground } from './PlainBackground';

export function RandomBackground(props) {
  const { width, height } = props;
  const { backgrounds } = useContext(DrillContext);
  const [index, setIndex] = useState(random(0, 2));
  const [colour, setColour] = useState(RgbColour.random().getWebColour('0x'));
  const [colour1, setColour1] = useState(RgbColour.random().getWebColour('#'));
  const [colour2, setColour2] = useState(RgbColour.random().getWebColour('#'));
  const { backgroundCount } = useSelector((state) => state.drill);

  useEffect(() => {
    seedrandom(backgroundCount);
    setIndex(random(0, 2));
    setColour(RgbColour.random().getWebColour('0x'));
    setColour1(RgbColour.random().getWebColour('#'));
    setColour2(RgbColour.random().getWebColour('#'));
  }, [backgroundCount]);

  return (
    <>
      {index === 0 && <GradientBackground width={width} height={height} colour1={colour1} colour2={colour2} />}
      {index === 1 && backgrounds?.length && <ImageBackground width={width} height={height} />}
      {(index === 2 || !backgrounds?.length) && <PlainBackground width={width} height={height} colour={colour} />}
    </>
  );
}

RandomBackground.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
