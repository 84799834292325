/* eslint-disable react/jsx-no-useless-fragment */
import PortableWifiOffIcon from '@mui/icons-material/PortableWifiOff';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import useOnlineStatus from '@rehooks/online-status';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

export function OfflineIcon() {
  const onlineStatus = useOnlineStatus();
  const classes = useStyles();

  return (
    <>
      {!onlineStatus ? (
        <PortableWifiOffIcon className={classes.icon} fontSize="large" style={{ color: red[500] }} />
      ) : (
        <></>
      )}
    </>
  );
}
