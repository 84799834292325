import { api } from '../api';
import { fetchData } from '../utils';

export const fetchSettings = (context) => async () => {
  if (!context) {
    return;
  }

  const url = api.rest.settings;
  const { log } = context;

  try {
    const response = await fetchData(url);

    return response;
  } catch (error) {
    log.error('Error: {error} occurred at {source}', {
      error,
      source: 'services.SettingsService.fetchSettings',
    });
  }
};
