import { include } from 'named-urls';

export const routes = {
  account: include('/account', {
    login: '/login',
    loginCallback: '/login/callback',
    logout: '/logout',
    logoutCallback: '/logout/callback',
    signinOidc: '/signin-oidc',
    silentRenew: '/silent-renew',
  }),
  assessment: '/assessment',
  default: '/',
  disclaimer: '/disclaimer',
  drill: '/drill/:drillId/:drillName/level/:level',
  loggedOut: '/logged-out',
  team: '/team',
};
