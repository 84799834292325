/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import React from 'react';
import './Loader.scss';

export function Loader(props) {
  const { visible } = props;

  return visible ? <div className="lds-dual-ring" /> : <></>;
}

Loader.propTypes = {
  visible: PropTypes.bool,
};

Loader.defaultProps = {
  visible: true,
};
