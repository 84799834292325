/* eslint-disable default-param-last */
import { REHYDRATE_STORE } from './actionTypes';

export const rehydrateState = {
  rehydrated: false,
};

const reducer = (state = rehydrateState, action) => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case REHYDRATE_STORE:
      return {
        rehydrated: true,
      };
    default:
      return state;
  }
};

export default reducer;
