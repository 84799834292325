export class FetchError extends Error {
  constructor(message, status) {
    super(message);
    this._status = status;
  }

  get status() {
    return this._status;
  }
}

export class FetchErrorEx extends Error {
  constructor(meta = {}) {
    super(meta.client ? meta.cause : `Server error ${meta.status}`);
    Object.assign(this, meta);
    this.name = 'FetchErrorEx';
  }
}
