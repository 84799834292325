import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { AuthContext, LogContext } from '../../providers';
import { Loader } from '../Loader';

const useStyles = makeStyles({
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

const clearState = () => {
  localStorage.clear();
  sessionStorage.clear();
  const cookies = document.cookie;

  for (const cookie in cookies.split(';')) {
    const pos = cookie.indexOf('=');

    const name = pos > -1 ? cookie.substring(0, pos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

export function AuthorizeRoute(props) {
  const { isAuthenticated, signinRedirect } = useContext(AuthContext);
  const classes = useStyles();
  const { log } = useContext(LogContext);
  const { children } = props;
  const location = useLocation();
  const { search } = location;
  const values = queryString.parse(search);
  const { token } = values;

  if (isAuthenticated()) {
    return children;
  }

  if (token) {
    clearState();
  }

  signinRedirect(token, { log });

  return (
    <div className={classes.loader}>
      <Loader />
    </div>
  );
}

AuthorizeRoute.propTypes = {
  children: PropTypes.any.isRequired,
};
