import React from 'react';
import { useParams } from 'react-router-dom';

import { Drill } from '../components';

export function DrillPage() {
  const { drillId, drillName, level } = useParams();

  return <Drill drillId={drillId} level={Number(level)} drillName={drillName} />;
}
