import { Box, Container, Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { actionCreators as authentication } from '../redux/authentication';
import { routes } from '../routes';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '300px',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function LoggedOut(props) {
  const dispatch = useDispatch();
  const { postLogoutUrl } = useSelector((state) => state.eyegym);
  const classes = useStyles(props);

  useEffect(() => {
    dispatch(authentication.loggedOut());

    if (postLogoutUrl) {
      window.location = postLogoutUrl;
    }
  }, [dispatch, postLogoutUrl]);

  useEffect(() => {
    if (document) {
      document.title = 'EyeGym | Signed out';
    }
  }, []);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box className={clsx(classes.column, classes.card)}>
        <Box className={classes.row}>
          <Box className={classes.stretched}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap, classes.header)}>
                  <Box className={clsx(classes.headline, classes.stretched)}>Signed out</Box>
                </Box>
                <Box className={clsx(classes.stretched, classes.content)}>
                  <Typography align="center">
                    You have successfully signed out. Click&nbsp;
                    <Link to={routes.default} component={RouterLink} underline="hover">
                      here
                    </Link>
                    &nbsp;to sign in again.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
