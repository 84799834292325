/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import range from 'lodash/range';
import { reverse } from 'named-urls';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DrillContext } from '../../../providers';
import { routes } from '../../../routes';

const useStyles = makeStyles({
  label: {
    marginBottom: 0,
    paddingRight: '8px',
  },
});

export function PreviousLevelDropdown(props) {
  const { drillId, drillName, level } = props;
  const { userLevel } = useContext(DrillContext);

  const [levels, setLevels] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (!userLevel) {
      return;
    }

    setLevels(range(1, userLevel.level + 1));
  }, [userLevel]);

  const handleChange = (event) => {
    const route = reverse(`${routes.drill}`, {
      drillId,
      drillName,
      level: event.target.value,
    });

    navigate(route);
  };

  return (
    <>
      {levels && levels.length && level && userLevel.level > 1 && (
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <label htmlFor="select-level" className={classes.label}>
            Level
          </label>
          <TextField select id="select-level" value={level} size="small" onChange={handleChange}>
            {levels.map((l) => (
              <MenuItem key={l} value={l}>
                {l}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
    </>
  );
}

PreviousLevelDropdown.propTypes = {
  drillId: PropTypes.any.isRequired,
  drillName: PropTypes.any.isRequired,
  level: PropTypes.number.isRequired,
};
