/* eslint-disable react/jsx-no-useless-fragment */
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../redux/authentication';

import { Loader } from './Loader';

const useStyles = makeStyles({
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

export function Rehydrate(props) {
  const { children } = props;
  const { rehydrated } = useSelector((state) => state.rehydrate);
  const { isSilentRenew } = useSelector((state) => state.authentication);
  const [silentRenew] = useState(isSilentRenew);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (rehydrated && silentRenew) {
      dispatch(actionCreators.setSilentRenew(false));
    }
  }, [dispatch, rehydrated, silentRenew]);

  return (
    <>
      {rehydrated ? (
        children
      ) : (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
    </>
  );
}

Rehydrate.propTypes = {
  children: PropTypes.any.isRequired,
};
