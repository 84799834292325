import FibreManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Button, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import kebabCase from 'lodash/kebabCase';
import minBy from 'lodash/minBy';
import { reverse } from 'named-urls';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DrillStage } from '../models';
import { actionCreators as drillActionCreators } from '../redux/drill';
import { actionCreators as eyegymActionCreators } from '../redux/eyegym';
import { routes } from '../routes';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '10em',
    width: '150px',
  },
  buttonContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} 0`,
    textAlign: 'center',
    width: '100%',
  },
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  centered: {
    margin: 'auto',
    width: 'fit-content',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  marginAuto: {
    margin: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function Assessment() {
  const { assessment, drills } = useSelector((state) => state.eyegym);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(drillActionCreators.setDrillStage(DrillStage.START));
    dispatch(eyegymActionCreators.setPostLogoutUrl(null));
    dispatch(eyegymActionCreators.setAssessmentDrillNumber(-1));
  }, [dispatch]);

  useEffect(() => {
    if (document) {
      document.title = 'EyeGym | Assessment';
    }
  }, []);

  const drill = useMemo(() => {
    if (!(assessment && drills)) {
      return null;
    }

    const first = minBy(assessment.product.productDrills, (d) => d.order);

    if (first) {
      const _drill = drills.find((pd) => pd.id === first.drillId);

      return _drill;
    }

    return null;
  }, [assessment, drills]);

  const onStart = useCallback(() => {
    if (!(drill && assessment)) {
      return;
    }

    const url = reverse(`${routes.drill}`, {
      drillId: drill.id,
      drillName: kebabCase(drill.name),
      level: assessment.level,
    });

    navigate(url);
  }, [assessment, drill, navigate]);

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={clsx(classes.column, classes.card)}>
            <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap, classes.header)}>
              <Box className={clsx(classes.stretched, classes.headline)}>Assessment</Box>
            </Box>
            <Box className={classes.centered}>
              <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap)}>
                <Typography variant="body1" component="div">
                  You are required to complete an assessment
                </Typography>
              </Box>
              <Box className={classes.stretched}>
                <List dense>
                  <ListItem>
                    <ListItemIcon>
                      <FibreManualRecordIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Read all the instructions carefully." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FibreManualRecordIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="All drills need to be done with speed and accuracy." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FibreManualRecordIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Make sure you do the assessment in a quiet environment." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <FibreManualRecordIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Complete the assessment by yourself, without any assistance or interruptions." />
                  </ListItem>
                </List>
              </Box>
              <Box className={classes.column}>
                <Box m={2} className={classes.buttonContainer}>
                  <Button variant="outlined" color="primary" className={classes.button} onClick={onStart}>
                    Start
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
