/* eslint-disable no-unsafe-optional-chaining */
import { Sprite } from '@inlet/react-pixi';
import random from 'lodash/random';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import seedrandom from 'seedrandom';

import { RgbColour } from '../../../models';
import { DrillContext } from '../../../providers';

import { PlainBackground } from './PlainBackground';

export function ImageBackground(props) {
  const { height: targetHeight, width: targetWidth } = props;
  const { backgroundCount } = useSelector((state) => state.drill);
  const { backgrounds } = useContext(DrillContext);

  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);

  const texture = useMemo(() => {
    const loader = PIXI.Loader.shared;
    let loaderTexture;
    seedrandom(backgroundCount);

    let loopCount = 0;

    while (!loaderTexture || loopCount < backgrounds?.length) {
      loopCount += 1;
      const backgroundIndex = random(backgrounds?.length - 1);
      const background = backgrounds[backgroundIndex];
      loaderTexture = loader.resources[background.id]?.texture;
    }

    if (loaderTexture) {
      return new PIXI.Texture(loaderTexture);
    }

    return null;
  }, [backgrounds, backgroundCount]);

  useEffect(() => {
    if (!texture) {
      return;
    }

    const {
      baseTexture: { width, height },
    } = texture;

    setImageWidth(width);
    setImageHeight(height);
  }, [texture]);

  const scale = useMemo(() => {
    let scaleX;
    let scaleY;
    let computedScale = 1;

    // 1. Too small in all dimensions
    if (imageWidth < targetWidth && imageHeight < targetHeight) {
      // enlarge the background by the largest scale to get the fill
      scaleX = targetWidth / imageWidth;
      scaleY = targetHeight / imageHeight;
      computedScale = scaleX > scaleY ? scaleX : scaleY;
    }

    // 2. Too large in all directions
    if (imageWidth > targetWidth && imageHeight > targetHeight) {
      // shrink the background by the smallest scale to get the fill;
      scaleX = targetWidth / imageWidth;
      scaleY = targetHeight / imageHeight;
      computedScale = scaleX > scaleY ? scaleX : scaleY;
    }

    // 3. Image is too tall and narrow
    if (imageHeight > targetHeight && imageWidth <= targetWidth) {
      computedScale = targetWidth / imageWidth;
    }

    // 4. Image is too long and thin
    if (imageWidth > targetWidth && imageHeight <= targetHeight) {
      computedScale = targetHeight / imageHeight;
    }

    return computedScale;
  }, [imageWidth, imageHeight, targetWidth, targetHeight]);

  // const x = useMemo(() => (imageWidth * scale - imageWidth) / 2, [imageWidth, scale]);
  // const y = useMemo(() => (imageHeight * scale - imageHeight) / 2, [imageHeight, scale]);

  return (
    <>
      {texture && <Sprite texture={texture} x={0} y={0} width={imageWidth * scale} height={imageHeight * scale} />}
      {!texture && (
        <PlainBackground width={targetWidth} height={targetHeight} color={RgbColour.random().getWebColour('0x')} />
      )}
    </>
  );
}

ImageBackground.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
