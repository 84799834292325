/* eslint-disable default-param-last */
import is from 'is_js';

import {
  ACCEPT_DISCLAIMER,
  ACCEPT_DISCLAIMER_COMMIT,
  ACCEPT_DISCLAIMER_ROLLBACK,
  CLEAR_STORE,
  COMPLETE_USER_ASSESSMENT,
  COMPLETE_USER_ASSESSMENT_COMMIT,
  COMPLETE_USER_ASSESSMENT_ROLLBACK,
  FETCH_ARROW_SET_BEGIN,
  FETCH_ARROW_SET_FAILURE,
  FETCH_ARROW_SET_SUCCESS,
  FETCH_BACKGROUNDS_BEGIN,
  FETCH_BACKGROUNDS_FAILURE,
  FETCH_BACKGROUNDS_SUCCESS,
  FETCH_DRILLS_BEGIN,
  FETCH_DRILLS_FAILURE,
  FETCH_DRILLS_SUCCESS,
  FETCH_DRILL_BEGIN,
  FETCH_DRILL_FAILURE,
  FETCH_DRILL_SUCCESS,
  FETCH_EXPLAINER_IMAGE_BEGIN,
  FETCH_EXPLAINER_IMAGE_FAILURE,
  FETCH_EXPLAINER_IMAGE_SUCCESS,
  FETCH_ICON_SET_BEGIN,
  FETCH_ICON_SET_FAILURE,
  FETCH_ICON_SET_SUCCESS,
  FETCH_PRODUCT_BEGIN,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_SUCCESS,
  FETCH_USER_BEGIN,
  FETCH_USER_FAILURE,
  FETCH_USER_SCORES_BEGIN,
  FETCH_USER_SCORES_FAILURE,
  FETCH_USER_SCORES_SUCCESS,
  FETCH_USER_SUCCESS,
  INCREASE_USER_LEVEL,
  INCREASE_USER_LEVEL_COMMIT,
  INCREASE_USER_LEVEL_ROLLBACK,
  POST_SCORE,
  POST_SCORE_COMMIT,
  POST_SCORE_ROLLBACK,
  RESET_STATE,
  SET_ASSESSMENT_DRILL_NUMBER,
  SET_CURRENT_ASSESSMENT,
  SET_MAXIMUM_LEVEL_REACHED_MODAL_SHOWN,
  SET_POST_LOGOUT_URL,
} from './actionTypes';

export const eyegymState = {
  arrowSet: null,
  assessment: null,
  assessmentDrillNumber: -1,
  assessmentUpdateCount: 0,
  assessments: [],
  backgrounds: null,
  drill: {},
  drills: [],
  error: null,
  explainerImage: null,
  iconSet: null,
  levelUpdateCount: 0,
  levels: [],
  loadingArrowSet: false,
  loadingBackgrounds: false,
  loadingDrill: false,
  loadingDrills: false,
  loadingExplainerImage: false,
  loadingIconSet: false,
  loadingProduct: false,
  loadingProducts: false,
  loadingScores: false,
  loadingUser: false,
  maximumLevelReachedModalShown: [],
  postLogoutUrl: null,
  product: {},
  products: [],
  scores: [],
  user: {},
};

const reducer = (state = eyegymState, action) => {
  if (!(action && action.type)) {
    return state;
  }

  switch (action.type) {
    case ACCEPT_DISCLAIMER: {
      const { user } = state;
      user.acceptedDisclaimer = true;
      user.pending = true;

      return {
        ...state,
        user,
      };
    }
    case ACCEPT_DISCLAIMER_COMMIT: {
      const { user } = state;
      delete user.pending;

      return {
        ...state,
        user,
      };
    }
    case ACCEPT_DISCLAIMER_ROLLBACK: {
      const { user } = state;
      delete user.pending;
      user.acceptedDisclaimer = false;

      return {
        ...state,
        user,
      };
    }
    case CLEAR_STORE: {
      return eyegymState;
    }
    case COMPLETE_USER_ASSESSMENT: {
      const { assessmentId, dateCompleted } = action.payload;

      const { assessments, assessmentUpdateCount } = state;

      const assessment = assessments[assessmentId];
      assessment.isCompleted = true;
      assessment.dateCompleted = dateCompleted;
      assessment.pending = true;

      return { ...state, assessment, assessmentUpdateCount: assessmentUpdateCount + 1, assessments };
    }
    case COMPLETE_USER_ASSESSMENT_COMMIT: {
      const { assessmentId } = action.meta;

      const { assessments, assessmentUpdateCount } = state;

      const assessment = assessments[assessmentId];
      delete assessment.pending;

      return { ...state, assessment, assessmentUpdateCount: assessmentUpdateCount + 1, assessments };
    }
    case COMPLETE_USER_ASSESSMENT_ROLLBACK: {
      const { assessmentId } = action.meta;
      const { assessments, assessmentUpdateCount } = state;

      const assessment = assessments[assessmentId];

      assessment.isCompleted = false;
      assessment.dateCompleted = null;

      return { ...state, assessment, assessmentUpdateCount: assessmentUpdateCount + 1, assessments };
    }
    case FETCH_ARROW_SET_BEGIN: {
      return { ...state, error: null, loadingArrowSet: true };
    }
    case FETCH_ARROW_SET_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingArrowSet: false,
      };
    }
    case FETCH_ARROW_SET_SUCCESS: {
      const { arrowSet } = action.payload;

      return {
        ...state,
        arrowSet,
        error: null,
        loadingArrowSet: false,
      };
    }
    case FETCH_BACKGROUNDS_BEGIN: {
      return {
        ...state,
        error: null,
        loadingBackgrounds: true,
      };
    }
    case FETCH_BACKGROUNDS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingBackgrounds: false,
      };
    }
    case FETCH_BACKGROUNDS_SUCCESS: {
      const { backgrounds } = action.payload;

      return {
        ...state,
        backgrounds,
        error: null,
        loadingBackgrounds: false,
      };
    }
    case FETCH_EXPLAINER_IMAGE_BEGIN: {
      return {
        ...state,
        error: null,
        loadingExplainerImage: true,
      };
    }
    case FETCH_EXPLAINER_IMAGE_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingExplainerImage: false,
      };
    }
    case FETCH_EXPLAINER_IMAGE_SUCCESS: {
      const { image: explainerImage } = action.payload;

      return {
        ...state,
        error: null,
        explainerImage,
        loadingExplainerImage: false,
      };
    }
    case FETCH_ICON_SET_BEGIN: {
      return {
        ...state,
        error: null,
        loadingIconSet: true,
      };
    }
    case FETCH_ICON_SET_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingIconSet: false,
      };
    }
    case FETCH_ICON_SET_SUCCESS: {
      const { iconSet } = action.payload;

      return {
        ...state,
        error: null,
        iconSet,
        loadingIconSet: false,
      };
    }
    case FETCH_USER_BEGIN:
      return {
        ...state,
        error: null,
        loadingUser: true,
      };
    case FETCH_USER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingUser: false,
        user: null,
      };
    }
    case FETCH_USER_SUCCESS: {
      const { user } = action.payload;

      const {
        currentUserProduct: { levels },
      } = user;

      return {
        ...state,
        error: null,
        levels,
        loadingUser: false,
        user,
      };
    }
    case FETCH_PRODUCT_BEGIN:
      return {
        ...state,
        error: null,
        loadingProduct: true,
      };
    case FETCH_PRODUCT_FAILURE: {
      const { products } = state || {};
      const { error, productId } = action.payload;

      if (products[productId]) {
        delete products[productId];
      }

      return {
        ...state,
        error,
        loadingProduct: false,
        products,
      };
    }
    case FETCH_PRODUCT_SUCCESS: {
      const { products, drills } = state || {};
      const { product } = action.payload;
      product.productDrills.sort((a, b) => a.order - b.order);
      products[product.id] = product;

      product.productDrills.forEach((pd) => {
        if (!drills[pd.drillId] && pd.drill) {
          drills[pd.drillId] = pd.drill;
        }
      });

      return {
        ...state,
        drills,
        error: null,
        loadingProduct: false,
        product,
        products,
      };
    }
    case FETCH_DRILL_BEGIN:
      return {
        ...state,
        error: null,
        loadingDrill: true,
      };
    case FETCH_DRILL_FAILURE: {
      const { drills } = state || {};
      const { error, drillId } = action.payload;

      if (drills[drillId]) {
        delete drills[drillId];
      }

      return {
        ...state,
        drills,
        error,
        loadingDrill: false,
      };
    }
    case FETCH_DRILL_SUCCESS: {
      const { drills } = state || {};
      const { drill } = action.payload;
      drills[drill.id] = drill;

      return {
        ...state,
        drill,
        drills,
        error: null,
        loadingDrill: false,
      };
    }
    case FETCH_DRILLS_BEGIN:
      return {
        ...state,
        error: null,
        loadingDrills: true,
      };
    case FETCH_DRILLS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingDrills: false,
      };
    }
    case FETCH_DRILLS_SUCCESS: {
      const { drills } = action.payload;

      return {
        ...state,
        drills,
        error: null,
        loadingDrills: false,
      };
    }
    case FETCH_USER_SCORES_BEGIN:
      return {
        ...state,
        error: null,
        loadingScores: true,
      };
    case FETCH_USER_SCORES_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loadingScores: false,
      };
    }
    case FETCH_USER_SCORES_SUCCESS: {
      const { scores } = action.payload;

      return {
        ...state,
        error: null,
        loadingScores: false,
        scores,
      };
    }
    case INCREASE_USER_LEVEL: {
      const { drillId } = action.payload;

      const { user, levelUpdateCount, levels } = state;

      const level = levels.find((l) => l.drillId === drillId);
      level.level += 1;
      level.pending = true;
      user.currentUserProduct.levels = levels;

      return { ...state, levelUpdateCount: levelUpdateCount + 1, levels, user };
    }
    case INCREASE_USER_LEVEL_COMMIT: {
      const { drillId } = action.meta;

      const { user, levelUpdateCount, levels } = state;

      const level = levels.find((l) => l.drillId === drillId);
      user.currentUserProduct.levels = levels;

      delete level.pending;

      return { ...state, levelUpdateCount: levelUpdateCount + 1, levels, user };
    }
    case INCREASE_USER_LEVEL_ROLLBACK: {
      const { drillId } = action.meta;
      const { user, levelUpdateCount, levels } = state;

      const level = levels.find((l) => l.drillId === drillId && l.increased);

      if (level && level.increased) {
        level.level -= 1;
        delete level.increased;
      }

      user.currentUserProduct.levels = levels;

      return { ...state, levelUpdateCount: levelUpdateCount + 1, levels, user };
    }
    case POST_SCORE: {
      const { score } = action.payload;
      const { scores } = state;

      if (!scores.find((s) => score.userScoreId === s?.userScoreId)) {
        score.pending = true;
        scores.push(score);
      }

      return {
        ...state,
        error: null,
        scores,
      };
    }
    case POST_SCORE_COMMIT: {
      const { score } = action.meta;
      const { scores } = state;

      if (scores.find((s) => score.userScoreId === s?.userScoreId)) {
        delete score.pending;
      }

      return {
        ...state,
        error: null,
        scores,
      };
    }
    case POST_SCORE_ROLLBACK: {
      const { score } = action.meta;
      let { scores } = state;

      scores = scores.filter((s) => score.userScoreId === s?.userScoreId);

      return {
        ...state,
        error: 'Unable to persist score.',
        scores,
      };
    }
    case RESET_STATE: {
      return {
        ...eyegymState,
      };
    }
    case SET_ASSESSMENT_DRILL_NUMBER: {
      const { assessmentDrillNumber } = action.payload;
      let newAssessmentDrillNumber = assessmentDrillNumber;

      if (is.function(assessmentDrillNumber)) {
        const { assessmentDrillNumber: currentAssessmentDrillNumber } = state;
        newAssessmentDrillNumber = assessmentDrillNumber(currentAssessmentDrillNumber);
      }

      return {
        ...state,
        assessmentDrillNumber: newAssessmentDrillNumber,
      };
    }
    case SET_CURRENT_ASSESSMENT: {
      const { assessment } = action.payload;
      const { assessments } = state;

      if (assessment?.id) {
        assessments[assessment.id] = assessment;
      }

      return {
        ...state,
        assessment,
        assessments,
      };
    }
    case SET_MAXIMUM_LEVEL_REACHED_MODAL_SHOWN: {
      const { drillId } = action.payload;
      const { maximumLevelReachedModalShown } = state;

      if (!maximumLevelReachedModalShown.includes(drillId)) {
        maximumLevelReachedModalShown.push(drillId);
      }

      return {
        ...state,
        maximumLevelReachedModalShown,
      };
    }
    case SET_POST_LOGOUT_URL: {
      const { url } = action.payload;

      return {
        ...state,
        postLogoutUrl: url,
      };
    }
    default:
      return state;
  }
};

export default reducer;
