import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { getImageAssetUri } from '../utils';

const useStyles = makeStyles({
  backdropContainer: {
    backgroundColor: 'rgba(0,0,0,.15)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: -1,
  },
  fullPageContainer: {
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  imageDiv: {
    backgroundImage: `url(${getImageAssetUri('background-blue.jpg')})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  intermediateContainer: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
  noiseDiv: {
    backgroundImage: `url(${getImageAssetUri('noise.png')})`,
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: 2,
  },
});

export function Backdrop() {
  const classes = useStyles();

  return (
    <div className={classes.backdropContainer}>
      <div className={classes.fullPageContainer}>
        <div>
          <div className={classes.intermediateContainer}>
            <div className={classes.imageDiv} />
          </div>
          <div className={classes.noiseDiv} />
        </div>
      </div>
    </div>
  );
}
