/* eslint-disable import/order */
/* eslint-disable default-case-last */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable dot-notation */

import { plainToClass } from 'class-transformer';
import detectTouchEvents from 'detect-touch-events';
import is from 'is_js';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
import minBy from 'lodash/minBy';
import random from 'lodash/random';
import range from 'lodash/range';
import moment from 'moment';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import seedrandom from 'seedrandom';

import {
  Arrow,
  Drill,
  DrillStage,
  GoalType,
  Guid,
  InputDirection,
  InputType,
  ObjectMovement,
  ObjectSymbol,
  ObjectVariety,
  Rect,
  StartPosition,
  User,
} from '../models';
import { actionCreators as drillActionCreators } from '../redux/drill';
import { actionCreators as eyegymActionCreators } from '../redux/eyegym';
import { getArrowUri, getBackgroundUri, getIconUri, noop, randomEx } from '../utils';
import { LogContext } from './LogProvider';

const MAX_LOOPCOUNT = 20;
const DEFAULT_MARGIN = 20;

const Periphery = {
  LEFT: 1,
  RIGHT: 2,
};

Periphery.MIN = Periphery.LEFT;
Periphery.MAX = Periphery.RIGHT;

const Border = {
  BOTTOM: 2,
  LEFT: 3,
  RIGHT: 4,
  TOP: 1,
};

Border.MIN = Border.TOP;
Border.MAX = Border.RIGHT;

const defaultContext = {
  arrows: null,
  assessment: null,
  backgrounds: null,
  details: null,
  drill: null,
  drillId: undefined,
  getIcon: noop,
  getSymbol: noop,
  hazardIconIndex: null,
  iconSet: null,
  imagesPreloaded: false,
  initialiseTarget: noop,
  inputDirection: null,
  lastDirection: null,
  lastNumber: null,
  lastStartPosition: null,
  leftCount: null,
  level: undefined,
  maximumLevel: null,
  nested: false,
  nextPosition: null,
  nextSymbol: null,
  postScore: noop,
  rightCount: null,
  safeIconIndex: null,
  setStartPosition: noop,
  settings: null,
  symbols: null,
  targetIconIndex: null,
  targetMovement: null,
  targetSymbol: null,
  unsafeIconIndex: null,
  userAssessmentId: null,
  userLevel: null,
  userProductId: null,
};

export const DrillContext = React.createContext(defaultContext);

// TODO: Loading icons arrows and backgrounds needs to be moved to an earlier phase so that
// they're preloaded when a team leader logs in.

export function DrillProvider({ children, drillId, level, nested, ...rest }) {
  const dispatch = useDispatch();
  const { log } = useContext(LogContext);
  const outerOptions = useContext(DrillContext);

  const { assessment, user, drills, product, arrowSet, iconSet, backgrounds } = useSelector((state) => state.eyegym);
  const { drillResetCount, stage } = useSelector((state) => state.drill);

  const languageCode = useMemo(() => user?.languageCode || 'en', [user]);

  const [drill, setDrill] = useState(
    rest?.drill ??
      plainToClass(
        Drill,
        drills.find((d) => d.id === drillId)
      )
  );

  const [details, setDetails] = useState(rest?.details ?? drill?.details.find((d) => d.languageCode === languageCode));
  const [imagesPreloaded, setImagesPreloaded] = useState(rest?.imagesPreloaded ?? false);
  const [iconSetsLoaded, setIconSetsLoaded] = useState([]);
  const [settings, setSettings] = useState(rest?.settings ?? drill?.settings.find((s) => s.level === level));

  const inputDirection = useRef(rest?.inputDirection);
  const lastDirection = useRef(rest?.lastDirection);
  const lastNumber = useRef(0);
  const lastStartPosition = useRef({ x: -1, y: -1 });
  const leftCount = useRef(rest?.leftCount || 0);
  const nextPosition = useRef(rest?.nextPosition);
  const nextSymbol = useRef(rest?.nextSymbol);
  const rightCount = useRef(rest?.leftCount || 0);
  const targetIconIndex = useRef(rest?.targetIconIndex);
  const targetMovement = useRef(rest?.targetMovement);
  const targetSymbol = useRef(rest?.targetSymbol);
  const hazardIconIndex = useRef(rest?.hazardIconIndex);
  const safeIconIndex = useRef(rest?.safeIconIndex);
  const unsafeIconIndex = useRef(rest?.safeIconIndex);
  const startPositions = useRef([]);
  const usedPositions = useRef([]);

  useEffect(() => {
    if (nested) {
      return;
    }

    if (assessment && !assessment.isCompleted) {
      const first = minBy(assessment.product.productDrills, (pd) => pd.order);
      dispatch(eyegymActionCreators.setAssessmentDrillNumber(first.order));
    }
  }, [dispatch, assessment, nested]);

  useEffect(() => {
    if (nested) {
      return;
    }

    const _drill = plainToClass(
      Drill,
      drills.find((d) => d.id === drillId)
    );

    if (!isEqual(_drill, drill)) {
      setDrill(_drill);
    }
  }, [drill, drillId, drills, nested]);

  useEffect(() => {
    if (document && drill?.name) {
      document.title = `EyeGym | ${drill.name} | Level ${level}`;
    }
  }, [drill?.name, level]);

  useEffect(() => {
    if (nested) {
      return;
    }

    const _settings = drill?.settings.find((s) => s.level === level);

    if (!_settings) {
      return;
    }

    let difficulty = 1;

    if (!assessment) {
      if (product) {
        difficulty = product.difficulty / 100 || 1;
      }
    }

    _settings.objectSpeed *= difficulty;
    _settings.objectSpeedMinimum *= difficulty;
    _settings.objectSpeedMaximum *= difficulty;
    _settings.paneTimeout /= difficulty;
    _settings.paneTimeoutMaximum /= difficulty;
    _settings.targetTime /= difficulty;

    if (!isEqual(_settings, settings)) {
      setSettings(_settings);
    }
  }, [assessment, drill, level, nested, product, settings]);

  useEffect(() => {
    if (nested) {
      return;
    }

    const _details = drill?.details.find((d) => d.languageCode === languageCode);

    if (!isEqual(_details, details)) {
      setDetails(_details);
    }
  }, [drill, languageCode, details, nested]);

  const maximumLevel = useMemo(() => {
    const _user = plainToClass(User, user);

    return _user.getMaximumLevel();
  }, [user]);

  const iconSetId = useMemo(() => {
    if (!drill) {
      return;
    }

    if (user && drill.canHaveCustomIconSet) {
      if (user.iconSetId) {
        return user.iconSetId;
      }

      if (user.team?.iconSetId) {
        return user.team.iconSetId;
      }
    }

    return drill.iconSetId;
  }, [user, drill]);

  const teamId = useMemo(() => user?.teamId, [user]);

  const arrowSetId = useMemo(() => {
    if (!drill) {
      return;
    }

    if (user && drill.canHaveCustomArrowSet) {
      if (user.arrowSetId) {
        return user.arrowSetId;
      }

      if (user.team?.arrowSetId) {
        return user.team.arrowSetId;
      }
    }

    return drill.arrowSetId;
  }, [user, drill]);

  useEffect(() => {
    if (log && arrowSetId) {
      dispatch(eyegymActionCreators.fetchArrowSet(arrowSetId, { log }));
    }
  }, [dispatch, log, arrowSetId]);

  useEffect(() => {
    if (log && iconSetId) {
      dispatch(eyegymActionCreators.fetchIconSet(iconSetId, { log }));
    }
  }, [dispatch, log, iconSetId]);

  useEffect(() => {
    if (log && teamId) {
      dispatch(eyegymActionCreators.fetchBackgrounds(teamId, { log }));
    }
  }, [dispatch, log, teamId]);

  const symbols = useMemo(() => {
    if (!arrowSet) {
      return;
    }

    const s = range(0, 21).map((i) => ({
      id: i,
      uri: getIconUri({ extension: 'png', id: i }),
    }));

    s.push({ id: 'Up', uri: getArrowUri('Up', arrowSet.up) });
    s.push({ id: 'Down', uri: getArrowUri('Down', arrowSet.down) });
    s.push({ id: 'Left', uri: getArrowUri('Left', arrowSet.left) });
    s.push({ id: 'Right', uri: getArrowUri('Right', arrowSet.right) });

    return s;
  }, [arrowSet]);

  const userAssessmentId = useMemo(() => {
    if (assessment && !assessment.isCompleted) {
      return assessment.id;
    }

    return null;
  }, [assessment]);

  const userProductId = useMemo(() => {
    if (assessment && !assessment.isCompleted) {
      return null;
    }

    return user.currentUserProductId;
  }, [assessment, user]);

  const userLevel = useMemo(() => {
    if (assessment && !assessment.isCompleted) {
      return;
    }

    if (!user?.currentUserProduct?.levels) {
      return;
    }

    return user.currentUserProduct.levels.find((l) => l.drillId === drillId);
  }, [assessment, drillId, user?.currentUserProduct?.levels]);

  useEffect(() => {
    const loader = PIXI.Loader.shared;

    if (
      !(arrowSet && backgrounds?.length && iconSet?.iconSetImages?.length && symbols?.length) ||
      loader.loading ||
      imagesPreloaded ||
      nested
    ) {
      return;
    }

    if (!loader.resources['Up']) {
      loader.add('Up', getArrowUri('Up', arrowSet.up));
    }

    if (!loader.resources['Down']) {
      loader.add('Down', getArrowUri('Down', arrowSet.down));
    }

    if (!loader.resources['Left']) {
      loader.add('Left', getArrowUri('Left', arrowSet.left));
    }

    if (!loader.resources['Right']) {
      loader.add('Right', getArrowUri('Right', arrowSet.right));
    }

    for (let i = 0, l = backgrounds.length; i < l; i += 1) {
      if (!loader.resources[backgrounds[i].id]) {
        loader.add(backgrounds[i].id, getBackgroundUri(backgrounds[i]));
      }
    }

    for (let i = 0, l = iconSet.iconSetImages.length; i < l; i += 1) {
      if (!loader.resources[iconSet.iconSetImages[i].imageId]) {
        loader.add(iconSet.iconSetImages[i].imageId, getIconUri(iconSet.iconSetImages[i].image));
      }
    }

    for (let i = 0, l = symbols.length; i < l; i += 1) {
      if (!loader.resources[symbols[i].id]) {
        loader.add(`${symbols[i].id}`, symbols[i].uri);
      }
    }

    loader.load(() => {
      setImagesPreloaded(true);
      setIconSetsLoaded([...iconSetsLoaded, iconSet?.id]);
    });
  }, [arrowSet, backgrounds, iconSet?.iconSetImages, iconSet?.id, imagesPreloaded, iconSetsLoaded, symbols, nested]);

  useEffect(() => {
    const loader = PIXI.Loader.shared;

    if (loader.loading || nested) {
      return;
    }

    if (!iconSet) {
      return;
    }

    if (iconSetsLoaded.includes(iconSet.id)) {
      return;
    }

    for (let i = 0, l = iconSet.iconSetImages.length; i < l; i += 1) {
      if (!loader.resources[iconSet.iconSetImages[i].imageId]) {
        loader.add(iconSet.iconSetImages[i].imageId, getIconUri(iconSet.iconSetImages[i].image));
      }
    }

    loader.load(() => {
      setIconSetsLoaded([...iconSetsLoaded, iconSet.id]);
    });
  }, [iconSetsLoaded, iconSet, iconSetId, nested]);

  const initialiseTarget = useCallback(() => {
    if (!iconSet?.iconSetImages) {
      return;
    }

    const findIcon = (name, exact) =>
      iconSet.iconSetImages
        .map((i) => i.image)
        .find((i) => {
          if (exact) {
            return i.name.toLowerCase() === name.toLowerCase();
          }

          return i.name.toLowerCase().includes(name.toLowerCase());
        });

    const images = iconSet.iconSetImages.map((i) => i.image);
    let targetIcon;

    if (drill.goalType === GoalType.EYE_RUN) {
      targetIcon = findIcon('target', true);
      const hazardIcon = findIcon('hazard', true);
      const safeIcon = findIcon('safe', true);
      const unsafeIcon = findIcon('unsafe', true);
      hazardIconIndex.current = indexOf(images, hazardIcon);
      safeIconIndex.current = indexOf(images, safeIcon);
      unsafeIconIndex.current = indexOf(images, unsafeIcon);
    } else {
      seedrandom(moment.utc().valueOf());
      targetIconIndex.current = random(0, images.length - 1);
      targetIcon = images[targetIconIndex.current];
    }

    dispatch(drillActionCreators.setTargetIcon(targetIcon, targetIcon?.id, targetIconIndex.current));

    const showTarget =
      targetIcon &&
      !drill.showSymbolOnly &&
      drill.objectVariety !== ObjectVariety.CORRECT_CORRECT_RANDOM &&
      !drill.hideIcon &&
      drill.goalType !== GoalType.LINE_TRACKING_MULTIPLE;

    dispatch(drillActionCreators.setShowTarget(showTarget));
  }, [dispatch, drill?.goalType, drill?.showSymbolOnly, drill?.objectVariety, drill?.hideIcon, iconSet?.iconSetImages]);

  const postScore = useCallback(
    ({ correctCount, incorrectCount, slowCount, totalResponseTime, start, userScore = undefined }) => {
      let percentage = 0;

      if (correctCount + incorrectCount > 0) {
        percentage = ((correctCount - slowCount) / (correctCount + incorrectCount)) * 100;
      }

      let responseTime = totalResponseTime;
      const numberOfPanes = settings.numberOfPanes || 1;

      if (numberOfPanes > 1) {
        responseTime /= numberOfPanes;
      }

      const targetTime = settings.targetTime * 1000;

      if (drill.goalType === GoalType.BATAK || drill.goalType === GoalType.GREEN_RED_LIGHT) {
        percentage = Math.min((userScore / settings.scoreRequired) * 100, 100);
      } else if (drill.goalType === GoalType.EYE_RUN) {
        percentage = Math.min(responseTime / (settings.paneTimeout * 1000) / 100, 100);
      } else if (targetTime && responseTime > targetTime && drill.goalType !== GoalType.TRACK_CORRECT) {
        const diff = responseTime - targetTime;
        const responsePercentage = 100 - (diff / targetTime) * 100;
        percentage *= responsePercentage / 100;
        percentage = Math.min(percentage, 100);
      }

      percentage = Math.max(percentage, 0);

      const score = {
        correct: correctCount,
        drillId: drill.id,
        duration: moment.utc().diff(start) / 1000,
        incorrect: incorrectCount,
        level: settings.level,
        passed: false,
        percentage,
        responseTime: responseTime / 1000,
        scoreDate: moment.utc(),
        slow: slowCount,
        targetTime: targetTime / 1000,
        userAssessmentId,
        userProductId,
        userScoreId: Guid.newGuid().toString(),
      };

      if (drill.goalType === GoalType.BATAK || drill.goalType === GoalType.GREEN_RED_LIGHT) {
        score.passed = Number(userScore) >= Number(settings.scoreRequired);
      } else {
        score.passed = Number(score.percentage.toFixed(2)) >= Number(settings.scoreRequired.toFixed(2));
      }

      dispatch(eyegymActionCreators.postScore(score));

      if (userAssessmentId) {
        dispatch(eyegymActionCreators.setAssessmentDrillNumber((n) => n + 1));
      }

      if (userProductId && score.passed) {
        // if the current level is equal to the maximum level the user has
        // achieved for this drill then we can increase the level.
        if (userLevel.level === level) {
          dispatch(eyegymActionCreators.increaseUserLevel({ drillId: drill.id, userProductId }));
        }
      }
    },
    [
      dispatch,
      drill?.goalType,
      drill?.id,
      level,
      settings?.level,
      settings?.numberOfPanes,
      settings?.paneTimeout,
      settings?.scoreRequired,
      settings?.targetTime,
      userAssessmentId,
      userLevel?.level,
      userProductId,
    ]
  );

  useEffect(() => {
    if (stage === DrillStage.START) {
      startPositions.current = [];
      usedPositions.current = [];
      leftCount.current = 0;
      rightCount.current = 0;
    }
  }, [stage]);

  useEffect(() => {
    if (nested) {
      return;
    }

    seedrandom(drillResetCount);

    if (!iconSet?.iconSetImages) {
      return;
    }

    const task = detectTouchEvents.hasSupport || is.tablet() ? details?.tabletTask : details?.task;
    dispatch(drillActionCreators.setTask(task));

    if (!drill?.needsTarget || drill?.goalType === GoalType.TRAFFIC_LIGHTS) {
      dispatch(drillActionCreators.setTargetIcon(null));

      return;
    }

    if (drill?.goalType === GoalType.EYE_SPIN) {
      if (settings.objectMovementId === ObjectMovement.MOVE_LINEAR) {
        const direction = randomEx(1, 4, lastDirection.current);
        lastDirection.current = direction;

        switch (direction) {
          case 1:
          case 2:
            targetMovement.current = direction === 1 ? ObjectMovement.MOVE_UP : ObjectMovement.MOVE_DOWN;
            inputDirection.current = random(1, 2) === 1 ? InputDirection.LEFT_RIGHT : InputDirection.RIGHT_LEFT;
            break;
          case 3:
          case 4:
          default:
            targetMovement.current = direction === 3 ? ObjectMovement.MOVE_LEFT : ObjectMovement.MOVE_RIGHT;
            inputDirection.current = random(1, 2) === 1 ? InputDirection.TOP_BOTTOM : InputDirection.BOTTOM_TOP;
            break;
        }
      } else {
        targetMovement.current = settings.objectMovementId;
        inputDirection.current =
          targetMovement.current === ObjectMovement.MOVE_UP || drill.targetMovement === ObjectMovement.MOVE_DOWN
            ? InputDirection.LEFT_RIGHT
            : InputDirection.TOP_BOTTOM;
      }

      return;
    }

    initialiseTarget();
  }, [
    dispatch,
    initialiseTarget,
    details?.tabletTask,
    details?.task,
    drill?.goalType,
    drill?.needsTarget,
    drill?.targetMovement,
    drillResetCount,
    iconSet?.iconSetImages,
    nested,
    settings?.objectMovementId,
  ]);

  const getIcon = useCallback(
    (index, cb) => {
      let iconIndex;

      if (!iconSet?.iconSetImages?.length) {
        return null;
      }

      const iconUbound = iconSet.iconSetImages.length - 1;

      if (drill.objectVariety === ObjectVariety.ALL_RANDOM) {
        iconIndex = random(0, iconUbound);
      } else {
        const weighting = random(1, 4);

        const hide =
          weighting === 3 &&
          drill.goalType === GoalType.COUNT_CORRECT &&
          drill.inputType === InputType.NONE &&
          drill.objectVariety !== ObjectVariety.CORRECT_RANDOM &&
          drill.objectVariety !== ObjectVariety.CORRECT_CORRECT_RANDOM;

        if (index === 0 && !hide) {
          iconIndex = targetIconIndex.current;
        } else if (index === 1 && drill.objectVariety === ObjectVariety.CORRECT_CORRECT_RANDOM) {
          iconIndex = targetIconIndex.current;
        } else if (drill.objectVariety === ObjectVariety.CORRECT_SAME) {
          iconIndex = targetIconIndex.current;
        } else if (drill.objectVariety === ObjectVariety.ALL_CORRECT) {
          iconIndex = targetIconIndex.current;
        } else if (index > 0 && drill.objectVariety === ObjectVariety.CORRECT_RANDOM) {
          iconIndex = randomEx(0, iconUbound, targetIconIndex.current);
        } else if (index > 1 && drill.objectVariety === ObjectVariety.CORRECT_CORRECT_RANDOM) {
          iconIndex = randomEx(0, iconUbound, targetIconIndex.current);
        } else if (drill.objectVariety === ObjectVariety.ALL_RANDOM) {
          iconIndex = randomEx(0, iconUbound, targetIconIndex.current);
        } else {
          iconIndex = randomEx(0, iconUbound, targetIconIndex.current);
        }

        if (iconIndex === targetIconIndex.current) {
          if (is.function(cb)) {
            cb();
          }
        }
      }

      if (iconIndex >= 0 && !drill.showSymbolOnly) {
        return { id: iconSet.iconSetImages[iconIndex].imageId, index: iconIndex };
      }

      return null;
    },
    [drill?.goalType, drill?.inputType, drill?.objectVariety, drill?.showSymbolOnly, iconSet?.iconSetImages]
  );

  const getSymbol = useCallback(
    (index) => {
      if (!symbols?.length) {
        return null;
      }

      let symbolIndex = -1;

      switch (drill.objectSymbol) {
        case ObjectSymbol.ARROW:
          if (drill.showCorrect) {
            if (index === 0) {
              symbolIndex = targetSymbol.current;
            } else {
              symbolIndex = randomEx(21, 24, targetSymbol.current);
            }
          } else if (drill.showSymbolTarget) {
            if (index === 0) {
              symbolIndex = randomEx(21, 24, targetSymbol.current);
            }
          } else {
            symbolIndex = random(21, 24);
          }

          break;
        case ObjectSymbol.NUMBER:
          switch (drill.goalType) {
            case GoalType.IDENTIFY_NEXT:
              nextSymbol.current = 1;
              symbolIndex = index + 1;
              break;
            case GoalType.REMEMBER:
              symbolIndex = randomEx(index === 0 ? 1 : 0, 9, lastNumber.current);
              lastNumber.current = symbolIndex;
              break;
            case GoalType.IDENTIFY_CORRECT:
            case GoalType.EYE_PUSHUPS:
            case GoalType.EYE_STRETCH:
              symbolIndex = index === 0 ? targetSymbol.current : randomEx(0, 20, targetSymbol.current);
              break;
            default:
              break;
          }

          break;
        default:
          break;
      }

      if (symbolIndex >= 0) {
        return { id: symbols[symbolIndex].id, index: symbolIndex };
      }

      return null;
    },
    [drill?.goalType, drill?.objectSymbol, drill?.showCorrect, drill?.showSymbolTarget, symbols]
  );

  const overlaps = (positions, position) => {
    const o = filter(positions, (p) => p.intersect(position));

    return o.length > 0;
  };

  const setStartPosition = useCallback(
    (icon, index, width, height, numberOfPanes = 1) => {
      if (!iconSet) {
        return;
      }

      let loopCount = 0;
      let margin = 0;
      let movement = -1;
      let position;

      const size = {
        height: iconSet.maximumHeight,
        width: iconSet.maximumWidth,
      };

      let x = 0;
      let y = 0;

      switch (settings.startPosition) {
        case StartPosition.BORDER:
          loopCount = 0;

          do {
            loopCount += 1;

            if (loopCount === MAX_LOOPCOUNT) {
              startPositions.current = [];
            }

            switch (random(Border.MIN, Border.MAX)) {
              case Border.TOP:
                x = random(size.width / 2, width - size.width / 2);
                y = size.height / 2;
                break;
              case Border.BOTTOM:
                x = random(size.width / 2, width - size.width / 2);
                y = height - size.height / 2;
                break;
              case Border.LEFT:
                x = size.width / 2;
                y = random(size.height / 2, height - size.height / 2);
                break;
              case Border.RIGHT:
              default:
                x = width - size.width / 2;
                y = random(size.height / 2, height - size.height / 2);
                break;
            }

            position = new Rect(x, y, size.width, size.height);
          } while (overlaps(startPositions.current, position));

          startPositions.current.push(position);

          break;
        case StartPosition.CENTRE:
          loopCount = 0;

          do {
            if (loopCount === MAX_LOOPCOUNT) {
              startPositions.current = [];
            }

            loopCount += 1;

            margin = DEFAULT_MARGIN;

            const iconWidth = icon.size.width + margin;
            const iconHeight = icon.size.height;

            if (drill.goalType === GoalType.REMEMBER || drill.goalType === GoalType.TRAFFIC_LIGHTS) {
              x = (width - iconWidth * settings.totalObjects) / 2;
              x += index * iconWidth + iconWidth;
              y = (height - iconHeight) / 2;
            } else if (index === 0) {
              x = (width - icon.size.width) / 2;
              y = (height - iconHeight) / 2;
            } else {
              y = random(0, height - size.height);

              do {
                x = random(0, width - size.width);
              } while (x < width / 2 && width / 2 <= x + size.width);
            }

            position = new Rect(x, y, size.width, size.height);
          } while (overlaps(startPositions.current, position));

          startPositions.current.push(position);

          break;
        case StartPosition.PERIPHERAL:
          loopCount = 0;

          do {
            if (loopCount === MAX_LOOPCOUNT) {
              startPositions.current = [];
            }

            if (index === 0 && leftCount.current >= numberOfPanes / 2) {
              nextPosition.current = Periphery.RIGHT;
            } else if (index === 0 && rightCount.current >= numberOfPanes / 2) {
              nextPosition.current = Periphery.LEFT;
            } else {
              nextPosition.current = random(Periphery.MIN, Periphery.MAX);
            }

            y = random(height / 3, height * (2 / 3)) - size.height;

            switch (nextPosition.current) {
              case Periphery.LEFT:
                x = 0;

                if (index === 0) {
                  leftCount.current += 1;
                }

                break;
              case Periphery.RIGHT:
              default:
                x = width - icon.size.width;

                if (index === 0) {
                  rightCount.current += 1;
                }
            }

            position = new Rect(x, y, size.width, size.height);
          } while (overlaps(startPositions.current, position));

          startPositions.current.push(position);

          break;
        case StartPosition.PUZZLE: {
          margin = 8;
          x = (width - size.width) / 2;
          y = (height - size.height) / 2;

          if (index > 0) {
            if (usedPositions.current.length < settings.totalObjects - 2) {
              do {
                nextPosition.current = random(1, settings.totalObjects - 1);
              } while (usedPositions.current.indexOf(nextPosition.current) >= 0);
            }

            const pos = (nextPosition.current % 4) + 1;
            const depth = Math.floor((nextPosition.current - 1) / 4);

            switch (pos) {
              case Border.BOTTOM:
                if (drill.goalType === GoalType.EYE_STRETCH) {
                  y = margin;
                } else {
                  if (depth <= 1) {
                    y -= size.height + margin;
                  } else if (depth <= 4) {
                    y -= (size.height + margin) * 2;
                  } else {
                    y -= (size.height + margin) * 3;
                  }

                  if (depth === 1 || depth === 3) {
                    x += size.width + width;
                  } else {
                    x -= size.width + width;
                  }
                }

                if (index === 1) {
                  targetSymbol.current = Arrow.up.index;
                }

                if (settings.objectMovementId === ObjectMovement.MOVE_OUTWARDS) {
                  movement = ObjectMovement.MOVE_UP;
                }

                break;
              case Border.RIGHT:
                if (drill.goalType === GoalType.EYE_STRETCH) {
                  x = width - size.width + margin;
                } else {
                  if (depth <= 1) {
                    x += size.width + margin;
                  } else if (depth <= 4) {
                    x += (size.width + margin) * 2;
                  } else {
                    x += (size.width + margin) * 3;
                  }

                  if (depth === 1 || depth === 3) {
                    y += size.height + margin;
                  } else if (depth === 4) {
                    y -= size.height + margin;
                  }
                }

                if (index === 1) {
                  targetSymbol.current = Arrow.right.index;
                }

                if (settings.objectMovementId === ObjectMovement.MOVE_OUTWARDS) {
                  movement = ObjectMovement.MOVE_RIGHT;
                }

                break;
              case Border.TOP:
                if (drill.goalType === GoalType.EYE_STRETCH) {
                  y = height - size.height + margin;
                } else {
                  if (depth <= 1) {
                    y += size.height + margin;
                  } else if (depth <= 4) {
                    y += (size.height + margin) * 2;
                  } else {
                    y += (size.height + margin) * 3;
                  }

                  if (depth === 1 || depth === 3) {
                    x -= size.width + margin;
                  } else if (depth === 4) {
                    x += size.width + margin;
                  }
                }

                if (index === 1) {
                  targetSymbol.current = Arrow.down.index;
                }

                if (settings.objectMovementId === ObjectMovement.MOVE_OUTWARDS) {
                  movement = ObjectMovement.MOVE_DOWN;
                }

                break;
              case Border.LEFT:
              default:
                if (drill.goalType === GoalType.EYE_STRETCH) {
                  x = margin;
                } else {
                  if (depth <= 1) {
                    x -= size.width + margin;
                  } else if (depth <= 4) {
                    x -= (size.width + margin) * 2;
                  } else {
                    x -= (size.width + margin) * 3;
                  }

                  if (depth === 1 || depth === 3) {
                    y -= size.height + margin;
                  } else if (depth === 4) {
                    y += size.height + margin;
                  }
                }

                if (index === 1) {
                  targetSymbol.current = Arrow.left.index;
                }

                if (settings.objectMovementId === ObjectMovement.MOVE_OUTWARDS) {
                  movement = ObjectMovement.MOVE_LEFT;
                }

                break;
            }

            usedPositions.current.push(nextPosition.current);
          }

          break;
        }
        case StartPosition.RANDOM:
        default:
          if (settings.objectMovementId === ObjectMovement.CIRCULAR) {
            icon.originX = (width - size.width) / 2;
            icon.originY = (height - size.height) / 2;
            loopCount = 0;

            do {
              loopCount += 1;

              if (loopCount === MAX_LOOPCOUNT) {
                startPositions.current = [];
              }

              icon.radius = random(size.height, (height - size.height) / 2);
              icon.angle = random(0, 360);
              x = icon.radius * Math.cos(icon.angle) + icon.originX;
              y = icon.radius * Math.sin(icon.angle) + icon.originY;
              position = new Rect(x, y, size.width, size.height);
            } while (overlaps(startPositions.current, position));
          } else if (drill.goalType === GoalType.REMEMBER) {
            if (startPositions.current.length === 0) {
              const totalLength = settings.totalObjects * (size.width + 8) - 8;
              x = random(0, width - totalLength);
              y = random(0, height - size.height);
            } else {
              const lastPos = startPositions.current[startPositions.current.length - 1];
              x = lastPos.x + 8 + size.width;
              y = lastPos.y;
            }

            position = new Rect(x, y, size.width, size.height);
          } else {
            loopCount = 0;

            do {
              loopCount += 1;

              if (loopCount === MAX_LOOPCOUNT) {
                startPositions.current = [];
              }

              switch (settings.objectMovementId) {
                case ObjectMovement.MOVE_DOWN:
                  y = random(size.height / 2, height / 4 - size.height / 2);
                  x = random(size.width / 2, width - size.width / 2);
                  break;
                case ObjectMovement.MOVE_UP:
                  y = random(3 * (height / 4) - size.height / 2, height - size.height / 2);
                  x = random(size.width / 2, width - size.width / 2);
                  break;
                case ObjectMovement.MOVE_RIGHT:
                  x = random(size.width / 2, width / 4 - size.width / 2);
                  y = random(size.height / 2, height - size.height / 2);
                  break;
                case ObjectMovement.MOVE_LEFT:
                  x = random(3 * (width / 2) - size.width / 2, width - size.width / 2);
                  y = random(size.height / 2, height - size.height / 2);
                  break;
                default:
                  y = random(size.height / 2, height - size.height / 2);

                  do {
                    x = random(size.width / 2, width - size.width / 2);
                  } while (x < width / 2 && width / 2 <= x + size.width);

                  break;
              }

              position = new Rect(x, y, size.width, size.height);
            } while (overlaps(startPositions.current, position));
          }

          startPositions.current.push(position);

          break;
        case StartPosition.PERIPHERAL_ROWS: {
          const availableWidth = width / settings.totalObjects;
          let availableHeight = height / settings.totalObjects;
          const i = settings.totalObjects - index - 1;

          const objectMovement =
            settings.objectMovementId === ObjectMovement.MOVE_LINEAR ? targetMovement : settings.objectMovementId;

          switch (objectMovement) {
            case ObjectMovement.MOVE_UP:
              x = random(0, availableWidth - size.width);
              x += availableWidth * i;
              y = height - random(size.height, size.height * 2);
              break;
            case ObjectMovement.MOVE_DOWN:
              x = random(0, availableWidth - size.width);
              x += availableWidth * i;
              y = random(size.height, size.height * 2);
              break;
            case ObjectMovement.MOVE_LEFT:
              y = random(0, availableHeight - size.height);
              y += availableHeight * i;
              x = width - random(size.width, size.width * 2);
              break;
            case ObjectMovement.MOVE_RIGHT:
              y = random(0, availableHeight - size.height);
              y += availableHeight * i;
              x = random(size.width, size.width * 2);
              break;
            default: {
              availableHeight = height / (settings.totalObjects / 2);
              const offset = (availableHeight - settings.objectSize) / 2;

              if ((i + 1) % 2 === 0) {
                x = width - availableHeight + offset;
              } else {
                x = 0 + offset;
              }

              y = Math.floor(i / 2) * availableHeight + offset;
              break;
            }
          }

          break;
        }
        case StartPosition.READING_ROWS: {
          const rows = Math.floor(Math.sqrt(settings.totalObjects));
          let maxPerRow = Math.floor(settings.totalObjects / rows);

          if (maxPerRow < 1) {
            maxPerRow = 1;
          }

          const totalRows = Math.ceil(settings.totalObjects / maxPerRow);
          const { maximumHeight, maximumWidth } = iconSet;

          const startY = (height - maximumHeight * totalRows) / 2;

          y = (startY + (index / maxPerRow || 0) * (maximumHeight + 8) || 0) - 12;
          x = (width - maximumWidth * maxPerRow) / 2;

          if (index > 0 && y === lastStartPosition.current.y) {
            x = lastStartPosition.current.x + maximumWidth + 8;
          }

          lastStartPosition.current.x = x;
          lastStartPosition.current.y = y;
          break;
        }
      }

      icon.setStartPosition(new Rect(x, y, size.width, size.height));

      if (movement !== -1) {
        icon.movement = movement;
      }
    },
    [drill, iconSet, settings]
  );

  const context = useMemo(
    () => ({
      ...outerOptions,
      ...rest,
      arrows: arrowSet,
      assessment,
      backgrounds,
      details,
      drill,
      drillId,
      getIcon,
      getSymbol,
      hazardIconIndex: hazardIconIndex.current,
      iconSet,
      imagesPreloaded,
      initialiseTarget,
      inputDirection: inputDirection.current,
      lastDirection: lastDirection.current,
      lastNumber: lastNumber.current,
      lastStartPosition: lastStartPosition.current,
      leftCount: leftCount.current,
      level,
      maximumLevel,
      nested,
      nextPosition: nextPosition.current,
      nextSymbol: nextSymbol.current,
      postScore,
      rightCount: rightCount.current,
      safeIconIndex: safeIconIndex.current,
      setStartPosition,
      settings,
      symbols,
      targetIconIndex: targetIconIndex.current,
      targetMovement: targetMovement.current,
      targetSymbol: targetSymbol.current,
      unsafeIconIndex: unsafeIconIndex.current,
      userAssessmentId,
      userLevel,
      userProductId,
    }),
    [
      arrowSet,
      assessment,
      backgrounds,
      details,
      drill,
      drillId,
      getIcon,
      getSymbol,
      iconSet,
      imagesPreloaded,
      initialiseTarget,
      level,
      maximumLevel,
      nested,
      outerOptions,
      postScore,
      rest,
      setStartPosition,
      settings,
      symbols,
      userAssessmentId,
      userLevel,
      userProductId,
    ]
  );

  return <DrillContext.Provider value={context}>{children}</DrillContext.Provider>;
}

DrillProvider.propTypes = {
  children: PropTypes.node.isRequired,
  drillId: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  nested: PropTypes.bool,
};

DrillProvider.defaultProps = {
  nested: false,
};
