import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import upperFirst from 'lodash/upperFirst';

const ver = process.env.API_VERSION || 1;
const root = process.env.REACT_APP_ASSET_PATH;

const escapeSpaces = (value) => {
  if (value && typeof value.replace === 'function') {
    return value.replace(' ', '+');
  }

  return value;
};

const buildFilter = (value) => {
  if (!Array.isArray(value) && !isPlainObject(value)) {
    return value;
  }

  // [ 'prop', 'eq', 'value' ] = prop+eq+value
  if (Array.isArray(value) && value.every((v) => !isObject(v))) {
    if (value[1] === 'contains') {
      return `contains(${value[0]},'${value[2]}')`;
    }

    if (value[1] === 'startswith') {
      return `startswith(${value[0]},'${value[2]}')`;
    }

    return value.join('+');
  }

  // [ 'prop', 'in', ['value1', 'value2']]
  if (Array.isArray(value) && !isObject(value[0]) && !isObject(value[1]) && Array.isArray(value[2])) {
    if (value[1] === 'not in') {
      return `not(${value[0]}+in+(${value[2].map((i) => `'${i}'`).join(',')}))`;
    }

    return `${value[0]}+${value[1]}+(${value[2].map((i) => `'${i}'`).join(',')})`;
  }

  // [[ 'prop1', 'eq', 'value1' ], [ 'prop2', 'eq', 'value2' ]] = prop1+eq+value1+and+prop2+eq+value2
  if (Array.isArray(value) && value.every(Array.isArray)) {
    return buildFilter({ and: value });
  }

  // { 'op1': [[ 'prop1', 'eq', 'value1' ], [ 'prop2', 'eq', 'value2' ]]} = prop1+eq+value1+op1+prop2+eq+value2
  if (isObject(value) && !Array.isArray(value)) {
    const filters = [];
    const operators = Object.keys(value);
    const values = Object.values(value);

    for (let i = 0, l = operators.length; i < l; i += 1) {
      const clauses = values[i].map((clause) => buildFilter(clause));
      filters.push(clauses.join(`+${operators[i]}+`));
    }

    if (filters.length === 1) {
      return filters[0];
    }

    return filters.map((filter) => `(${filter})`).join('+and+');
  }

  return null;
};

const buildExpand = (expand) => {
  const expansions = expand.map((value) => {
    if (isString(value)) {
      return value;
    }

    if (isObject(value) && !Array.isArray(value)) {
      const keys = Object.keys(value);
      const values = Object.values(value);
      const clauses = [];

      for (let i = 0, l = keys.length; i < l; i += 1) {
        const clause = `${keys[i]}(${buildExpand(values[i])})`;
        clauses.push(clause);
      }

      return clauses.join(',');
    }

    return value.join(',');
  });

  return expansions ? `$expand=${expansions.join(',')}` : '';
};

const buildPair = (param, value) => {
  if (value === null || value === undefined) {
    return null;
  }

  if (!Array.isArray(value)) {
    return `${param}=${escapeSpaces(value)}`;
  }

  if (!value.length) {
    return null;
  }

  if (param === '$expand') {
    return buildExpand(value);
  }

  if (param !== '$filter') {
    return `${param}=${escapeSpaces(value.join(','))}`;
  }

  const filter = buildFilter(value);

  if (filter) {
    return `${param}=${filter}`;
  }

  return null;
};

export const buildODataUri = (
  path,
  params = {
    $count: null,
    $expand: null,
    $filter: null,
    $orderBy: null,
    $select: null,
    $skip: null,
    $top: null,
  }
) => {
  let retVal = path;
  const addQuery = !path.endsWith('?');

  const pairs = [];
  const keys = Object.keys(params);
  const values = Object.values(params);

  for (let i = 0, l = keys.length; i < l; i += 1) {
    const pair = buildPair(keys[i], values[i]);

    if (pair) {
      pairs.push(pair);
    }
  }

  if (pairs.length) {
    if (addQuery) {
      retVal = `${retVal}?`;
    }

    return `${retVal}${pairs.join('&')}`;
  }

  return retVal;
};

export const getApiRoot = (endpoint, version = ver) => `api/v${version}/${endpoint}`;

export const getArrowUri = (direction, arrow) =>
  `${root}images/arrow${upperFirst(direction.toLowerCase())}/${arrow.id}.${arrow.extension}`;

export const getAssetUri = (assetPath) => `${root}assets/${assetPath}`;

export const getBackgroundUri = (background) => `${root}images/backgrounds/${background.id}.${background.extension}`;

export const getEntityRoot = (entity, version = ver) => `odata/v${version}/${entity}`;

export const getExplainerImageUri = (explainerImage) =>
  `${root}images/explainers/${explainerImage.id}.${explainerImage.extension}`;

export const getIconUri = (icon) => `${root}images/icons/${icon.id}.${icon.extension}`;

export const getImageAssetUri = (assetName) => getAssetUri(`images/${assetName}`);

export const getLookupRoot = (type, version = ver) => `api/v${version}/lookups/${type}`;
