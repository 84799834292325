/* eslint-disable no-console */
/* eslint-disable max-classes-per-file */
import autoBind from 'auto-bind';
import detectTouchEvents from 'detect-touch-events';
import without from 'lodash/without';
import * as PIXI from 'pixi.js';

import { noop } from '../utils';

export const Key = {
  K_0: 'Digit0',
  K_1: 'Digit1',
  K_2: 'Digit2',
  K_3: 'Digit3',
  K_4: 'Digit4',
  K_5: 'Digit5',
  K_6: 'Digit6',
  K_7: 'Digit7',
  K_8: 'Digit8',
  K_9: 'Digit9',

  K_ALT_LEFT: 'AltLeft',
  K_ALT_RIGHT: 'AltRight',
  K_BACKSPACE: 'Backspace',
  K_CTRL_LEFT: 'ControlLeft',
  K_CTRL_RIGHT: 'ControlRight',
  K_DOWN: 'ArrowDown',

  K_ENTER: 'Enter',
  K_ESC: 'Escape',
  K_KP0: 'Numpad0',
  K_KP1: 'Numpad1',
  K_KP2: 'Numpad2',
  K_KP3: 'Numpad3',
  K_KP4: 'Numpad4',
  K_KP5: 'Numpad5',
  K_KP6: 'Numpad6',
  K_KP7: 'Numpad7',
  K_KP8: 'Numpad8',
  K_KP9: 'Numpad9',
  K_LEFT: 'ArrowLeft',
  K_NUMPAD_ENTER: 'NumpadEnter',
  K_RIGHT: 'ArrowRight',
  K_SHIFT_LEFT: 'ShiftLeft',
  K_SHIFT_RIGHT: 'ShiftRight',
  K_SPACE: 'Space',
  K_TAB: 'Tab',
  K_UP: 'ArrowUp',

  K_a: 'KeyA',
  K_b: 'KeyB',
  K_c: 'KeyC',
  K_d: 'KeyD',
  K_e: 'KeyE',
  K_f: 'KeyF',
  K_g: 'KeyG',
  K_h: 'KeyH',
  K_i: 'KeyI',
  K_j: 'KeyJ',
  K_k: 'KeyK',
  K_l: 'KeyL',
  K_m: 'KeyM',
  K_n: 'KeyN',
  K_o: 'KeyO',
  K_p: 'KeyP',
  K_q: 'KeyQ',
  K_r: 'KeyR',
  K_s: 'KeyS',
  K_t: 'KeyT',
  K_u: 'KeyU',
  K_v: 'KeyV',
  K_w: 'KeyW',
  K_x: 'KeyX',
  K_y: 'KeyY',
  K_z: 'KeyZ',

  KEY_DOWN: 1,
  KEY_UP: 2,

  NOEVENT: 0,
  NUMEVENTS: 32000,
  QUIT: 0,
  USEREVENT: 24,
};

export const Mouse = {
  MOUSE_DOWN: 5,
  MOUSE_MOTION: 3,
  MOUSE_UP: 4,
  MOUSE_WHEEL: 6,
};

const getCanvasOffset = (canvas) => {
  const rect = canvas.getBoundingClientRect();

  return [rect.left, rect.top];
};

class EventCache {
  constructor() {
    autoBind(this);
  }

  _listEvents = [];

  get listEvents() {
    return this._listEvents;
  }

  add(...rest) {
    this._listEvents.push(rest);
  }

  remove(...rest) {
    const item = this._listEvents.find(
      (e) => rest[0] === e[0] && rest[1] === e[1] && rest[2] === e[2] && rest[3] === e[3]
    );

    this._listEvents = without(this._listEvents, item);
  }

  flush() {
    for (let i = this._listEvents.length - 1; i >= 0; i -= 1) {
      const item = this._listEvents[i];
      const [obj, _event, fn, options] = item;
      let event = _event;

      if (obj.removeEventListener) {
        obj.removeEventListener(event, fn, options);
      }

      if (event.substring(0, 2) !== 'on') {
        event = `on${event}`;
      }

      if (obj.detachEvent) {
        obj.detachEvent(event, fn);
      }

      obj[event] = null;
    }
  }
}

const eventCache = new EventCache();

const addEvent = (obj, type, fn, useCapture) => {
  if (obj.addEventListener) {
    obj.addEventListener(type, fn, useCapture);
    eventCache.add(obj, type, fn, useCapture);
  } else if (obj.attachEvent) {
    obj[`e${type}${fn}`] = fn;

    obj[type + fn] = () => {
      obj[`e${type}${fn}`](window.event);
    };

    obj.attachEvent(`on${type}`, obj[type + fn]);
    eventCache.add(obj, type, fn, useCapture);
  } else {
    obj[`on${type}`] = obj[`e${type}${fn}`];
  }
};

const removeEvent = (obj, type, fn, useCapture) => {
  if (obj.removeEventListener) {
    obj.removeEventListener(type, fn, useCapture);
    eventCache.remove(obj, type, fn, useCapture);
  } else if (obj.detachEvent) {
    obj.detachEvent(`on${type}`, obj[type + fn]);
    eventCache.remove(obj, type, fn, useCapture);
  } else {
    obj[`on${type}`] = null;
  }
};

const TAP_DURATION = 750; // Shorter than 750ms is a tap, longer is a taphold or drag.
const MOVE_TOLERANCE = 12; // 12px seems to work in most mobile browsers.
const PREVENT_DURATION = 2500; // 2.5 seconds maximum from preventGhostClick call to click
const CLICKBUSTER_THRESHOLD = 25; // 25 pixels in any dimension is the limit for busting clicks.

const hit = (x1, y1, x2, y2) => Math.abs(x1 - x2) < CLICKBUSTER_THRESHOLD && Math.abs(y1 - y2) < CLICKBUSTER_THRESHOLD;

const checkAllowableRegions = (touchCoordinates, x, y) => {
  for (let i = 0; i < touchCoordinates.length; i += 2) {
    if (hit(touchCoordinates[i], touchCoordinates[i + 1], x, y)) {
      touchCoordinates.splice(i, i + 2);

      return true;
    }
  }

  return false;
};

export class Events {
  _clickOnTouchStart = false;
  _document;
  _lastPos = [];
  _lastPreventedTime;
  _rootElement;
  _startTime;
  _tapElement;
  _tapping = false;
  _touchCoordinates;
  _touchStartX;
  _touchStartY;
  _view;
  eventCache;
  ticker;
  queue = [];
  constructor(view, rootElement) {
    this._rootElement = rootElement;
    this._document = document;
    this._view = view;
    this.ticker = PIXI.Ticker.shared;
    this.eventCache = eventCache;

    autoBind(this);

    if (detectTouchEvents.hasSupport) {
      this._view.onClick = noop;
      addEvent(this._view, 'touchstart', this.onTouchStart, true);
    } else {
      addEvent(this._view, 'mousedown', this.onMouseDown, false);
      addEvent(this._view, 'mouseup', this.onMouseUp, false);
    }

    addEvent(this._document, 'keydown', this.onKeyDown, false);
    addEvent(this._document, 'keyup', this.onKeyUp, false);
    addEvent(this._view, 'mousemove', this.onMouseMove, false);
    addEvent(this._view, 'mousewheel', this.onMouseWheel, false);
    addEvent(this._view, 'DOMMouseScroll', this.onMouseWheel, false);
    addEvent(this._view, 'beforeunload', this.onBeforeUnload, false);
  }

  getQueue() {
    return this.queue.splice(0, this.queue.length);
  }

  pop() {
    return this.queue.pop();
  }

  push(event) {
    this.queue.push(event);
  }

  clear() {
    this.queue = [];
  }

  destroy() {
    this.eventCache.flush();
  }

  get clickOnTouchStart() {
    return this._clickOnTouchStart;
  }

  set clickOnTouchStart(value) {
    this._clickOnTouchStart = value;
  }

  onBeforeUnload() {
    this.queue.push({
      type: Key.QUIT,
    });
  }

  onClick(event) {
    if (Date.now() - this._lastPreventedTime > PREVENT_DURATION) {
      return;
    }

    const touches = event.touches && event.touches.length ? event.touches : [event];
    const x = touches[0].clientX;
    const y = touches[0].clientY;

    if (x < 1 && y < 1) {
      return;
    }

    if (checkAllowableRegions(this._touchCoordinates, x, y)) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    event.target?.blur();
  }

  onKeyDown(e) {
    if (!this.ticker.started) {
      return;
    }

    const key = e.code || e.which;
    this.queue.push({
      ctrlKey: e.ctrlKey,
      key,
      metaKey: e.metaKey,
      shiftKey: e.shiftKey,
      type: Key.KEY_DOWN,
    });

    if (
      (!e.ctrlKey &&
        !e.metaKey &&
        ((key >= Key.K_LEFT && key <= Key.K_DOWN) ||
          (key >= Key.K_0 && key <= Key.K_z) ||
          (key >= Key.K_KP1 && key <= Key.K_KP9) ||
          key === Key.K_SPACE ||
          key === Key.K_TAB ||
          key === Key.K_ENTER)) ||
      key === Key.K_ALT ||
      key === Key.K_BACKSPACE
    ) {
      e.preventDefault();
    }
  }

  onKeyUp(e) {
    if (!this.ticker.started) {
      return;
    }

    this.queue.push({
      ctrlKey: e.ctrlKey,
      key: e.code,
      metaKey: e.metaKey,
      shiftKey: e.shiftKey,
      type: Key.KEY_UP,
    });
  }

  onMouseDown(e) {
    if (!this.ticker.started) {
      return;
    }

    const offset = getCanvasOffset(this._view);
    this.queue.push({
      button: e.button,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
      pos: [e.clientX - offset[0], e.clientY - offset[1]],
      shiftKey: e.shiftKey,
      type: Mouse.MOUSE_DOWN,
    });
  }

  onMouseMove(e) {
    if (!this.ticker.started) {
      return;
    }

    const offset = getCanvasOffset(this._view);
    const currentPos = [e.clientX - offset[0], e.clientY - offset[1]];
    let relativePos = [];

    if (this._lastPos.length) {
      relativePos = [this._lastPos[0] - currentPos[0], this._lastPos[1] - currentPos[1]];
    }

    this.queue.push({
      buttons: null, // FIXME, fixable?
      pos: currentPos,
      rel: relativePos,
      timestamp: e.timeStamp,
      type: Key.MOUSE_MOTION,
    });
    this._lastPos = currentPos;
  }

  onMouseUp(e) {
    if (!this.ticker.started) {
      return;
    }

    const offset = getCanvasOffset(this._view);
    this.queue.push({
      button: e.button,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
      pos: [e.clientX - offset[0], e.clientY - offset[1]],
      shiftKey: e.shiftKey,
      type: Key.MOUSE_UP,
    });
  }

  onMouseWheel(e) {
    if (!this.ticker.started) {
      return;
    }

    const offset = getCanvasOffset(this._view);
    const currentPos = [e.clientX - offset[0], e.clientY - offset[1]];
    this.queue.push({
      delta: e.detail || -e.wheelDeltaY / 40,
      pos: currentPos,
      type: Key.MOUSE_WHEEL,
    });
  }

  onTouchMove(event) {
    let touches;

    if (event.currentTouch) {
      touches = event.currentTouch;
    } else if (event.touches && event.touches.length) {
      touches = event.touches;
    } else {
      touches = [event];
    }

    const e = touches[0].originalEvent || touches[0];
    // $rootScope.debugMessage += '[' + x + ', ' + y + ']';
    this.onMouseMove(e);
  }

  onTouchStart(event) {
    const touches = event.touches && event.touches.length ? event.touches : [event];
    const e = touches[0].originalEvent || touches[0];

    if (this._clickOnTouchStart) {
      this.onMouseDown(e);
      this.resetState();

      return;
    }

    if (this._tapping) {
      return; // we can't start another tap without having finished another.
    }

    this._tapping = true;
    this._tapElement = event.target ? event.target : event.srcElement;

    if (this._tapElement.nodeType === 3) {
      this._tapElement = this._tapElement.parent;
    }

    this._startTime = Date.now();

    this._touchStartX = e.clientX;
    this._touchStartY = e.clientY;

    addEvent(this._view, 'touchmove', this.onTouchMove, true);
    addEvent(this._view, 'touchend', this.onTouchEnd, true);
  }

  onTouchEnd(event) {
    removeEvent(this._view, 'touchmove', this.onTouchMove, true);
    removeEvent(this._view, 'touchend', this.onTouchEnd, true);

    const diff = Date.now() - this._startTime;

    let touches;

    if (event.changedTouches && event.changedTouches.length) {
      touches = event.changedTouches;
    } else if (event.touches && event.touches.length) {
      touches = event.touches;
    } else {
      touches = [event];
    }

    const e = touches[0].originalEvent || touches[0];
    const x = e.clientX;
    const y = e.clientY;

    const dist = Math.sqrt((x - this._touchStartX) ** 2 + (y - this._touchStartY) ** 2);

    if (this._tapping && diff < TAP_DURATION && dist < MOVE_TOLERANCE) {
      this.preventGhostClick(x, y);

      if (this._tapElement) {
        this._tapElement.blur();
      }

      if (!this.ticker.started) {
        return;
      }

      this.onMouseDown(e);
    }

    this.resetState();
  }

  preventGhostClick(x, y) {
    if (!this._touchCoordinates) {
      addEvent(this._rootElement, 'click', this.onClick, true);
      addEvent(this._rootElement, 'touchstart', this.onTouchStart, true);
      this._touchCoordinates = [];
    }

    this._lastPreventedTime = Date.now();
    this.checkAllowableRegions(this._touchCoordinates, x, y);
  }

  resetState() {
    this._tapping = false;
  }
}
