/* eslint-disable react/jsx-no-useless-fragment */
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import is from 'is_js';
import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '../providers';
import { actionCreators } from '../redux/eyegym';

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
    borderRadius: '10em',
  },
}));

export function LoginMenu(props) {
  const { user } = useSelector((state) => state.authentication);
  const { logout } = useContext(AuthContext);
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const isAuthenticated = useMemo(() => {
    const id = is.not.online() ? user?.id : user?.profile?.sub;

    return id;
  }, [user?.id, user?.profile?.sub]);

  const handleButtonClick = () => {
    dispatch(actionCreators.resetState());
    logout();
  };

  const authenticatedView = () => (
    <Button className={classes.toolbarButton} color="primary" variant="outlined" onClick={handleButtonClick}>
      Sign out
    </Button>
  );

  return (isAuthenticated && authenticatedView()) || <></>;
}
