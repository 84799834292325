import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { actionCreators as authenticationActionCreators } from '../redux/authentication';
import { routes } from '../routes';
import { AuthService, signOut } from '../services';
import { noop } from '../utils';

import { LogContext } from './LogProvider';

const defaultOptions = {
  createSigninRequest: noop,
  isAuthenticated: noop,
  logout: noop,
  signinRedirect: noop,
  signinRedirectCallback: noop,
  signinSilentCallback: noop,
  signoutRedirectCallback: noop,
};

export const AuthContext = React.createContext(defaultOptions);

export const AuthConsumer = AuthContext.Consumer;

export function AuthProvider(props) {
  const { children, store } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { log } = useContext(LogContext);

  const authService = useMemo(() => new AuthService(navigate, store), [navigate, store]);

  authService.events.addUserLoaded('loaded', (user) => {
    dispatch(authenticationActionCreators.loggedIn(user));
  });

  authService.events.addRedirect('redirect', (uri) => {
    navigate(uri, { replace: true });
  });

  authService.events.addUserUnloaded('unloaded', async () => {
    dispatch(authenticationActionCreators.loggedOut());
    signOut(routes.loggedOut, { log })().then((response) => {
      const returnUrl = (response && response.returnUrl) || routes.loggedOut;
      navigate(returnUrl);
      // dispatch(replace(returnUrl));
    });
  });

  return <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
  store: PropTypes.any.isRequired,
};
