import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DrillContext } from '../../../providers';
import { getIconUri } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  target: {
    width: '100px',
  },
  targetContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  task: {
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
}));

const formatTask = (task, target, showSymbolOnly) => {
  let _task = task;

  if (_task && target && !showSymbolOnly) {
    const name = target.name.toLowerCase();
    let article = 'a';

    switch ([...name][0]) {
      case 'a':
      case 'e':
      case 'i':
      case 'o':
      case 'u':
        article = 'an';
        break;
      default:
        break;
    }

    if (pluralize.isPlural(name)) {
      article = 'the';
    }

    _task = _task.replace(/{article}/gi, article).replace(/{ObjectName}/gi, name);
  }

  _task = _task.charAt(0).toUpperCase() + _task.slice(1);

  return _task;
};

export function StandardTask(props) {
  const { variant, component } = props;
  const { drill } = useContext(DrillContext);
  const { task, targetIcon, showTarget } = useSelector((state) => state.drill);

  const classes = useStyles();

  const formattedTask = useMemo(() => formatTask(task, targetIcon, drill.showSymbolOnly), [task, targetIcon, drill]);

  return (
    <>
      <Typography variant={variant} component={component} className={classes.task}>
        {formattedTask}
      </Typography>
      {showTarget && targetIcon && (
        <Box className={classes.targetContainer}>
          <img alt="target" src={getIconUri(targetIcon)} className={classes.target} />
        </Box>
      )}
    </>
  );
}

StandardTask.propTypes = {
  component: PropTypes.any,
  variant: PropTypes.any,
};

StandardTask.defaultProps = {
  component: 'div',
  variant: 'body1',
};
