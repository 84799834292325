import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export function MaximumLevelReachedModal(props) {
  const { onClose, open, ...others } = props;

  const handleOkayClick = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      aria-labelledby="maximum-level-reached-dialog-title"
      aria-describedby="maximum-level-reached-dialog-text"
      {...others}
    >
      <DialogTitle id="maximum-level-reached-dialog-title">EyeGym</DialogTitle>
      <DialogContent>
        <DialogContentText id="maximum-level-reached-dialog-text">
          You have reached the maximum level for this drill. You can continue to train on this drill at the same level,
          or any previous level.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleOkayClick}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

MaximumLevelReachedModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
