/* eslint-disable react/jsx-no-useless-fragment */
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { DrillType } from '../../../models';
import { DrillContext } from '../../../providers';
import { noop, useWindowDimensions } from '../../../utils';

import { EyeBounce } from './EyeBounce';
import { EyeHandReaction } from './EyeHandReaction';
import { EyeJumps } from './EyeJumps';
import { EyeSkipping } from './EyeSkipping';
import { EyeSpeed } from './EyeSpeed';
import { EyeTracking } from './EyeTracking';
import { PeripheralResponse } from './PeripheralResponse';

export function Game(props) {
  const { onComplete } = props;
  const { drill } = useContext(DrillContext);
  const { width, height } = useWindowDimensions();

  const calculatedHeight = height - 136;
  const calculatedWidth = width - 16;

  const Component = useMemo(() => {
    switch (drill.drillType) {
      case DrillType.EYE_HAND_REACTION:
        return EyeHandReaction;
      case DrillType.EYE_JUMPS:
        return EyeJumps;
      case DrillType.EYE_SPEED:
        return EyeSpeed;
      case DrillType.PERIPHERAL_RESPONSE:
        return PeripheralResponse;
      case DrillType.EYE_TRACKING:
        return EyeTracking;
      case DrillType.EYE_BOUNCE:
        return EyeBounce;
      case DrillType.EYE_SKIPPING:
        return EyeSkipping;
      default:
        throw new Error(`${drill.drillType} has not been implemented.`);
    }
  }, [drill]);

  return <>{Component && <Component height={calculatedHeight} width={calculatedWidth} onComplete={onComplete} />}</>;
}

Game.propTypes = {
  onComplete: PropTypes.func,
};

Game.defaultProps = {
  onComplete: noop,
};
