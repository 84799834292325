export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_DRILL_STAGE = 'SET_DRILL_STAGE';
export const SET_HAZARD_ICON = 'SET_HAZARD_ICON';
export const SET_INPUT_DIRECTION = 'SET_INPUT_DIRECTION';
export const SET_LAST_BACKGROUND_INDEX = 'SET_LAST_BACKGROUND_INDEX';
export const SET_LAST_DIRECTION = 'SET_LAST_DIRECTION';
export const SET_SAFE_ICON = 'SET_SAFE_ICON';
export const SET_SHOW_SCORE = 'SET_SHOW_SCORE';
export const SET_SHOW_TARGET = 'SET_SHOW_TARGET';
export const SET_TARGET_ICON = 'SET_TARGET_ICON';
export const SET_TARGET_MOVEMENT = 'SET_TARGET_MOVEMENT';
export const SET_TASK = 'SET_TASK';
export const SET_UNSAFE_ICON = 'SET_UNSAFE_ICON';
export const TRIGGER_BACKGROUND_UPDATE = 'TRIGGER_BACKGROUND_UPDATE';
export const TRIGGER_DRILL_RESET = 'TRIGGER_DRILL_RESET';
