export const ADD_OFFLINE_TEAM = 'ADD_OFFLINE_TEAM';
export const FETCH_OFFLINE_TEAM_USERS_BEGIN = 'FETCH_OFFLINE_TEAM_USERS_BEGIN';
export const FETCH_OFFLINE_TEAM_USERS_FAILURE = 'FETCH_OFFLINE_TEAM_USERS_FAILURE';
export const FETCH_OFFLINE_TEAM_USERS_SUCCESS = 'FETCH_OFFLINE_TEAM_USERS_SUCCESS';
export const SET_SYNCHRONISED_USER_COUNT = 'SET_SYNCHRONISED_USER_COUNT';
export const SYNCHRONISE_ARROW_SET_BEGIN = 'SYNCHRONISE_ARROW_SET_BEGIN';
export const SYNCHRONISE_ARROW_SET_FAILURE = 'SYNCHRONISE_ARROW_SET_FAILURE';
export const SYNCHRONISE_ARROW_SET_SUCCESS = 'SYNCHRONISE_ARROW_SET_SUCCESS';
export const SYNCHRONISE_BACKGROUNDS_BEGIN = 'SYNCHRONISE_BACKGROUNDS_BEGIN';
export const SYNCHRONISE_BACKGROUNDS_FAILURE = 'SYNCHRONISE_BACKGROUNDS_FAILURE';
export const SYNCHRONISE_BACKGROUNDS_SUCCESS = 'SYNCHRONISE_BACKGROUNDS_SUCCESS';
export const SYNCHRONISE_DRILLS_BEGIN = 'SYNCHRONISE_DRILLS_BEGIN';
export const SYNCHRONISE_DRILLS_FAILURE = 'SYNCHRONISE_DRILLS_FAILURE';
export const SYNCHRONISE_DRILLS_SUCCESS = 'SYNCHRONISE_DRILLS_SUCCESS';
export const SYNCHRONISE_EXPLAINER_IMAGES_BEGIN = 'SYNCHRONISE_EXPLAINER_IMAGES_BEGIN';
export const SYNCHRONISE_EXPLAINER_IMAGES_FAILURE = 'SYNCHRONISE_EXPLAINER_IMAGES_FAILURE';
export const SYNCHRONISE_EXPLAINER_IMAGES_SUCCESS = 'SYNCHRONISE_EXPLAINER_IMAGES_SUCCESS';
export const SYNCHRONISE_ICON_SET_BEGIN = 'SYNCHRONISE_ICON_SET_BEGIN';
export const SYNCHRONISE_ICON_SET_FAILURE = 'SYNCHRONISE_ICON_SET_FAILURE';
export const SYNCHRONISE_ICON_SET_SUCCESS = 'SYNCHRONISE_ICON_SET_SUCCESS';
export const SYNCHRONISE_PRODUCT_BEGIN = 'SYNCHRONISE_PRODUCT_BEGIN';
export const SYNCHRONISE_PRODUCT_FAILURE = 'SYNCHRONISE_PRODUCT_FAILURE';
export const SYNCHRONISE_PRODUCT_SUCCESS = 'SYNCHRONISE_PRODUCT_SUCCESS';
export const SYNCHRONISE_USER_ASSESSMENTS_BEGIN = 'SYNCHRONISE_USER_ASSESSMENTS_BEGIN';
export const SYNCHRONISE_USER_ASSESSMENTS_FAILURE = 'SYNCHRONISE_USER_ASSESSMENTS_FAILURE';
export const SYNCHRONISE_USER_ASSESSMENTS_SUCCESS = 'SYNCHRONISE_USER_ASSESSMENTS_SUCCESS';
export const SYNCHRONISE_USER_BEGIN = 'SYNCHRONISE_USER_BEGIN';
export const SYNCHRONISE_USER_FAILURE = 'SYNCHRONISE_USER_FAILURE';
export const SYNCHRONISE_USER_PRODUCT_BEGIN = 'SYNCHRONISE_USER_PRODUCT_BEGIN';
export const SYNCHRONISE_USER_PRODUCT_FAILURE = 'SYNCHRONISE_USER_PRODUCT_FAILURE';
export const SYNCHRONISE_USER_PRODUCT_SUCCESS = 'SYNCHRONISE_USER_PRODUCT_SUCCESS';
export const SYNCHRONISE_USER_SCORES_BEGIN = 'SYNCHRONISE_USER_SCORES_BEGIN';
export const SYNCHRONISE_USER_SCORES_FAILURE = 'SYNCHRONISE_USER_SCORES_FAILURE';
export const SYNCHRONISE_USER_SCORES_SUCCESS = 'SYNCHRONISE_USER_SCORES_SUCCESS';
export const SYNCHRONISE_USER_SUCCESS = 'SYNCHRONISE_USER_SUCCESS';
