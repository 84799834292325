/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-one-expression-per-line */
import DotIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { plainToClass } from 'class-transformer';
import clsx from 'clsx';
import maxBy from 'lodash/maxBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TrainingFrequency, User } from '../models';
import { EyeGymContext } from '../providers';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  horizontal: {
    '& dd': {
      marginLeft: '55%',
    },
    '& dt': {
      clear: 'left',
      float: 'left',
      fontWeight: 'bold',
      overflow: 'hidden',
      textAlign: 'right',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '50%',
    },
    margin: theme.spacing(1),
  },
  row: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    },
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  strong: {
    color: 'white',
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function UserInfo(props) {
  const { md, xs } = props;
  const { scores } = useSelector((state) => state.eyegym);

  const { user: _user, product, trainingFrequency, expiryDate } = useContext(EyeGymContext);

  const user = useMemo(() => plainToClass(User, _user), [_user]);

  const lastSession = useMemo(() => {
    if (!scores?.length) {
      return null;
    }

    const lastScore = maxBy(scores, (s) => moment.utc(s.scoreDate).valueOf());
    const date = moment.utc(lastScore.scoreDate).local();

    if (date.isSameOrBefore('1970-01-01')) {
      return null;
    }

    return date;
  }, [scores]);

  const timeTrained = useMemo(() => {
    let filteredScores;

    switch (trainingFrequency) {
      case TrainingFrequency.PER_WEEK:
        filteredScores = scores.filter((s) => moment.utc(s.scoreDate).isSame(moment.utc(), 'week'));
        break;
      case TrainingFrequency.PER_MONTH:
        filteredScores = scores.filter((s) => moment.utc(s.scoreDate).isSame(moment.utc(), 'month'));
        break;
      default:
        filteredScores = scores.filter((s) => moment.utc(s.scoreDate).isSame(moment.utc(), 'day'));
        break;
    }

    const retVal = filteredScores.reduce((sum, s) => sum + s.duration, 0);

    return retVal;
  }, [trainingFrequency, scores]);

  const timeRemaining = useMemo(() => {
    if (!user || !user.team) {
      return 0;
    }

    const trainingTime = user.forceTrainingOverrides ? user.trainingTime : user.team.trainingTime;
    const isValid = expiryDate.isSameOrAfter(moment.utc(), 'day') && product && !product.isComplete;

    const value = trainingTime * 60 - timeTrained;

    return isValid && value > 0 ? value : 0;
  }, [user, expiryDate, product, timeTrained]);

  const totalTrainingTime = useMemo(() => scores.reduce((sum, s) => sum + s.duration, 0), [scores]);

  const expired = useMemo(() => moment.utc(expiryDate).isBefore(moment.utc()), [expiryDate]);

  const expiryRelationship = useMemo(() => (expired ? 'expired on' : 'is valid until'), [expired]);

  const formatTimespan = (input) => {
    if (Number.isNaN(input)) {
      return null;
    }

    const totalSeconds = Math.abs(input);

    if (totalSeconds === 0) {
      return '0 minutes';
    }

    const m = Math.floor(totalSeconds / 60);
    const s = Math.floor(totalSeconds - m * 60);

    return (
      <>
        {m ? (
          <>
            <strong>{m}</strong>
            <span>{` minute${m === 1 ? '' : 's'}`}</span>
          </>
        ) : (
          <></>
        )}
        {m && s ? <> and </> : <></>}
        {s ? (
          <>
            <strong>{s}</strong>
            <span>{` second${s === 1 ? '' : 's'}`}</span>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const period = useMemo(() => {
    switch (trainingFrequency) {
      case 'PerWeek':
        return 'this week';
      case 'PerMonth':
        return 'this month';
      default:
        return 'today';
    }
  }, [trainingFrequency]);

  const nextSession = useMemo(() => {
    switch (trainingFrequency) {
      case 'PerWeek':
        return 'next week';
      case 'PerMonth':
        return 'next month';
      default:
        return 'tomorrow';
    }
  }, [trainingFrequency]);

  const classes = useStyles(props);

  const lastSessionView = () => (
    <Typography component="span" variant="body2">
      You last trained{' '}
      {lastSession.isSame(moment.utc(), 'day') ? (
        <>
          <strong className={classes.strong}>today</strong> at{' '}
        </>
      ) : (
        <>
          on <strong className={classes.strong}>{lastSession.format('DD MMMM yyyy')}</strong> at{' '}
        </>
      )}
      <strong className={classes.strong}>{lastSession.format('HH:mm')}</strong>.
    </Typography>
  );

  return (
    <Grid item xs={xs} md={md}>
      <Box className={clsx(classes.card, classes.column)}>
        <Box sx={{ p: 1 }} className={clsx(classes.row, classes.wrap, classes.header)}>
          <Box className={clsx(classes.stretched, classes.headline)}>Welcome {user?.name}</Box>
        </Box>
        <Box sx={{ p: 1 }} className={clsx(classes.row, classes.wrap)}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <DotIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                You are in the <strong className={classes.strong}>{user?.team?.name}</strong> team.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DotIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                You are using the <strong className={classes.strong}>{product?.name}</strong> program.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DotIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                Your program {expiryRelationship}{' '}
                <strong className={classes.strong}>{expiryDate.format('DD MMMM yyyy')}</strong>.
              </ListItemText>
            </ListItem>
            {!expired && (
              <>
                <ListItem>
                  <ListItemIcon>
                    <DotIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {lastSession ? lastSessionView() : 'You have not trained yet on this program.'}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DotIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {timeTrained ? (
                      <>
                        You have trained for <strong className={classes.strong}>{formatTimespan(timeTrained)}</strong>{' '}
                        {period}.
                      </>
                    ) : (
                      `You haven't trained ${period}.`
                    )}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DotIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {timeRemaining > 0 && (
                      <>
                        You can train for a further{' '}
                        <strong className={classes.strong}>{formatTimespan(timeRemaining)}</strong>.
                      </>
                    )}
                    {timeRemaining <= 0 && <>Come back {nextSession} and try again.</>}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <DotIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    In all you have trained for a total of{' '}
                    <strong className={classes.strong}>{formatTimespan(totalTrainingTime)}</strong>.
                  </ListItemText>
                </ListItem>
              </>
            )}
          </List>
        </Box>
      </Box>
    </Grid>
  );
}

UserInfo.propTypes = {
  md: PropTypes.number,
  xs: PropTypes.number,
};

UserInfo.defaultProps = {
  md: 6,
  xs: 12,
};
