import { Event } from './Event';

export class AuthServiceEvents {
  constructor() {
    this._redirect = new Event('Redirect');
    this._userLoaded = new Event('User loaded');
    this._userUnloaded = new Event('User unloaded');
  }

  load(user, raiseEvents = true) {
    if (raiseEvents) {
      return this._userLoaded.raise(user);
    } else {
      return Promise.resolve();
    }
  }

  redirect(uri, raiseEvents = true) {
    if (raiseEvents) {
      return this._redirect.raise(uri);
    } else {
      return Promise.resolve();
    }
  }

  unload(raiseEvents = true) {
    if (raiseEvents) {
      return this._userUnloaded.raise();
    } else {
      return Promise.resolve();
    }
  }

  addUserLoaded(key, cb) {
    this._userLoaded.addHandler(key, cb);
  }

  removeUserLoaded(key) {
    this._userLoaded.removeHandler(key);
  }

  addRedirect(key, cb) {
    this._redirect.addHandler(key, cb);
  }

  removeRedirect(key) {
    this._redirect.removeHandler(key);
  }

  addUserUnloaded(key, cb) {
    this._userUnloaded.addHandler(key, cb);
  }

  removeUserUnloaded(key) {
    this._userUnloaded.removeHandler(key);
  }
}
