import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './components';

//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById('root');

ReactDOM.render(<App />, rootElement);

//serviceWorkerRegistration.register();
