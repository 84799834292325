/* eslint-disable no-console */
import { FetchError, FetchErrorEx } from '../errors/FetchError';

const fetchRetry = require('fetch-retry');

const makeRequest = (method) => (url, options) => {
  return fetch(url, { ...options, method })
    .catch((e) => {
      throw new FetchErrorEx({ cause: e, client: true });
    })
    .then((res) => {
      if (res.ok) {
        return res;
      }

      throw new FetchErrorEx({ res, server: true, status: res.status });
    });
};

const get = makeRequest('GET');
const post = makeRequest('POST');
const put = makeRequest('PUT');

const handleErrors = (response) => {
  if (!response.ok) {
    const message =
      response.statusText || (response.status === 400 ? 'No data found' : 'An unhandled exception has occurred');

    throw new FetchError(message, response.status);
  }

  return response;
};

const getRetry = fetchRetry(get);
const postRetry = fetchRetry(post);
const putRetry = fetchRetry(put);

export const fetchData = async (url, authToken = undefined) => {
  let params = {
    retries: 3,
    retryDelay: 1000,
    retryOn: [503],
  };

  if (authToken) {
    params = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      ...params,
    };
  }

  const response = await getRetry(url, params);
  const wrappedResponse = await handleErrors(response);

  return wrappedResponse.json();
};

export const postData = async (url, body, authToken = undefined) => {
  let params = {
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  if (authToken) {
    params = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...params.headers,
      },
      ...params,
    };
  }

  const response = await postRetry(url, params);
  const wrappedResponse = await handleErrors(response);

  try {
    if (wrappedResponse.status === 204) {
      return response.statusText;
    }

    return wrappedResponse.json();
  } catch (err) {
    if (wrappedResponse.status < 400) {
      return wrappedResponse.statusText;
    }

    throw err;
  }
};

export const putData = async (url, body, authToken = undefined) => {
  let params = {
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  };

  if (authToken) {
    params = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...params.headers,
      },
      ...params,
    };
  }

  const response = await putRetry(url, params);
  const wrappedResponse = await handleErrors(response);

  return wrappedResponse.json();
};

export default fetchData;
