import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InputDirection, ObjectMovement } from '../../../models';
import { DrillContext } from '../../../providers';

const useStyles = makeStyles({
  html: {
    whiteSpace: 'pre-wrap',
  },
});

const formatEyeSpinTask = (task, movement, direction) => {
  if (task && movement && direction) {
    let startPosition;
    let _inputDirection;
    let moveDirection;

    switch (movement) {
      case ObjectMovement.MOVE_UP:
        startPosition = 'bottom';
        moveDirection = 'up';
        break;
      case ObjectMovement.MOVE_DOWN:
        startPosition = 'top';
        moveDirection = 'down';
        break;
      case ObjectMovement.MOVE_LEFT:
        startPosition = 'right';
        moveDirection = 'across';
        break;
      default:
        startPosition = 'left';
        moveDirection = 'across';
        break;
    }

    switch (direction) {
      case InputDirection.LEFT_RIGHT:
        _inputDirection = 'left to right';
        break;
      case InputDirection.RIGHT_LEFT:
        _inputDirection = 'right to left';
        break;
      case InputDirection.TOP_BOTTOM:
        _inputDirection = 'top to bottom';
        break;
      default:
        _inputDirection = 'bottom to top';
        break;
    }

    startPosition = `<strong class="text-danger">${startPosition}</strong>`;
    _inputDirection = `<strong class="text-danger">${_inputDirection}</strong>`;
    moveDirection = `<strong class="text-danger">${moveDirection}</strong>`;

    return task
      .replace(/{startPosition}/gi, startPosition)
      .replace(/{inputDirection}/gi, _inputDirection)
      .replace(/{moveDirection}/gi, moveDirection);
  }

  return task;
};

export function EyeSpinTask(props) {
  const { variant, component } = props;
  const { task } = useSelector((state) => state.drill);
  const { targetMovement, inputDirection: _inputDirection } = useContext(DrillContext);

  const classes = useStyles();

  const formattedTask = useMemo(
    () => formatEyeSpinTask(task, targetMovement, _inputDirection),
    [task, targetMovement, _inputDirection]
  );

  return (
    <Typography variant={variant} component={component} className={classes.html}>
      {formattedTask}
    </Typography>
  );
}

EyeSpinTask.propTypes = {
  component: PropTypes.any,
  variant: PropTypes.any,
};

EyeSpinTask.defaultProps = {
  component: 'div',
  variant: 'body1',
};
