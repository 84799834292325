import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import * as Loads from 'react-loads';
import { useErrorBoundary } from 'use-error-boundary';

import { CacheProvider, LogContext } from '../providers';
import { fetchVersion } from '../services';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    textAlign: 'center',
  },
  footerText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '50%',
    },
  },
}));

export function Footer(props) {
  const { log } = useContext(LogContext);
  const { className } = props;
  const { ErrorBoundary } = useErrorBoundary();

  const year = useMemo(() => {
    const date = new Date();

    return date.getFullYear();
  }, []);

  const classes = useStyles(props);

  const {
    response: version,
    isResolved,
    isCached,
  } = Loads.useLoads('version', fetchVersion({ log }), {
    cacheProvider: CacheProvider,
    cacheStrategy: 'context-only',
    loadPolicy: 'cache-and-load',
    variables: [],
  });

  return (
    <ErrorBoundary>
      <footer className={className}>
        <Container maxWidth="md" className={classes.footerContainer}>
          <small className={classes.footerText}>
            &copy; {year} EyeGym. All rights reserved. This software is protected by local and international copyright
            laws.
            {(isCached || isResolved) && ` v${version}`}
          </small>
        </Container>
      </footer>
    </ErrorBoundary>
  );
}

Footer.propTypes = {
  className: PropTypes.any,
};

Footer.defaultProps = {
  className: undefined,
};
