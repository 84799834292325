import { CLEAR_STORE, LOGGED_IN, LOGGED_OUT, RESET_AUTH_STATE, SET_SILENT_RENEW } from './actionTypes';

const clearStore = () => ({
  type: CLEAR_STORE,
});

const loggedIn = (user) => ({
  payload: {
    user,
  },
  type: LOGGED_IN,
});

const loggedOut = () => ({
  type: LOGGED_OUT,
});

const resetAuthState = () => ({
  type: RESET_AUTH_STATE,
});

const setSilentRenew = (value) => ({
  payload: {
    isSilentRenew: value,
  },
  type: SET_SILENT_RENEW,
});

export const actionCreators = {
  clearStore,
  loggedIn,
  loggedOut,
  resetAuthState,
  setSilentRenew,
};
