/* eslint-disable react/jsx-no-useless-fragment */
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EyeGymContext } from '../../../providers';
import { actionCreators } from '../../../redux/eyegym';
import { getExplainerImageUri } from '../../../utils';

const useStyles = makeStyles({
  responsive: {
    display: 'block',
    height: 'auto',
    maxWidth: '100%',
  },
  thumbnail: {
    '-webkit-transition': 'all .2s ease-in-out',
    backgroundColor: 'rgba(255,255,255,0.54)',
    border: '1px solid #ddd',
    borderRadius: '4px',
    display: 'inline-block',
    height: 'auto',
    lineHeight: 1.428571429,
    maxWidth: '100%',
    padding: '4px',
    transition: 'all .2s ease-in-out',
  },
});

export function ExplainerImage(props) {
  const { explainerImageId } = props;
  const { explainerImage } = useSelector((state) => state.eyegym);
  const dispatch = useDispatch();
  const { log } = useContext(EyeGymContext);
  const classes = useStyles();

  useEffect(() => {
    if (explainerImageId) {
      dispatch(actionCreators.fetchExplainerImage(explainerImageId, { log }));
    }
  }, [dispatch, explainerImageId, log]);

  return explainerImage ? (
    <img
      alt="explainer"
      src={getExplainerImageUri(explainerImage)}
      className={clsx(classes.responsive, classes.thumbnail)}
    />
  ) : (
    <></>
  );
}

ExplainerImage.propTypes = {
  explainerImageId: PropTypes.string.isRequired,
};
