/* eslint-disable no-console */
import { Tween } from '@tweenjs/tween.js';
import random from 'lodash/random';
import moment from 'moment';
import * as PIXI from 'pixi.js';

import { gradient } from '../utils/gradient';

import { GoalType, ObjectMovement, StartPosition } from './Drill';
import { Rect } from './Rect';

const getFrameChange = (value, delta) => value * delta; // / 1000;
export class Icon {
  _drillArea = {
    height: 0,
    width: 0,
  };

  _flipHorizontal;
  _goalType;
  _hasIcon = false;
  _hidden;
  _iconIndex = -1;
  _isHitTestVisible = true;
  _isShapeShifting = false;
  _loader;
  _minDisplayTime = 0;
  _mouseOver = false;
  _overlayOpacity;
  _rotationSpeed;
  _selectedMovement = ObjectMovement.MOVE_RIGHT;
  _settings; // see if we actually need this
  _shapeShiftCount = 0;
  _shapeShiftElapsedTime = 0;
  _shapeShiftInterval = 0;
  _showSymbolOnly;
  _size = 100;
  _speed = 0;
  _speedR = 0;
  _speedX = 0;
  _speedY = 0;
  _iconSprite;
  _symbolSprite;
  _startDelay;
  _startTime;
  _symbolIndex;
  _targetMovement;
  _trackStart;
  _trackTime = 0;
  _trackTimeTotal = 0;
  _log = null;

  angle = 0;
  container = new PIXI.Container();
  isTarget = false;
  movement = -1;
  originX = 0;
  originY = 0;
  position = new Rect();
  pulseCount = 0;
  pulseDelay = 0;
  pulseDirection = 1;
  pulseStep = 0;
  radius = 0;
  sizeMin = 0;
  visible;

  constructor(
    width,
    height,
    flipHorizontal,
    goalType,
    overlayOpacity,
    rotationSpeed,
    settings,
    showSymbolOnly,
    targetMovement,
    log
  ) {
    this._drillArea.width = width || 0;
    this._drillArea.height = height || 0;
    this._flipHorizontal = flipHorizontal;
    this._goalType = goalType;
    this._loader = PIXI.Loader.shared;
    this._overlayOpacity = overlayOpacity;
    this._rotationSpeed = rotationSpeed;
    this._settings = settings;
    this._showSymbolOnly = showSymbolOnly;
    this._targetMovement = targetMovement;
    this._startDelay = random(settings.startDelayMinimum * 1000, settings.startDelayMaximum * 1000);
    this.visible = this._startDelay === 0 || this._goalType === GoalType.EYE_SITUPS;
    this.container.alpha = this.visible ? 1 : 0;
    this._log = log;
  }

  get elapsedTime() {
    return this._startTime ? moment.utc().diff(this._startTime) : -1;
  }

  get iconIndex() {
    return this._iconIndex;
  }

  get iconSprite() {
    return this._iconSprite;
  }

  get symbolSprite() {
    return this._symbolSprite;
  }

  /**
   * @param {number} value
   */
  set minDisplayTime(value) {
    this._minDisplayTime = value;
  }

  get shapeShiftCount() {
    return this._shapeShiftCount;
  }

  /**
   * @param {number} value
   */
  set shapeShiftInterval(value) {
    this._shapeShiftInterval = value;
  }

  get size() {
    const icon = this.container.children?.length ? this.container.getChildAt(0) : null;

    if (icon instanceof PIXI.Sprite) {
      return {
        height: icon.height * icon.scale.y,
        width: icon.width * icon.scale.x,
      };
    }

    if (icon) {
      return {
        height: this._size,
        width: this._size,
      };
    }

    return { height: 0, width: 0 };
  }

  get speed() {
    return this._speed;
  }

  /**
   * @param {number} value
   */
  set speed(value) {
    if (value) {
      this._speed = value;
      this._speedX = value;
      this._speedY = value;
    }

    if (this._settings.objectMovementId === ObjectMovement.RANDOM_BOUNCE) {
      if (random(1, 2) === 1) {
        this._speedX = random(this._speedX / 3, this._speedX);
      } else {
        this._speedY = random(this._speedY / 3, this._speedY);
      }

      this._speedR = this._rotationSpeed;

      switch (random(1, 3)) {
        case 1:
          this._speedX *= -1;
          break;
        case 2:
          this._speedY *= -1;
          break;
        default:
          this._speedX *= -1;
          this._speedY *= -1;
          break;
      }
    }

    this.setMovement();
  }

  get startDelay() {
    return this._startDelay;
  }

  set startDelay(value) {
    this._startDelay = value;

    if (value > 0 && this._goalType === GoalType.EYE_SITUPS) {
      this.visible = false;
      this.container.alpha = 0;
    }
  }

  get startTime() {
    return this._startTime;
  }

  set startTime(value) {
    if (value) {
      this._startTime = value;
    } else {
      this._startTime = moment.utc();
    }
  }

  get symbolIndex() {
    return this._symbolIndex;
  }

  get trackTime() {
    return this._trackTime;
  }

  get zIndex() {
    return this.container.zIndex;
  }

  set zIndex(value) {
    this.container.zIndex = value;
  }

  // eslint-disable-next-line class-methods-use-this
  centreSprite(sprite, centre = 0.5) {
    sprite?.anchor?.set(centre);
  }

  clearStartTime() {
    this._startTime = undefined;
  }

  fade(opacity) {
    this._isHitTestVisible = false;
    const tween = new Tween(this.container).to({ alpha: opacity }, 100);
    tween.start();
  }

  isMouseOver(point) {
    const icon = this.container.children?.length ? this.container.getChildAt(0) : null;

    if (!icon) {
      return false;
    }

    let width;
    let height;

    if (icon instanceof PIXI.Sprite) {
      width = icon.width;
      height = icon.height;
    } else {
      width = this._size;
      height = this._size;
    }

    const local = icon.toLocal(point);

    const bounds = {
      x: { max: width, min: 0 },
      y: { max: height, min: 0 },
    };

    if (bounds.x.min <= local.x && local.x <= bounds.x.max && bounds.y.min <= local.y && local.y <= bounds.y.max) {
      return true;
    }

    return false;
  }

  isMouseTarget(point) {
    return this._isHitTestVisible && this.isMouseOver(point);
  }

  scaleImage(bmp, factor = 1) {
    if (factor > 1) {
      // eslint-disable-next-line no-param-reassign
      factor = 1 / factor;
    }

    const scale = (this._size * factor) / bmp.height;
    this.scale = scale;
    bmp.scale.set(scale);
  }

  setGradiantFill(colour1, colour2) {
    colour1.lightness += (1 - colour1.lightness) / 8;
    colour2.lightness += (1 - colour2.lightness) / 8;

    const c1 = colour1.getWebColour('_');
    const c2 = colour2.getWebColour('_');

    const texture = gradient(c1, c2, this._size, this._size);

    const fill = new PIXI.Graphics();
    fill.beginTextureFill({ texture }).drawRect(0, 0, this._size, this._size).endFill();

    const flash = new PIXI.Graphics();
    flash.beginFill(0xff0000).drawRect(0, 0, this._size, this._size).endFill();
    flash.alpha = 0;

    this.container.addChild(fill);
    this.container.addChild(flash);
  }

  setIcon(icon) {
    if (!icon) {
      return;
    }

    const resource = this._loader.resources[icon.id];

    if (!resource) {
      if (this._log) {
        this._log.error('Loader: {loader} does not contain data for Icon: {icon}', {
          icon,
          loader: this._loader,
        });
      }

      return;
    }

    const iconTexture = new PIXI.Texture(resource.texture);

    if (iconTexture && !this._showSymbolOnly) {
      this._iconIndex = icon.index;
      this._hasIcon = true;
      const width = this._size;
      const height = this._size;
      const sprite = PIXI.Sprite.from(iconTexture);
      sprite.width = width;
      sprite.height = height;
      this.centreSprite(sprite);
      this.scaleImage(sprite);

      if (this._flipHorizontal) {
        sprite.scale.x *= -1;
      }

      if (this.container.children?.length && this.container.getChildAt(0)) {
        this.container.removeChildAt(0);
      }

      this.container.addChildAt(sprite, 0);
      this._iconSprite = sprite;
    }
  }

  setImages(icon, symbol) {
    this.setIcon(icon);

    if (symbol) {
      this.setSymbol(symbol);
    }
  }

  setMovement() {
    switch (this.movement) {
      case ObjectMovement.MOVE_DOWN:
        this._speedX = 0;

        if (this._speedY < 0) {
          this._speedY *= -1;
        }

        break;
      case ObjectMovement.MOVE_UP:
        this._speedX = 0;

        if (this._speedY > 0) {
          this._speedY *= -1;
        }

        break;
      case ObjectMovement.MOVE_LEFT:
        this._speedY = 0;

        if (this._speedX > 0) {
          this._speedX *= -1;
        }

        break;
      case ObjectMovement.MOVE_RIGHT:
        this._speedY = 0;

        if (this._speedX < 0) {
          this._speedY *= -1;
        }

        break;
      case ObjectMovement.HORIZONTAL: {
        const leftOrRight = random(1, 2);
        this._speedY = 0;

        if (leftOrRight === 1) {
          if (this._speedX > 0) {
            this._speedX *= -1;
          }

          this._selectedMovement = ObjectMovement.MOVE_LEFT;
        } else {
          if (this._speedX < 0) {
            this._speedX *= -1;
          }

          this._selectedMovement = ObjectMovement.MOVE_RIGHT;
        }

        break;
      }
      case ObjectMovement.VERTICAL: {
        const upOrDown = random(1, 2);
        this._speedX = 0;

        if (upOrDown === 1) {
          if (this._speedY > 0) {
            this._speedY *= -1;
          }

          this._selectedMovement = ObjectMovement.MOVE_UP;
        } else {
          if (this._speedY < 0) {
            this._speedY *= -1;
          }

          this._selectedMovement = ObjectMovement.MOVE_DOWN;
        }

        break;
      }
      default:
        break;
    }
  }

  setSize(iconIndex) {
    if (
      (this._goalType === GoalType.IDENTIFY_CORRECT ||
        this._goalType === GoalType.EYE_PUSHUPS ||
        this._goalType === GoalType.EyeStretch ||
        this._goalType === GoalType.LINE_TRACKING ||
        this._goalType === GoalType.LINE_TRACKING_MULTIPLE) &&
      this._settings.startPosition === StartPosition.READING_ROWS
    ) {
      this._size = this._settings.objectSize;

      if (this._settings.objectSizeMinimum < this._size) {
        this.movement = ObjectMovement.PULSE;
        this.sizeMin = this._settings.objectSizeMinimum;
        this.pulseDelay = (this._settings.paneTimeout / 4) * 100;
      }
    } else {
      if (this._goalType === GoalType.BATAK || this._goalType === GoalType.GREEN_RED_LIGHT) {
        this._size = this._settings.objectSize;
      } else {
        this._size =
          iconIndex === 0
            ? this._settings.objectSize
            : random(this._settings.objectSizeMinimum, this._settings.objectSizeMaximum);
      }

      this.movement =
        this._settings.objectMovementId === ObjectMovement.MOVE_LINEAR
          ? this._targetMovement
          : this._settings.objectMovementId;
    }

    if (this.movement === ObjectMovement.PULSE) {
      this.pulseStep = (this._size - this.sizeMin) / 30; // assuming 60 FPS.
    }
  }

  setStartPosition(rect) {
    this.position = rect;
    this.container.position.set(rect.x, rect.y);
  }

  setSymbol(symbol) {
    if (!symbol) {
      return;
    }

    this._symbolIndex = symbol.index;

    const symbolTexture = new PIXI.Texture(this._loader.resources[symbol.id].texture);

    if (symbolTexture) {
      const sprite = PIXI.Sprite.from(symbolTexture);
      this.centreSprite(sprite);
      let factor = 1;

      if (this._hasIcon && !this._showSymbolOnly) {
        sprite.alpha = this._overlayOpacity;
        factor = 0.8;
      }

      this.scaleImage(sprite, factor);
      this.container.addChild(sprite);
      this._symbolSprite = sprite;
    }
  }

  setVisible(visible, duration) {
    if (visible === this.visible) {
      return;
    }

    this.visible = visible;
    let tween;

    if (visible) {
      this._hidden = false;
      tween = new Tween(this.container).to({ alpha: 1 }, duration || 100);
    } else {
      this._hidden = true;
      tween = new Tween(this.container).to({ alpha: 0 }, duration || 30);
    }

    tween.start();
  }

  shapeShift(delta, cb) {
    if (this._isShapeShifting) {
      return;
    }

    this._shapeShiftElapsedTime += delta;

    if (this._shapeShiftElapsedTime >= this._shapeShiftInterval) {
      this._isShapeShifting = true;
      this._shapeShiftCount += 1;
      this._shapeShiftElapsedTime = 0;
      this._shapeShiftInterval = random(
        this._settings.minimumDisplayTime * 100,
        this._settings.minimumDisplayTime * 100 * 4
      );
      cb();
      this._isShapeShifting = false;
    }
  }

  show() {
    this._isHitTestVisible = true;
    const tween = new Tween(this.container).to({ alpha: 1 }, 50);
    tween.start();
  }

  trackMouseOver(point) {
    const body = document.getElementsByTagName('body')[0];

    if (body) {
      body.style.cursor = 'default';
    }

    if (!this._mouseOver && this.isMouseOver(point)) {
      this._mouseOver = true;
      this._trackStart = moment.utc();
      this._trackTimeTotal = this._trackTime;

      if (body) {
        body.style.cursor = 'pointer';
      }
    } else if (this._mouseOver && this.isMouseOver(point)) {
      this._trackTime = this._trackTimeTotal + moment.utc().diff(this._trackStart);

      if (body) {
        body.style.cursor = 'pointer';
      }
    } else if (this._mouseOver && !this.isMouseOver(point)) {
      this._mouseOver = false;
    }
  }

  update(e) {
    const { elapsedTime } = this;

    const sprite = this.container.children?.length ? this.container.getChildAt(0) : null;

    if (this._goalType === GoalType.EYE_SITUPS && this.isTarget) {
      if (this._startDelay <= elapsedTime && elapsedTime && this._startDelay + 250) {
        if (sprite) {
          sprite.alpha = 1;
        }
      } else if (sprite) {
        sprite.alpha = 0;
      }
    } else if (!this.visible && elapsedTime > this._startDelay && !this._hidden) {
      this.setVisible(true);
    } else if (
      this.visible &&
      this._startDelay > 0 &&
      this._minDisplayTime > 0 &&
      elapsedTime > this._startDelay + this._minDisplayTime
    ) {
      this.setVisible(false);
    }

    if (!this.visible) {
      return;
    }

    const { x, y } = this.container;

    let newX = x;
    let newY = y;

    const speedX = getFrameChange(this._speedX, e.delta);

    if (!sprite) {
      return;
    }

    let { width, height } = this.size;

    if (sprite instanceof PIXI.Sprite) {
      width = sprite.width * sprite.scale.x || this._size;
      height = sprite.height * sprite.scale.y || this._size;
    }

    const marginX = 0;
    const offsetX = getFrameChange(this._speedX, e.delta);
    const offsetY = getFrameChange(this._speedY, e.delta);

    newX += offsetX;
    newY += offsetY;

    switch (this.movement) {
      case ObjectMovement.CIRCULAR: {
        this.angle += speedX / 100;
        newX = this.radius * Math.cos(this.angle) + this.originX;
        newY = this.radius * Math.sin(this.angle) + this.originY;
        break;
      }
      case ObjectMovement.PULSE: {
        if (elapsedTime > this.pulseDelay) {
          this.pulseCount += this.pulseDirection;

          if (this.pulseCount < 0 || this.pulseCount > 30) {
            this.pulseDirection *= -1;
          }

          const scaleOffset = this.pulseCount * this.pulseStep;
          const pulseScaleX = (width - scaleOffset) / width;
          const pulseScaleY = (height - scaleOffset) / height;
          this.container.scale.set(pulseScaleX, pulseScaleY);
          newX = this.position.x + scaleOffset / 2;
          newY = this.position.y + scaleOffset / 2;
        } else {
          newX = this.position.x;
          newY = this.position.y;
        }

        break;
      }
      case ObjectMovement.BOUNCE:
      case ObjectMovement.RANDOM_BOUNCE: {
        // right hand border
        if (newX + width / 2 >= this._drillArea.width + marginX * -1) {
          this._speedX *= -1;
          newX = this._drillArea.width - width / 2 + marginX * -1;
        }

        // left
        if (newX <= width / 2) {
          this._speedX *= -1;
          newX = width / 2;
        }

        // bottom
        if (newY + height / 2 > this._drillArea.height) {
          this._speedY *= -1;
          newY = this._drillArea.height - height / 2;
        }

        // top
        if (newY <= height / 2) {
          this._speedY *= -1;
          newY = height / 2;
        }

        break;
      }
      case ObjectMovement.MOVE_LEFT:
        if (newX < (width + marginX) * -1) {
          newX = this._drillArea.width + marginX * -1;
        }

        break;
      case ObjectMovement.MOVE_RIGHT:
        if (newX > this._drillArea.width + marginX * -1) {
          newX = (width + marginX) * -1;
        }

        break;
      case ObjectMovement.MOVE_UP:
        if (newY < height * -1) {
          newY = this._drillArea.height;
        }

        break;
      case ObjectMovement.MOVE_DOWN:
        if (newY > this._drillArea.height) {
          newY = height * -1;
        }

        break;
      case ObjectMovement.HORIZONTAL:
        switch (this._selectedMovement) {
          case ObjectMovement.MOVE_LEFT:
            if (newX < (width + marginX) * -1) {
              newX = this._drillArea.width + marginX * -1;
            }

            break;
          case ObjectMovement.MOVE_RIGHT:
          default:
            if (newX > this._drillArea.width + marginX * -1) {
              newX = (width + marginX) * -1;
            }

            break;
        }

        break;
      case ObjectMovement.VERTICAL:
        switch (this._selectedMovement) {
          case ObjectMovement.MOVE_UP:
            if (newY < height * -1) {
              newY = this._drillArea.height;
            }

            break;
          case ObjectMovement.MOVE_DOWN:
          default:
            if (newY > this._drillArea.height) {
              newY = height * -1;
            }

            break;
        }

        break;
      default:
        break;
    }

    if (this._flipHorizontal) {
      if ((speedX < 0 && this._speedX >= 0) || (this._speedX < 0 && speedX >= 0)) {
        sprite.scale.set(sprite.scale.x * -1, sprite.scale.y);
      }
    }

    if (this._goalType === GoalType.StopCentre) {
      if (!this._startTime && newX <= this._drillArea.width / 2 && this._drillArea.width / 2 <= newX + width) {
        this._startTime = moment.utc();
      } else if (this._startTime && (newX + width < this._drillArea.width / 2 || this._drillArea.width / 2 < newX)) {
        this._startTime = null;
      }
    }

    this.container.position.set(newX, newY);
  }
}
