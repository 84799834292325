import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '../routes';

export function Logo(props) {
  const { REACT_APP_ASSET_PATH } = process.env;
  const { className, imageName } = props;

  const imgSrc = `${REACT_APP_ASSET_PATH}assets/images/${imageName}`;

  return (
    <div className={className}>
      <Link to={routes.default} component={RouterLink} underline="hover">
        <img alt="Logo" src={imgSrc} />
      </Link>
    </div>
  );
}

Logo.propTypes = {
  className: PropTypes.any,
  imageName: PropTypes.string,
};

Logo.defaultProps = {
  className: undefined,
  imageName: 'training-logo.png',
};
