/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { addEvent, getArrowUri, noop, removeEvent, useWindowDimensions } from '../utils';

import { ClickSink } from './ClickSink';

const useStyles = makeStyles({
  overlay: {
    '& table': {
      '& td': {
        '& img': {
          height: '80px',
          padding: '10px',
          width: '80px',
        },
        height: '75px',

        width: '75px',
      },
      bottom: '90px',
      left: '50%',
      marginLeft: '-120px',
      opacity: '0.6',

      position: 'absolute',
    },
    backgroundColor: 'transparent',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',

    zIndex: 2000,
  },
});

export function TouchOverlay(props) {
  const { show, showArrows, arrows, disableTouchMove, onTap } = props;
  const { width } = useWindowDimensions();
  const classes = useStyles();

  const preventTouchMove = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (disableTouchMove) {
      addEvent(document.body, 'touchmove', preventTouchMove);
    } else {
      removeEvent(document.body, 'touchmove', preventTouchMove);
    }
  }, [disableTouchMove]);

  const onArrowTap = debounce(
    (event, direction) => {
      if (event.eventId) {
        onTap({ ...event, key: direction });
      }
    },
    500,
    { leading: true }
  );

  const onScreenTap = debounce(
    (event) => {
      if (showArrows) {
        return;
      }

      if (!show) {
        return;
      }

      const key = event.screenX <= width / 2 ? 'left' : 'right';

      if (event.eventId) {
        onTap({ ...event, key });
      }
    },
    500,
    { leading: true }
  );

  return (
    <>
      {show && (
        <ClickSink
          clickOnTouchStart
          rootElement={document.body}
          className={classes.overlay}
          id="overlay-root"
          onClick={onScreenTap}
        >
          {showArrows && (
            <table>
              <tbody>
                <tr>
                  <td />
                  <td>
                    <ClickSink
                      clickOnTouchStart
                      rootElement={document.body}
                      id="up-arrow"
                      onClick={(e) => onArrowTap(e, 'up')}
                    >
                      <img alt="up" src={getArrowUri('Up', arrows.up)} />
                    </ClickSink>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>
                    <ClickSink
                      clickOnTouchStart
                      rootElement={document.body}
                      id="left-arrow"
                      onClick={(e) => onArrowTap(e, 'left')}
                    >
                      <img alt="left" src={getArrowUri('Left', arrows.left)} />
                    </ClickSink>
                  </td>
                  <td>
                    <ClickSink
                      clickOnTouchStart
                      rootElement={document.body}
                      id="down-arrow"
                      onClick={(e) => onArrowTap(e, 'down')}
                    >
                      <img alt="down" src={getArrowUri('Down', arrows.down)} />
                    </ClickSink>
                  </td>
                  <td>
                    <ClickSink
                      clickOnTouchStart
                      rootElement={document.body}
                      id="right-arrow"
                      onClick={(e) => onArrowTap(e, 'right')}
                    >
                      <img alt="right" src={getArrowUri('Right', arrows.right)} />
                    </ClickSink>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </ClickSink>
      )}
    </>
  );
}

TouchOverlay.propTypes = {
  arrows: PropTypes.shape({
    down: PropTypes.any,
    left: PropTypes.any,
    right: PropTypes.any,
    up: PropTypes.any,
  }),
  disableTouchMove: PropTypes.bool,
  onTap: PropTypes.func,
  show: PropTypes.bool,
  showArrows: PropTypes.bool,
};

TouchOverlay.defaultProps = {
  arrows: undefined,
  disableTouchMove: false,
  onTap: noop,
  show: false,
  showArrows: false,
};
