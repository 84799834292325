/* eslint-disable no-console */
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { plainToClass } from 'class-transformer';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import kebabCase from 'lodash/kebabCase';
import { reverse } from 'named-urls';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import seedrandom from 'seedrandom';

import { TrainingFrequency, User } from '../models';
import { EyeGymContext } from '../providers';
import { routes } from '../routes';
import { useWindowDimensions } from '../utils';

import { TimeExhaustedModal } from './Modals';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

const defaultOptions = {
  backgroundColor: 'transparent',
  bar: {
    groupWidth: 30,
  },
  chartArea: {
    height: 0,
    left: 240,
    top: 0,
  },
  hAxis: {
    baseLineColor: '#aaa',
    gridlines: {
      color: '#888',
    },
    textStyle: {
      color: '#fff',
      fontSize: 14,
    },
    ticks: [],
    title: 'Level',
    titleTextStyle: {
      color: '#fff',
      fontSize: 14,
    },
  },
  height: 0,
  isStacked: true,
  legend: 'none',
  tooltip: {
    isHtml: true,
  },
  vAxis: {
    textStyle: {
      color: '#fff',
      fontSize: 14,
    },
  },
};

export function UserDrillProgress(props) {
  const { xs, md } = props;
  const { levelUpdateCount, levels } = useSelector((state) => state.eyegym);
  const [chartData, setChartData] = useState(null);
  const [key, setKey] = useState();
  const [chartOptions, setChartOptions] = useState(defaultOptions);
  const [timeExhaustedOpen, setTimeExhaustedOpen] = useState(false);
  const { user, scores, product, drills, trainingFrequency, timeRemaining } = useContext(EyeGymContext);
  const classes = useStyles(props);
  const { height } = useWindowDimensions();
  const navigate = useNavigate();

  const trainingTimeExhaustedMessage = useMemo(() => {
    switch (trainingFrequency) {
      case TrainingFrequency.PER_WEEK:
        return 'You have finished your training for this week. Please come back next week to continue training.';
      case TrainingFrequency.PER_MONTH:
        return 'You have finished your training for this month. Please come back next month to continue training.';
      default:
        return 'You have finished your training for today. Please come back tomorrow to continue training.';
    }
  }, [trainingFrequency]);

  const createHtmlTooltip = (
    name,
    attempts
  ) => `<div style="padding:5px;margin-bottom:0;border:0;background-color:rgba(0,0,0,0.75)">
    <table>
    <tr>
    <td style="white-space:nowrap;"><strong>${name}</strong></td>
    <td style="white-space:nowrap;"> - ${attempts} attempt${attempts === 1 ? '' : 's'}</td>
    </tr>
    </table></div>`;

  useEffect(() => {
    seedrandom(levelUpdateCount);
    const userObj = plainToClass(User, user);

    if (!(userObj && scores && drills.length && levels?.length && product?.productDrills?.length)) {
      return;
    }

    setKey((prevKey) => !prevKey);

    const groupedScores = groupBy(scores, (s) => s.drillId);

    const data = drills.map((d) => {
      const drillScores = groupedScores[d.id] || [];
      const level = levels.find((l) => l.drillId === d.id);
      const drill = product.productDrills.find((pd) => pd.drillId === d.id);

      return {
        attempts: drillScores.length,
        id: d.id,
        level: level?.level ?? 0,
        name: d.name,
        order: drill?.order ?? 1,
      };
    });

    data.sort((a, b) => a.order - b.order);

    const chart = [
      [
        { id: 'drill', label: 'Drill', type: 'string' },
        {
          id: 'level',
          label: 'Level',
          type: 'number',
        },
        {
          role: 'style',
          type: 'string',
        },
        {
          p: {
            html: true,
          },
          role: 'tooltip',
          type: 'string',
        },
        { type: 'string' },
      ],
    ];

    const maximumLevel = userObj.getMaximumLevel();

    const ticks = [];

    for (let i = 0, l = maximumLevel; i <= l; i += 2) {
      ticks.push({ f: i, v: i });
    }

    for (let i = 0, l = data.length; i < l; i += 1) {
      let color = '#00518c';
      const item = data[i];

      if (item.level >= maximumLevel * (2 / 3) && item.level < maximumLevel) {
        color = '#74ce00';
      } else if (item.level >= 1 && item.level <= maximumLevel * (2 / 3)) {
        color = '#ff8400';
      }

      chart.push([
        item.name,
        item.level,
        `color:${color}`,
        createHtmlTooltip(item.name, item.attempts),
        reverse(`${routes.drill}`, {
          drillId: item.id,
          drillName: kebabCase(item.name),
          level: item.level,
        }),
      ]);
    }

    const availableHeight = height - 238;
    const chartAreaHeight = availableHeight - 90;

    chartOptions.hAxis.ticks = ticks;
    chartOptions.chartArea.height = chartAreaHeight;
    chartOptions.height = availableHeight;

    setChartData(chart);
    setChartOptions(chartOptions);
  }, [chartOptions, scores, drills, user, levels, product, height, levelUpdateCount]);

  const renderChart = () => (
    <Chart
      key={key}
      chartType="BarChart"
      data={chartData}
      options={chartOptions}
      width="100%"
      chartWrapperParams={{
        view: {
          columns: [0, 1, 2, 3],
        },
      }}
      chartEvents={[
        {
          callback: ({ chartWrapper }) => {
            const chart = chartWrapper.getChart();
            const selection = chart.getSelection();

            if (selection.length === 0) {
              return;
            }

            if (timeRemaining <= 0) {
              setTimeExhaustedOpen(true);

              return;
            }

            const drill = chartData[selection[0].row + 1];

            // dispatch(replace(drill[4]));
            navigate(drill[4]);
          },
          eventName: 'select',
        },
      ]}
    />
  );

  const handleTimeExhaustedClose = () => {
    setTimeExhaustedOpen(false);
  };

  return (
    <Grid item xs={xs} md={md}>
      <Box className={clsx(classes.column, classes.card)}>
        <Box sx={{ p: 1 }} className={clsx(classes.header, classes.row, classes.wrap)}>
          <Box className={clsx(classes.headline, classes.stretched)}>
            {`Your progress so far in the ${product.name} program`}
          </Box>
        </Box>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap)}>
          {chartData && renderChart()}
        </Box>
        <TimeExhaustedModal
          keepMounted
          message={trainingTimeExhaustedMessage}
          open={timeExhaustedOpen}
          onClose={handleTimeExhaustedClose}
        />
      </Box>
    </Grid>
  );
}

UserDrillProgress.propTypes = {
  md: PropTypes.number,
  xs: PropTypes.number,
};

UserDrillProgress.defaultProps = {
  md: 6,
  xs: 12,
};
