export const ACCEPT_DISCLAIMER = 'ACCEPT_DISCLAIMER';
export const ACCEPT_DISCLAIMER_COMMIT = 'ACCEPT_DISCLAIMER_COMMIT';
export const ACCEPT_DISCLAIMER_ROLLBACK = 'ACCEPT_DISCLAIMER_ROLLBACK';
export const CLEAR_DRILLS = 'CLEAR_DRILLS';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const CLEAR_STORE = 'CLEAR_STORE';
export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_USER_SCORES = 'CLEAR_USER_SCORES';
export const COMPLETE_USER_ASSESSMENT = 'COMPLETE_USER_ASSESSMENT';
export const COMPLETE_USER_ASSESSMENT_COMMIT = 'COMPLETE_USER_ASSESSMENT_COMMIT';
export const COMPLETE_USER_ASSESSMENT_ROLLBACK = 'COMPLETE_USER_ASSESSMENT_ROLLBACK';
export const FETCH_ARROW_SET_BEGIN = 'FETCH_ARROW_SET_BEGIN';
export const FETCH_ARROW_SET_FAILURE = 'FETCH_ARROW_SET_FAILURE';
export const FETCH_ARROW_SET_SUCCESS = 'FETCH_ARROW_SET_SUCCESS';
export const FETCH_BACKGROUNDS_BEGIN = 'FETCH_BACKGROUNDS_BEGIN';
export const FETCH_BACKGROUNDS_FAILURE = 'FETCH_BACKGROUNDS_FAILURE';
export const FETCH_BACKGROUNDS_SUCCESS = 'FETCH_BACKGROUNDS_SUCCESS';
export const FETCH_DRILL_BEGIN = 'FETCH_DRILL_BEGIN';
export const FETCH_DRILL_FAILURE = 'FETCH_DRILL_FAILURE';
export const FETCH_DRILL_SUCCESS = 'FETCH_DRILL_SUCCESS';
export const FETCH_DRILLS_BEGIN = 'FETCH_DRILLS_BEGIN';
export const FETCH_DRILLS_FAILURE = 'FETCH_DRILLS_FAILURE';
export const FETCH_DRILLS_SUCCESS = 'FETCH_DRILLS_SUCCESS';
export const FETCH_EXPLAINER_IMAGE_BEGIN = 'FETCH_EXPLAINER_IMAGE_BEGIN';
export const FETCH_EXPLAINER_IMAGE_FAILURE = 'FETCH_EXPLAINER_IMAGE_FAILURE';
export const FETCH_EXPLAINER_IMAGE_SUCCESS = 'FETCH_EXPLAINER_IMAGE_SUCCESS';
export const FETCH_ICON_SET_BEGIN = 'FETCH_ICON_SET_BEGIN';
export const FETCH_ICON_SET_FAILURE = 'FETCH_ICON_SET_FAILURE';
export const FETCH_ICON_SET_SUCCESS = 'FETCH_ICON_SET_SUCCESS';
export const FETCH_PRODUCT_BEGIN = 'FETCH_PRODUCT_BEGIN';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_SCORES_BEGIN = 'FETCH_USER_SCORES_BEGIN';
export const FETCH_USER_SCORES_FAILURE = 'FETCH_USER_SCORES_FAILURE';
export const FETCH_USER_SCORES_SUCCESS = 'FETCH_USER_SCORES_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const INCREASE_USER_LEVEL = 'INCREASE_USER_LEVEL';
export const INCREASE_USER_LEVEL_COMMIT = 'INCREASE_USER_LEVEL_COMMIT';
export const INCREASE_USER_LEVEL_ROLLBACK = 'INCREASE_USER_LEVEL_ROLLBACK';
export const POST_SCORE = 'POST_SCORE';
export const POST_SCORE_COMMIT = 'POST_SCORE_COMMIT';
export const POST_SCORE_ROLLBACK = 'POST_SCORE_ROLLBACK';
export const RESET_STATE = 'RESET_STATE';
export const SET_ASSESSMENT_DRILL_NUMBER = 'SET_ASSESSNENT_DRILL_NUMBER';
export const SET_CURRENT_ASSESSMENT = 'SET_CURRENT_ASSESSMENT';
export const SET_MAXIMUM_LEVEL_REACHED_MODAL_SHOWN = 'SET_MAXIMUM_LEVEL_REACHED_MODAL_SHOWN';
export const SET_POST_LOGOUT_URL = 'SET_POST_LOGOUT_URL';
