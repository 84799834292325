/* eslint-disable class-methods-use-this */
import CryptoJs from 'crypto-js';

const createMd5Hash = (value) => CryptoJs.MD5(value).toString();

export class PasswordHasher {
  hashPassword(password) {
    return createMd5Hash(password);
  }

  verifyHashedPassword(hashedPassword, providedPassword) {
    const encodedPassword = createMd5Hash(providedPassword);

    return hashedPassword.toLowerCase() === encodedPassword.toLowerCase();
  }
}
