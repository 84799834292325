import moment from 'moment';

export const TrainingFrequency = {
  PER_DAY: 'PerDay',
  PER_MONTH: 'PerMonth',
  PER_WEEK: 'PerWeek',
};

export class User {
  getExpiryDate(product) {
    if (this.forceTrainingOverrides && this.expiryDate) {
      return this.expiryDate;
    }

    if (!this.team && this.expiryDate) {
      return this.expiryDate;
    }

    if (!this.team) {
      return moment.utc().add(1, 'days');
    }

    if (!this.team.productTrainingSettingsOverrideTeam) {
      return this.team.paidUntil;
    }

    const minDate = moment(new Date(1970, 1, 1));

    return moment(product.expiryDate).isAfter(minDate) ? product.expiryDate : this.expiryDate;
  }

  getMaximumLevel() {
    if (this.forceTrainingOverrides && this.maximumLevel) {
      return this.maximumLevel;
    }

    if (this.team) {
      return this.team.maximumLevel;
    }

    return 1;
  }

  get isTeamLeader() {
    return this.team?.teamLeaderId === this.id;
  }
}
