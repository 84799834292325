import { Graphics } from '@inlet/react-pixi';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { RgbColour } from '../../../models';

export function PlainBackground(props) {
  const { colour, height, width } = props;

  const draw = useCallback(
    (g) => {
      g.clear();
      g.beginFill(colour);
      g.drawRect(0, 0, width, height);
      g.endFill();
    },
    [colour, width, height]
  );

  return <Graphics draw={draw} />;
}

PlainBackground.propTypes = {
  colour: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

PlainBackground.defaultProps = {
  colour: RgbColour.random().getWebColour('0x'),
};
