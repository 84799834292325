import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  button: {
    border: `1px solid ${theme.palette.primary.main} !important`,
    borderRadius: '10em !important',
    padding: '0 !important',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

export function BackButton() {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <IconButton color="primary" className={classes.button} size="large" onClick={handleClick}>
      <ArrowBackIcon fontSize="large" className={classes.icon} />
    </IconButton>
  );
}
