/* eslint-disable no-console */
import { Stage } from '@inlet/react-pixi';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Provider as ReactReduxProvider, ReactReduxContext } from 'react-redux';

import { Events } from '../../models';
import { DrillContext, DrillProvider, EyeGymContext, EyeGymProvider } from '../../providers';
import { noop } from '../../utils';

const useStyles = makeStyles({
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'block',
  },
});

export function Canvas(props) {
  const { width, height, children, onMount, show } = props;
  const [app, setApp] = useState();
  const [events, setEvents] = useState();
  const classes = useStyles();

  const handleStageMount = (a) => {
    setApp(a);
    const e = new Events(a.renderer.view, document.body);
    setEvents(e);
    onMount({ app: a, events: e });
  };

  useEffect(
    () => () => {
      try {
        if (app?.stage) {
          app.destroy();
        }
      } catch {
        // do nothing
      }

      try {
        if (events) {
          events.destroy();
        }
      } catch {
        // do nothing
      }
    },
    [app, events]
  );

  return (
    <div className={show ? classes.visible : classes.hidden}>
      <ReactReduxContext.Consumer>
        {({ store, ...reduxProps }) => (
          <EyeGymContext.Consumer>
            {({ ...eyegymProps }) => (
              <DrillContext.Consumer>
                {({ drillId, level, ...drillProps }) => (
                  <Stage
                    width={width}
                    height={height}
                    options={{ autoDensity: true, autoStart: false, sharedTicker: true }}
                    onMount={handleStageMount}
                  >
                    <ReactReduxProvider store={store} props={reduxProps}>
                      <EyeGymProvider nested props={eyegymProps}>
                        <DrillProvider nested props={drillProps} drillId={drillId} level={level}>
                          {children}
                        </DrillProvider>
                      </EyeGymProvider>
                    </ReactReduxProvider>
                  </Stage>
                )}
              </DrillContext.Consumer>
            )}
          </EyeGymContext.Consumer>
        )}
      </ReactReduxContext.Consumer>
    </div>
  );
}

Canvas.propTypes = {
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onMount: PropTypes.func,
  show: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

Canvas.defaultProps = {
  children: undefined,
  onMount: noop,
  show: true,
};
