import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  html: {
    whiteSpace: 'pre-wrap',
  },
});

export function SimpleTask(props) {
  const { variant, component } = props;
  const { task } = useSelector((state) => state.drill);
  const classes = useStyles();

  return (
    <Typography variant={variant} component={component} className={classes.html}>
      {task}
    </Typography>
  );
}

SimpleTask.propTypes = {
  component: PropTypes.any,
  variant: PropTypes.any,
};

SimpleTask.defaultProps = {
  component: 'div',
  variant: 'body1',
};
