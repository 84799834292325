/* eslint-disable no-console */
import assignIn from 'lodash/assignIn';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Guid } from '../models';
import { addEvent, noop, removeEvent } from '../utils';

const TAP_DURATION = 750; // Shorter than 750ms is a tap, longer is a taphold or drag.
const MOVE_TOLERANCE = 12; // 12px seems to work in most mobile browsers.
const PREVENT_DURATION = 2500; // 2.5 seconds maximum from preventGhostClick call to click
const CLICKBUSTER_THRESHOLD = 25; // 25 pixels in any dimension is the limit for busting clicks.

const hit = (x1, y1, x2, y2) => Math.abs(x1 - x2) < CLICKBUSTER_THRESHOLD && Math.abs(y1 - y2) < CLICKBUSTER_THRESHOLD;

const checkAllowableRegions = (touchCoordinates, x, y) => {
  for (let i = 0; i < touchCoordinates.length; i += 2) {
    if (hit(touchCoordinates[i], touchCoordinates[i + 1], x, y)) {
      touchCoordinates.splice(i, i + 2);

      return true; // allowable region
    }
  }

  return false; // No allowable region; bust it.
};

export function ClickSink({ children, ...props }) {
  const { id, onClick, clickOnTouchStart, rootElement, disabled, className } = props;
  const [tapping, setTapping] = useState(false);
  const element = useRef(null);
  const lastPreventedTime = useRef();
  const startTime = useRef();
  const tapElement = useRef(null);
  const touchCoordinates = useRef();
  const touchStartX = useRef();
  const touchStartY = useRef();
  const handled = useRef(false);

  const ticker = PIXI.Ticker.shared;

  const resetState = () => {
    setTapping(false);
  };

  const onGhostClick = (event) => {
    if (Date.now() - lastPreventedTime.current > PREVENT_DURATION) {
      return; // Too old.
    }

    const touches = event.touches && event.touches.length ? event.touches : [event];
    const x = touches[0].clientX;
    const y = touches[0].clientY;

    if (x < 1 && y < 1) {
      return; // offscreen
    }

    if (checkAllowableRegions(touchCoordinates.current, x, y)) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    event.target?.blur();
  };

  const onGhostTouchStart = (event) => {
    const touches = event.touches && event.touches.length ? event.touches : [event];
    const x = touches[0].clientX;
    const y = touches[0].clientY;

    touchCoordinates.current.push(x, y);

    setTimeout(() => {
      // Remove the allowable region.
      for (let i = 0; i < touchCoordinates.current.length; i += 2) {
        if (touchCoordinates.current[i] === x && touchCoordinates.current[i + 1] === y) {
          touchCoordinates.current.splice(i, i + 2);

          return;
        }
      }
    }, PREVENT_DURATION);
  };

  const preventGhostClick = useCallback(
    (x, y) => {
      if (!touchCoordinates.current) {
        addEvent(rootElement, 'click', onGhostClick, true);
        addEvent(rootElement, 'touchstart', onGhostTouchStart, true);
        touchCoordinates.current = [];
      }

      lastPreventedTime.current = Date.now();

      checkAllowableRegions(touchCoordinates.current, x, y);
    },
    [rootElement]
  );

  const onTouchEnd = useCallback(
    (event) => {
      const diff = Date.now() - startTime.current;

      let touches;

      if (event.changedTouches && event.changedTouches.length) {
        touches = event.changedTouches;
      } else if (event.touches && event.touches.length) {
        touches = event.touches;
      } else {
        touches = [event];
      }

      const e = touches[0].originalEvent || touches[0];
      const x = e.clientX;
      const y = e.clientY;
      const dist = Math.sqrt(x - touchStartX.current ** 2 + (y - touchStartY.current ** 2));

      if (!clickOnTouchStart && tapping && diff < TAP_DURATION && dist < MOVE_TOLERANCE) {
        preventGhostClick(x, y);

        if (tapElement.current) {
          tapElement.current.blur();
        }

        if (!disabled) {
          element.current.dispatchEvent(new MouseEvent('click', e));
        }
      }

      resetState();

      removeEvent(element.current, 'touchend', onTouchEnd);
    },
    [clickOnTouchStart, disabled, preventGhostClick, tapping]
  );

  const onTouchStart = useCallback(
    (event) => {
      const touches = event.touches && event.touches.length ? event.touches : [event];
      const e = touches[0].originalEvent || touches[0];

      event.stopPropagation();

      if (clickOnTouchStart) {
        if (handled.current) {
          return;
        }

        setTimeout(() => {
          handled.current = false;
        }, TAP_DURATION);

        removeEvent(element.current, 'touchend', onTouchEnd);

        if (!e.screenX && e.pageX) {
          e.screenX = e.pageX;
        }

        if (!e.screenY && e.pageY) {
          e.screenY = e.pageY;
        }

        element.current.dispatchEvent(new MouseEvent('click', { ...e, ...event }));
        resetState();

        return;
      }

      setTapping(true);
      tapElement.current = event.target ? event.target : event.srcElement; // IE uses srcElement.

      // Hack for Safari, which can target text nodes instead of containers.
      if (tapElement.current.nodeType === 3) {
        tapElement.current = tapElement.parentNode;
      }

      startTime.current = Date.now();

      touchStartX.current = e.clientX;
      touchStartY.current = e.clientY;

      addEvent(element.current, 'touchend', onTouchEnd);
    },
    [clickOnTouchStart, onTouchEnd]
  );

  const onElementClick = useCallback(
    (event, touchend) => {
      if (!ticker.started) {
        return;
      }

      event.stopPropagation();

      const e = touchend || event;

      if (!e.view) {
        return;
      }

      const clonedEvent = assignIn({}, e);

      onClick({
        ...clonedEvent,
        eventId: Guid.newGuid().toString(),
      });
    },
    [onClick, ticker.started]
  );

  useEffect(() => {
    const el = element.current;

    addEvent(el, 'touchstart', onTouchStart);
    addEvent(el, 'touchmove', resetState);
    addEvent(el, 'touchcancel', resetState);
    el.onclick = noop;
    addEvent(el, 'click', onElementClick);

    return () => {
      removeEvent(el, 'touchstart', onTouchStart);
      removeEvent(el, 'touchmove', resetState);
      removeEvent(el, 'touchcancel', resetState);
      removeEvent(el, 'click', onElementClick);
    };
  }, [onElementClick, onTouchStart]);

  return (
    <div ref={element} id={id} className={className}>
      {children}
    </div>
  );
}

ClickSink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  clickOnTouchStart: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  rootElement: PropTypes.any.isRequired,
};

ClickSink.defaultProps = {
  children: undefined,
  className: undefined,
  clickOnTouchStart: false,
  disabled: false,
  id: undefined,
  onClick: noop,
};
