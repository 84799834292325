/* eslint-disable react/jsx-no-useless-fragment */

import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isUndefined } from 'lodash';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { UserDrillProgress, UserInfo } from '../components';
import { DrillStage } from '../models';
import { EyeGymContext } from '../providers';
import { actionCreators as drillActionCreators } from '../redux/drill';
import { actionCreators as eyegymActionCreators } from '../redux/eyegym';
import { routes } from '../routes';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
}));

export function Default() {
  const location = useLocation();
  const { search } = location;

  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();
  const { assessment, user } = useContext(EyeGymContext);

  useEffect(() => {
    if (document) {
      document.title = 'EyeGym | Home';
    }
  }, []);

  useEffect(() => {
    dispatch(drillActionCreators.setDrillStage(DrillStage.START));
    dispatch(eyegymActionCreators.setPostLogoutUrl(null));
  }, [dispatch]);

  useEffect(() => {
    const { acceptedDisclaimer } = queryString.parse(search);

    if (!user || isUndefined(user?.acceptedDisclaimer)) {
      return;
    }

    if (user && !user.acceptedDisclaimer && !acceptedDisclaimer) {
      navigate(routes.disclaimer);
    } else if (assessment && !assessment.isCompleted) {
      navigate(routes.assessment);
    } else {
      setLoaded(true);
    }
  }, [assessment, navigate, user, search]);

  const normalView = () => (
    <Grid container spacing={2}>
      <UserInfo xs={12} md={4} />
      <UserDrillProgress xs={12} md={8} />
    </Grid>
  );

  return (
    <>
      {loaded && (
        <Container maxWidth="xl" className={classes.container}>
          {normalView()}
        </Container>
      )}
    </>
  );
}
