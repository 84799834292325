import { createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'reflect-metadata';
import { AuthProvider, EyeGymProvider, LogProvider } from '../providers';
import { store } from '../redux/store';

import { Rehydrate } from './Rehydrate';
import { Root } from './Root';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

let theme = createTheme({
  breakpoints: {
    values: {
      lg: 1280,
      md: 960,
      sm: 600,
      xl: 1920,
      xs: 0,
    },
  },
  palette: {
    primary: {
      main: '#00C6DB',
    },
    secondary: {
      main: '#ff4f49',
    },
  },
  text: {
    primary: 'hsla(0,0%,100%,.45)',
  },
  typography: {
    body1: {
      fontSize: '1rem',
    },
    useNextVariants: true,
  },
});

theme = responsiveFontSizes(theme);

export function App() {
  return (
    <Provider store={store}>
      <Rehydrate>
        <BrowserRouter basename={baseUrl}>
          <LogProvider appName="eyegym">
            <AuthProvider store={store}>
              <StylesProvider injectFirst>
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <EyeGymProvider>
                      <Root />
                    </EyeGymProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              </StylesProvider>
            </AuthProvider>
          </LogProvider>
        </BrowserRouter>
      </Rehydrate>
    </Provider>
  );
}
