import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect } from 'react';

import { UsersGrid } from '../components';
import { EyeGymContext } from '../providers/EyeGymProvider';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
}));

export function Team() {
  useEffect(() => {
    if (document) {
      document.title = 'EyeGym | Team';
    }
  }, []);
  const { user } = useContext(EyeGymContext);
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UsersGrid teamId={user.teamId} />
    </Container>
  );
}
