/* eslint-disable no-unreachable */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import chunk from 'lodash/chunk';
import moment from 'moment';
import * as PIXI from 'pixi.js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LogContext } from '../../providers';
import { actionCreators } from '../../redux/offline';
import { asyncForEach, noop } from '../../utils';
import { LinearProgressWithLabel } from '../LinearProgressWithLabel';

export function SynchroniseUsersModal(props) {
  const { team, users, onClose, open, ...others } = props;
  const { synchronisedUserCount } = useSelector((state) => state.offlineUsers);
  const [synchronised, setSynchronised] = useState(false);
  const [synchronising, setSynchronising] = useState(false);
  const [startTime, setStartTime] = useState();
  const dispatch = useDispatch();
  const { log } = useContext(LogContext);

  useEffect(() => {
    if (open) {
      dispatch(actionCreators.setSynchronisedUserCount(0));
      setSynchronised(false);
      setSynchronising(false);
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (users?.length && synchronisedUserCount === users.length + 1) {
      const loader = PIXI.Loader.shared;
      loader.load();
    }
  }, [synchronisedUserCount, users?.length]);

  const handleOkayClick = async () => {
    setSynchronising(true);
    setStartTime(moment());
    dispatch(actionCreators.setSynchronisedUserCount(0));

    // first thing is to synchronise the team's icon set, arrow set, backgrounds & the defaults
    if (team.arrowSetId) {
      dispatch(actionCreators.synchroniseArrowSet(team.arrowSetId, { log }));
    }

    // setSynchronised(true);
    // setSynchronising(false);

    // return;

    if (team.iconSetId) {
      dispatch(actionCreators.synchroniseIconSet(team.iconSetId, { log }));
    }

    dispatch(actionCreators.synchroniseExplainerImages({ log }));

    dispatch(actionCreators.synchroniseBackgrounds(team.id, { log }));

    dispatch(actionCreators.synchroniseArrowSet(`${process.env.REACT_APP_DEFAULT_ARROW_SET_ID}`, { log }));

    dispatch(actionCreators.synchroniseIconSet(`${process.env.REACT_APP_DEFAULT_ICON_SET_ID}`, { log }));

    const chunks = chunk([team.teamLeaderId, ...users], 10);

    await asyncForEach(chunks, async (userIds) => {
      const actions = userIds.map(async (userId) => dispatch(actionCreators.synchroniseUser(userId, { log })));

      await Promise.all(actions);
    });

    setSynchronised(true);
    setSynchronising(false);
  };

  const timeRemainingMessage = useMemo(() => {
    if (synchronisedUserCount === 0 || !startTime) {
      return 'Calculating time remaining ...';
    }

    const elapsedTime = moment.duration(startTime.diff(moment())).asMilliseconds();
    const perUserTimeInMilliseconds = elapsedTime / synchronisedUserCount;
    const estimatedTotalTimeInMilliseconds = perUserTimeInMilliseconds * (users.length + 1);
    const estimatedTimeInMilliseconds = estimatedTotalTimeInMilliseconds - elapsedTime;

    const estimatedTime = moment.duration(estimatedTimeInMilliseconds).humanize();

    return `Elapsed time: ${moment.duration(elapsedTime).humanize()}; ${estimatedTime} remaining.`;
  }, [synchronisedUserCount, startTime, users?.length]);

  const progress = useMemo(() => (synchronisedUserCount / (users.length + 1)) * 100, [users, synchronisedUserCount]);

  const handleCloseClick = () => {
    setSynchronised(false);
    setSynchronising(true);
    onClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      aria-labelledby="synchronise-users-dialog-title"
      aria-describedby="synchronise-users-dialog-text"
      onBackdropClick={noop}
      {...others}
    >
      <DialogTitle id="synchronise-users-dialog-title">EyeGym</DialogTitle>
      <DialogContent>
        <DialogContentText id="synchronise-users-dialog-text">
          {`You have chosen to synchronise ${users.length} team members, this might take some time.`}
        </DialogContentText>
        <DialogContentText id="synchronise-users-dialog-text">
          When you click okay the synchronisation will begin. You will be able to close this dialog when synchronisation
          is complete.
        </DialogContentText>
        {(synchronising || synchronised) && <LinearProgressWithLabel value={progress} />}
        {synchronising && <DialogContentText>{timeRemainingMessage}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus disabled={synchronised || synchronising} onClick={handleOkayClick}>
          Okay
        </Button>
        <Button disabled={!synchronised} onClick={handleCloseClick}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SynchroniseUsersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
};
