import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { AuthConsumer } from '../../providers';
import { Loader } from '../Loader';

const useStyles = makeStyles({
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});

export function Callback() {
  const classes = useStyles();

  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();

        return (
          <div className={classes.loader}>
            <Loader />
          </div>
        );
      }}
    </AuthConsumer>
  );
}

export default Callback;
