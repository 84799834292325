/* eslint-disable no-console */
import { plainToClass } from 'class-transformer';
import is from 'is_js';

import { api } from '../../api';
import { Drill } from '../../models';
import { extractRootFilters, fetchData } from '../../utils';

import {
  ACCEPT_DISCLAIMER,
  ACCEPT_DISCLAIMER_COMMIT,
  ACCEPT_DISCLAIMER_ROLLBACK,
  CLEAR_STORE,
  COMPLETE_USER_ASSESSMENT,
  COMPLETE_USER_ASSESSMENT_COMMIT,
  COMPLETE_USER_ASSESSMENT_ROLLBACK,
  FETCH_ARROW_SET_BEGIN,
  FETCH_ARROW_SET_FAILURE,
  FETCH_ARROW_SET_SUCCESS,
  FETCH_BACKGROUNDS_BEGIN,
  FETCH_BACKGROUNDS_FAILURE,
  FETCH_BACKGROUNDS_SUCCESS,
  FETCH_DRILLS_BEGIN,
  FETCH_DRILLS_FAILURE,
  FETCH_DRILLS_SUCCESS,
  FETCH_DRILL_BEGIN,
  FETCH_DRILL_FAILURE,
  FETCH_DRILL_SUCCESS,
  FETCH_EXPLAINER_IMAGE_BEGIN,
  FETCH_EXPLAINER_IMAGE_FAILURE,
  FETCH_EXPLAINER_IMAGE_SUCCESS,
  FETCH_ICON_SET_BEGIN,
  FETCH_ICON_SET_FAILURE,
  FETCH_ICON_SET_SUCCESS,
  FETCH_PRODUCT_BEGIN,
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_SUCCESS,
  FETCH_USER_BEGIN,
  FETCH_USER_FAILURE,
  FETCH_USER_SCORES_BEGIN,
  FETCH_USER_SCORES_FAILURE,
  FETCH_USER_SCORES_SUCCESS,
  FETCH_USER_SUCCESS,
  INCREASE_USER_LEVEL,
  INCREASE_USER_LEVEL_COMMIT,
  INCREASE_USER_LEVEL_ROLLBACK,
  POST_SCORE,
  POST_SCORE_COMMIT,
  POST_SCORE_ROLLBACK,
  RESET_STATE,
  SET_ASSESSMENT_DRILL_NUMBER,
  SET_CURRENT_ASSESSMENT,
  SET_MAXIMUM_LEVEL_REACHED_MODAL_SHOWN,
  SET_POST_LOGOUT_URL,
} from './actionTypes';

const columnConfig = {
  name: {
    op: 'contains',
  },
};

const acceptDisclaimer = (userId) => ({
  meta: {
    offline: {
      commit: {
        meta: {
          userId,
        },
        type: ACCEPT_DISCLAIMER_COMMIT,
      },
      effect: {
        json: {
          userId,
        },
        method: 'POST',
        url: api.rest.users.acceptDisclaimer,
      },
      rollback: {
        meta: {
          userId,
        },
        type: ACCEPT_DISCLAIMER_ROLLBACK,
      },
    },
  },
  payload: {
    userId,
  },
  type: ACCEPT_DISCLAIMER,
});

const completeUserAssessment = (assessmentId, dateCompleted) => ({
  meta: {
    offline: {
      commit: {
        meta: {
          assessmentId,
          dateCompleted,
        },
        type: COMPLETE_USER_ASSESSMENT_COMMIT,
      },
      effect: {
        json: {
          assessmentId,
          dateCompleted,
        },
        method: 'POST',
        url: api.rest.userAssessments.complete,
      },
      rollback: {
        meta: {
          assessmentId,
          dateCompleted,
        },
        type: COMPLETE_USER_ASSESSMENT_ROLLBACK,
      },
    },
  },
  payload: {
    assessmentId,
    dateCompleted,
  },
  type: COMPLETE_USER_ASSESSMENT,
});

const fetchArrowSetBegin = () => ({
  type: FETCH_ARROW_SET_BEGIN,
});

const fetchArrowSetFailure = (error) => ({
  payload: {
    error,
  },
  type: FETCH_ARROW_SET_FAILURE,
});

const fetchArrowSetSuccess = (arrowSet) => ({
  payload: {
    arrowSet,
  },
  type: FETCH_ARROW_SET_SUCCESS,
});

const fetchArrowSet = (arrowSetId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingArrowSet },
    offlineUsers: { arrowSets },
  } = getState();

  if (is.not.online()) {
    dispatch(fetchArrowSetBegin());
    const arrowSet = arrowSets[arrowSetId];

    if (!arrowSet) {
      return false;
    }

    dispatch(fetchArrowSetSuccess(arrowSet));

    return arrowSet;
  }

  const { log } = context;

  if (!(auth && log) || loadingArrowSet) {
    return false;
  }

  dispatch(fetchArrowSetBegin());

  try {
    const url = api.odata.arrowSet(arrowSetId);
    const response = await fetchData(url, auth.access_token);
    const arrowSet = response;
    dispatch(fetchArrowSetSuccess(arrowSet));

    return arrowSet;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchArrowSetFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchArrowSet' });
  }
};

const fetchBackgroundsBegin = () => ({
  type: FETCH_BACKGROUNDS_BEGIN,
});

const fetchBackgroundsFailure = (error) => ({
  payload: {
    error,
  },
  type: FETCH_BACKGROUNDS_FAILURE,
});

const fetchBackgroundsSuccess = (backgrounds) => ({
  payload: {
    backgrounds,
  },
  type: FETCH_BACKGROUNDS_SUCCESS,
});

const fetchBackgrounds = (teamId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingBackgrounds },
    offlineUsers: { backgrounds: offlineBackgrounds },
  } = getState();

  if (is.not.online()) {
    dispatch(fetchBackgroundsBegin());
    const backgrounds = offlineBackgrounds[teamId];

    if (!backgrounds) {
      return false;
    }

    dispatch(fetchBackgroundsSuccess(backgrounds));

    return backgrounds;
  }

  const { log } = context;

  if (!(auth && log) || loadingBackgrounds) {
    return false;
  }

  dispatch(fetchBackgroundsBegin());

  try {
    const url = api.rest.backgrounds(teamId);
    const response = await fetchData(url, auth.access_token);
    const backgrounds = response;
    dispatch(fetchBackgroundsSuccess(backgrounds));

    return backgrounds;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchBackgroundsFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchBackgrounds' });
  }
};

const fetchExplainerImageBegin = () => ({
  type: FETCH_EXPLAINER_IMAGE_BEGIN,
});

const fetchExplainerImageFailure = (error) => ({
  payload: {
    error,
  },
  type: FETCH_EXPLAINER_IMAGE_FAILURE,
});

const fetchExplainerImageSuccess = (image) => ({
  payload: { image },
  type: FETCH_EXPLAINER_IMAGE_SUCCESS,
});

const fetchExplainerImage = (imageId, log) => async (dispatch, getState) => {
  if (!imageId) {
    return false;
  }

  const {
    authentication: { user: auth },
    eyegym: { loadingExplainerImage },
    offlineUsers: { explainerImages },
  } = getState();

  if (is.not.online()) {
    dispatch(fetchExplainerImageBegin());
    const explainerImage = explainerImages[imageId];
    dispatch(fetchExplainerImageSuccess(explainerImage));

    return explainerImage;
  }

  if (!(auth && log) || loadingExplainerImage) {
    return false;
  }

  dispatch(fetchExplainerImageBegin());

  try {
    const url = api.odata.image(imageId);
    const response = await fetchData(url, auth.access_token);
    const explainerImage = response;
    dispatch(fetchExplainerImageSuccess(explainerImage));

    return explainerImage;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchExplainerImageFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchExplainerImage' });
  }
};

const fetchIconSetBegin = () => ({
  type: FETCH_ICON_SET_BEGIN,
});

const fetchIconSetFailure = (error) => ({
  payload: {
    error,
  },
  type: FETCH_ICON_SET_FAILURE,
});

const fetchIconSetSuccess = (iconSet) => ({
  payload: {
    iconSet,
  },
  type: FETCH_ICON_SET_SUCCESS,
});

const fetchIconSet = (iconSetId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingIconSet },
    offlineUsers: { iconSets },
  } = getState();

  if (is.not.online()) {
    dispatch(fetchIconSetBegin());
    const iconSet = iconSets[iconSetId];

    if (!iconSet) {
      return false;
    }

    dispatch(fetchIconSetSuccess(iconSet));

    return iconSet;
  }

  const { log } = context;

  if (!(auth && log) || loadingIconSet) {
    return false;
  }

  dispatch(fetchIconSetBegin());

  try {
    const url = api.odata.iconSet(iconSetId);
    const response = await fetchData(url, auth.access_token);
    const iconSet = response;
    dispatch(fetchIconSetSuccess(iconSet));

    return iconSet;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchIconSetFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchIconSet' });
  }
};

const fetchUserBegin = () => ({
  type: FETCH_USER_BEGIN,
});

const fetchUserFailure = (error, userId) => ({
  payload: { error, userId },
  type: FETCH_USER_FAILURE,
});

const fetchUserSuccess = (user) => ({
  payload: { user },
  type: FETCH_USER_SUCCESS,
});

const fetchProductBegin = () => ({
  type: FETCH_PRODUCT_BEGIN,
});

const fetchProductFailure = (error, productId) => ({
  payload: { error, productId },
  type: FETCH_PRODUCT_FAILURE,
});

const fetchProductSuccess = (product) => ({
  payload: { product },
  type: FETCH_PRODUCT_SUCCESS,
});

const fetchDrillBegin = () => ({
  type: FETCH_DRILL_BEGIN,
});

const fetchDrillFailure = (error, drillId) => ({
  payload: {
    drillId,
    error,
  },
  type: FETCH_DRILL_FAILURE,
});

const fetchDrillSuccess = (drill) => ({
  payload: { drill },
  type: FETCH_DRILL_SUCCESS,
});

const fetchDrillsBegin = () => ({
  type: FETCH_DRILLS_BEGIN,
});

const fetchDrillsFailure = (error) => ({
  payload: { error },
  type: FETCH_DRILLS_FAILURE,
});

const fetchDrillsSuccess = (drills) => ({
  payload: { drills },
  type: FETCH_DRILLS_SUCCESS,
});

const fetchUserScoresBegin = () => ({
  type: FETCH_USER_SCORES_BEGIN,
});

const fetchUserScoresFailure = (error) => ({
  payload: { error },
  type: FETCH_USER_SCORES_FAILURE,
});

const fetchUserScoresSuccess = (scores) => ({
  payload: { scores },
  type: FETCH_USER_SCORES_SUCCESS,
});

const increaseUserLevel = (userLevel) => ({
  meta: {
    offline: {
      commit: {
        meta: userLevel,
        type: INCREASE_USER_LEVEL_COMMIT,
      },
      effect: {
        json: userLevel,
        method: 'POST',
        url: api.rest.userlevels.increase,
      },
      rollback: {
        meta: userLevel,
        type: INCREASE_USER_LEVEL_ROLLBACK,
      },
    },
  },
  payload: userLevel,
  type: INCREASE_USER_LEVEL,
});

const postScore = (score) => ({
  meta: {
    offline: {
      commit: {
        meta: { score },
        type: POST_SCORE_COMMIT,
      },
      effect: {
        json: score,
        method: 'POST',
        url: api.rest.userscores.post,
      },
      rollback: {
        meta: { score },
        type: POST_SCORE_ROLLBACK,
      },
    },
  },
  payload: { score },
  type: POST_SCORE,
});

const clearStore = () => ({
  type: CLEAR_STORE,
});

const resetState = () => ({
  type: RESET_STATE,
});

const setAssessmentDrillNumber = (assessmentDrillNumber) => ({
  payload: {
    assessmentDrillNumber,
  },
  type: SET_ASSESSMENT_DRILL_NUMBER,
});

const setCurrentAssessment = (assessment) => ({
  payload: {
    assessment,
  },
  type: SET_CURRENT_ASSESSMENT,
});

const setMaximumLevelReachedModalShown = (drillId) => ({
  payload: {
    drillId,
  },
  type: SET_MAXIMUM_LEVEL_REACHED_MODAL_SHOWN,
});

const fetchUser = (userId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingUser },
    offlineUsers: { users, teams },
  } = getState();

  if (!userId) {
    return false;
  }

  if (is.not.online()) {
    dispatch(fetchUserBegin());
    const user = users[userId];
    user.team = teams[user.teamId];

    if (user) {
      dispatch(fetchUserSuccess(user));

      return user;
    }
  }

  const { log } = context;

  if (!(auth && log) || loadingUser) {
    return false;
  }

  const expansions = [
    'Team',
    'Sport',
    { Assessments: [{ Product: ['ProductDrills'] }] },
    { CurrentUserProduct: ['Levels'] },
  ];

  const url = api.odata.user(userId, expansions);

  try {
    const response = await fetchData(url, auth.access_token);
    const user = response;
    dispatch(fetchUserSuccess(user));

    return user;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchUserFailure(message, userId));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchUser' });
  }
};

const fetchProduct = (productId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingProduct },
    offlineUsers: { products: offlineProducts },
  } = getState();

  if (!productId) {
    return;
  }

  if (is.not.online()) {
    dispatch(fetchProductBegin());
    const product = offlineProducts[productId];

    if (product) {
      dispatch(fetchProductSuccess(product));

      return product;
    }
  }

  const { log } = context;

  if (!(auth && log) || loadingProduct) {
    return;
  }

  const expansions = [{ ProductDrills: ['Drill'] }];

  const url = api.odata.product(productId, expansions);

  dispatch(fetchProductBegin());

  try {
    const response = await fetchData(url, auth.access_token);
    const product = response;
    dispatch(fetchProductSuccess(product));

    return product;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchProductFailure(message, productId));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchProduct' });
  }
};

const fetchDrill = (drillId, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingDrill },
    offlineUsers: { drills: offlineDrills },
  } = getState();

  if (!drillId) {
    return false;
  }

  if (is.not.online()) {
    dispatch(fetchDrillBegin());
    const drill = offlineDrills[drillId];

    if (drill) {
      dispatch(fetchDrillSuccess(drill));

      return drill;
    }
  }

  const { log } = context;

  if (!(auth && log) || loadingDrill) {
    return false;
  }

  const expansions = ['details', 'settings'];

  const url = api.odata.drill(drillId, expansions);

  try {
    dispatch(fetchDrillBegin());
    const response = await fetchData(url, auth.access_token);
    const drill = response;
    dispatch(fetchDrillSuccess(drill));

    return drill;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchDrillFailure(message, drillId));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchDrill' });
  }
};

const fetchDrills = (filters, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingDrills },
    offlineUsers: { drills: offlineDrills },
  } = getState();

  if (is.not.online()) {
    dispatch(fetchDrillsBegin());
    const items = Object.values(offlineDrills);
    const ids = filters[0].value;
    const drills = items.filter((d) => ids.includes(d.id));
    dispatch(fetchDrillsSuccess(drills));

    return drills;
  }

  const { log } = context;

  if ((!auth && log) || loadingDrills) {
    return;
  }

  dispatch(fetchDrillsBegin());

  const url = api.odata.drills({
    expand: ['Details', 'Settings'],
    filter: extractRootFilters(filters, columnConfig),
  });

  try {
    const response = await fetchData(url, auth.access_token);
    const drills = plainToClass(Drill, response.value);
    dispatch(fetchDrillsSuccess(drills));

    return drills;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchDrillsFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchDrills' });
  }
};

const fetchUserScores = (filters, context) => async (dispatch, getState) => {
  const {
    authentication: { user: auth },
    eyegym: { loadingScores },
    offlineUsers: { userScores: offlineUserScores },
  } = getState();

  if (is.not.online()) {
    dispatch(fetchUserScoresBegin());
    const drillIds = filters[0].value;
    const userProductId = filters[1].value;
    const items = offlineUserScores[userProductId];
    const scores = items.filter((s) => drillIds.includes(s.drillId));

    dispatch(fetchUserScoresSuccess(scores));

    return scores;
  }

  const { log } = context;

  if (!(auth && log) || loadingScores) {
    return;
  }

  dispatch(fetchUserScoresBegin());

  const url = api.odata.userScores({
    filter: extractRootFilters(filters, columnConfig),
  });

  try {
    const response = await fetchData(url, auth.access_token);
    const scores = response.value;
    dispatch(fetchUserScoresSuccess(scores));

    return scores;
  } catch (error) {
    const message = error.message || error;
    dispatch(fetchUserScoresFailure(message));
    log.error('Error: {error} occurred in {source}', { error, source: 'redux.eyegym.fetchUserScores' });
  }
};

const setPostLogoutUrl = (url) => ({
  payload: {
    url,
  },
  type: SET_POST_LOGOUT_URL,
});

export const actionCreators = {
  acceptDisclaimer,
  clearStore,
  completeUserAssessment,
  fetchArrowSet,
  fetchBackgrounds,
  fetchDrill,
  fetchDrills,
  fetchExplainerImage,
  fetchIconSet,
  fetchProduct,
  fetchUser,
  fetchUserScores,
  increaseUserLevel,
  postScore,
  resetState,
  setAssessmentDrillNumber,
  setCurrentAssessment,
  setMaximumLevelReachedModalShown,
  setPostLogoutUrl,
};
