import FibreManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Button, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../providers';
import { actionCreators } from '../redux/eyegym';
import { routes } from '../routes';

const useStyles = makeStyles((theme) => ({
  acceptButton: {
    marginRight: theme.spacing(2),
    width: '200px !important',
  },
  body1: {
    fontSize: '0.875rem',
  },
  bottomMargin: {
    marginBottom: theme.spacing(1),
  },
  button: {
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '10em',
    width: '150px',
  },
  buttonContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
    padding: `${theme.spacing(2)} 0`,
    textAlign: 'center',
    width: '100%',
  },
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  centered: {
    margin: 'auto',
    width: 'fit-content',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    minHeight: '100%',
    paddingTop: theme.spacing(3),
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  leftMargin: {
    marginLeft: theme.spacing(2),
  },
  list: {
    paddingTop: 0,
  },
  listIcon: {
    minWidth: '36px',
  },
  listItem: {
    padding: `0 ${theme.spacing(2)}`,
  },
  marginAuto: {
    margin: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function Disclaimer() {
  const classes = useStyles();

  const { user } = useSelector((state) => state.eyegym);

  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (document) {
      document.title = 'EyeGym | Disclaimer';
    }
  }, []);

  const handleCancelClick = () => {
    dispatch(actionCreators.resetState());
    logout();
  };

  const handleAcceptClick = () => {
    dispatch(actionCreators.acceptDisclaimer(user.id));
    navigate(`${routes.default}?acceptedDisclaimer=true`);
  };

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={clsx(classes.column, classes.card)}>
            <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap, classes.header)}>
              <Box className={clsx(classes.stretched, classes.headline)}>Disclaimer</Box>
            </Box>
            <Box className={classes.centered}>
              <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap)}>
                <Typography
                  variant="body1"
                  component="h1"
                  className={clsx(classes.body1, classes.leftMargin, classes.bottomMargin)}
                >
                  By using or accessing any program available on this website, you agree to the following terms and
                  conditions:
                </Typography>
                <Box className={classes.stretched}>
                  <Typography variant="subtitle2" component="h2" className={classes.leftMargin}>
                    System usage
                  </Typography>
                  <List dense className={classes.list}>
                    <ListItem>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Please remember to use the 'Sign out' button from the menu when you have finished training. This helps to ensure your total privacy when using EyeGym." />
                    </ListItem>
                  </List>
                  <Typography variant="subtitle2" component="h2" className={classes.leftMargin}>
                    Medical disclaimer
                  </Typography>
                  <List dense className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="There are some situations and conditions where use of EyeGym is strongly discouraged. This website uses software that creates flickering light at a rate of 25-35 Frames Per Second, which can cause seizures in persons with various medical conditions." />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Before you proceed to EyeGym and take part in any of the drills, we strongly recommend that you seek advice from your licensed medical practitioner if you have either experienced seizures in the past or believe that you may potentially experience seizures due to flickering on the screen." />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="If you are in any way uncertain of what effect flickering images at a rate of 25-35 Frames Per Second is going to have on you we ask that you DO NOT enter EyeGym until you have received medical confirmation that it is safe for you to participate in the drills." />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Seizures may be caused by the following conditions: epilepsy; visual photosensitivity; heart disorders; or use of a pacemaker, stimulants, tranquilizers, psychotropic medications, drugs or alcohol. This list is not intended to be exhaustive and you should consult with your medical practitioner before proceeding if you have reason to suspect that you may have a problem." />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Even if you do not suffer from any medical conditions, should you feel discomfort we recommend that you set the light intensity levels on your viewing device to the minimum levels. If discomfort should continue, please stop usage immediately and seek medical advice." />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listIcon}>
                        <FibreManualRecordIcon color="primary" fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="If you accept this Disclaimer and enter EyeGym, you agree to do so at your own risk and assume full responsibility for any risk to any injury, damage or loss that may result from the use thereof. Dr Sherylle Calder will not be liable for any personal or physical damage or loss, howsoever cause, whether directly or indirectly, resulting from the use of the EyeGym website." />
                    </ListItem>
                  </List>
                </Box>
                <Box className={classes.column}>
                  <Box m={2} className={classes.buttonContainer}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={clsx(classes.acceptButton, classes.button)}
                      onClick={handleAcceptClick}
                    >
                      Accept &amp; Continue
                    </Button>
                    <Button
                      autoFocus
                      variant="outlined"
                      color="primary"
                      className={classes.button}
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
