import { CssBaseline } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import * as Loads from 'react-loads';

import { CacheProvider, LogContext } from '../providers';
import { fetchSettings } from '../services';

import { Backdrop } from './Backdrop';
import { Footer } from './Footer';
import { NavMenu } from './NavMenu';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  main: {
    height: '100%',
    maxHeight: '100%',
    paddingBottom: '56px',
    paddingTop: '64px',
  },
  root: {
    height: '100%',
    minHeight: '100vh',
  },
}));

export function Layout(props) {
  const { log } = useContext(LogContext);
  const { children } = props;
  const classes = useStyles(props);

  const {
    response: settings,
    isCached,
    isResolved,
  } = Loads.useLoads('settings', fetchSettings({ log }), {
    cacheProvider: CacheProvider,
    cacheStrategy: 'context-only',
    loadPolicy: 'cache-and-load',
    variables: [],
  });

  useEffect(() => {
    if (settings && (isCached || isResolved)) {
      localStorage.setItem('driver-bureau-redirect-uri', settings.driverBureauRedirectUri);
    }
  }, [isCached, isResolved, settings]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Backdrop />
      <NavMenu />
      <main className={classes.main} role="main">
        {children}
      </main>
      <Footer className="footer text-muted" />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
