import DotIcon from '@mui/icons-material/FiberManualRecord';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import detectTouchEvents from 'detect-touch-events';
import is from 'is_js';
import React from 'react';

const useStyles = makeStyles({
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
    flexWrap: 'wrap',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  indicator: {
    backgroundColor: '#001A33',
  },
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    '& .MuiListItemIcon-root': {
      minWidth: '32px',
    },
    flexGrow: 100000,
  },
  tabRoot: {
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: '#BFD9F2',
    },
  },
  tabsRoot: {
    borderBottom: '1px solid #efefef',
    width: '100%',
  },
});

export function Requirements(props) {
  const classes = useStyles(props);

  return (
    <>
      <Box sx={{ p: 2 }} className={clsx(classes.header, classes.row)}>
        <Box className={clsx(classes.headline, classes.stretched)}>Before you train</Box>
      </Box>
      <Box className={classes.stretched}>
        <List dense>
          {(is.tablet() || detectTouchEvents.hasSupport) && (
            <ListItem>
              <ListItemIcon>
                <DotIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Please make sure your device is landscape-oriented." />
            </ListItem>
          )}
          {is.desktop() && (
            <>
              <ListItem>
                <ListItemIcon>
                  <DotIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Press F11 to switch your browser to full-screen mode." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DotIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Adjust your screen resolution to be at least 1024 x 768." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DotIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Assessments and some drills require the use of a mouse." />
              </ListItem>
            </>
          )}
          <ListItem>
            <ListItemIcon>
              <DotIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Ensure that cookies are enabled." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <DotIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Make sure that your browser is not in 'private browsing' mode." />
          </ListItem>
        </List>
      </Box>
    </>
  );
}
