import { AppBar, Link, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { plainToClass } from 'class-transformer';
import clsx from 'clsx';
import is from 'is_js';
import { isUndefined } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { AuthUser, User } from '../models';
import { EyeGymContext } from '../providers';

import { LoginMenu } from './LoginMenu';
import { Logo } from './Logo';
import { OfflineIcon } from './OfflineIcon';

const useStyles = makeStyles((theme) => ({
  link: {
    '&:hover': {
      color: 'lightblue',
    },
  },
  logo: {
    flexGrow: 1,
  },
  navBarBackground: {
    '&.MuiPaper-root': {
      backgroundColor: '#1f2326 !important',
    },
  },
  root: {
    flexGrow: 1,
  },
  selected: {
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    },
    color: 'white',
  },
  typoRoot: {
    '& > * + *': {
      marginLeft: `${theme.spacing(2)} !important`,
    },
  },
  vpj: {
    flexGrow: 0,
    marginRight: theme.spacing(3),
  },
}));

export function NavMenu(props) {
  const classes = useStyles(props);
  const { isRedirecting, user: authUser } = useSelector((state) => state.authentication);
  const { user: plainUser } = useSelector((state) => state.eyegym);
  const { assessment } = useContext(EyeGymContext);
  const { pathname } = useLocation();

  const authenticatedUser = useMemo(() => plainToClass(AuthUser, authUser), [authUser]);

  const isAuthenticated = useMemo(() => {
    const id = is.not.online() ? authUser?.id : authUser?.profile?.sub;

    return id;
  }, [authUser?.id, authUser?.profile?.sub]);

  const user = useMemo(() => plainToClass(User, plainUser), [plainUser]);

  const isLogin = useMemo(() => pathname.includes('/login'), [pathname]);

  const isDisclaimer = useMemo(() => pathname.includes('/disclaimer'), [pathname]);

  const getEyeGymUserMenu = () => (
    <Typography className={classes.typoRoot}>
      <Link
        color="primary"
        component={RouterLink}
        to="/"
        className={clsx(classes.link, pathname === '/' && classes.selected)}
        underline="hover"
      >
        Home
      </Link>
      {/* <Link
        color="primary"
        component={RouterLink}
        to="/my-progress"
        className={clsx(classes.link, pathname.includes('/my-progress') && classes.selected)}
      >
        My progress
      </Link> */}
      <LoginMenu />
    </Typography>
  );

  const getDriverBureauUserMenu = () => (
    <Typography className={classes.typoRoot}>
      <Link
        color="primary"
        component={RouterLink}
        to="/"
        className={clsx(classes.link, pathname === '/' && classes.selected)}
        underline="hover"
      >
        Home
      </Link>
      {/* <Link
        color="primary"
        component={RouterLink}
        to="/progress"
        className={clsx(classes.link, pathname.includes('/progress') && classes.selected)}
      >
        My progress
      </Link> */}
      <LoginMenu />
    </Typography>
  );

  const getTeamLeaderMenu = () => (
    <Typography className={classes.typoRoot}>
      <Link
        color="primary"
        component={RouterLink}
        to="/"
        className={clsx(classes.link, pathname === '/' && classes.selected)}
        underline="hover"
      >
        Home
      </Link>
      <Link
        color="primary"
        component={RouterLink}
        to="/team"
        className={clsx(classes.link, pathname.includes('/team') && classes.selected)}
        underline="hover"
      >
        Team
      </Link>
      {/* <Link
        color="primary"
        component={RouterLink}
        to="/progress"
        className={clsx(classes.link, pathname.includes('/progress') && classes.selected)}
      >
        My progress
      </Link> */}
      <LoginMenu />
    </Typography>
  );

  const getUserMenu = () => (
    <Typography className={classes.typoRoot}>
      <Link
        color="primary"
        component={RouterLink}
        to="/"
        className={clsx(classes.link, pathname === '/' && classes.selected)}
        underline="hover"
      >
        Home
      </Link>
      <LoginMenu />
    </Typography>
  );

  const getAnonymousMenu = () => (
    <Typography className={classes.typoRoot}>
      <LoginMenu />
    </Typography>
  );

  const GetMenu = () => {
    if (isUndefined(user?.acceptedDisclaimer) || !isAuthenticated || !authenticatedUser || assessment) {
      return getAnonymousMenu();
    }

    if (user?.isTeamLeader) {
      return getTeamLeaderMenu();
    }

    if (authenticatedUser?.isDriverBureauUser) {
      return getDriverBureauUserMenu();
    }

    if (authenticatedUser?.isEyeGymUser) {
      return getEyeGymUserMenu();
    }

    return getUserMenu();
  };

  return (
    <AppBar position="fixed" className={clsx(classes.navBarBackground, classes.root)}>
      <Toolbar>
        {authenticatedUser?.isDriverBureauUser && <Logo className={classes.vpj} imageName="vpj-logo.png" />}
        <Logo className={classes.logo} />
        <OfflineIcon />
        {isRedirecting || isLogin || isDisclaimer ? <div /> : GetMenu()}
      </Toolbar>
    </AppBar>
  );
}
