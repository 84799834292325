import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo, useState } from 'react';

import { noop } from '../utils';

export const TriStateCheckbox = forwardRef((props, ref) => {
  const { defaultChecked, checked: checkedProp, onChange, indeterminate: _, indeterminateIcon: __, ...rest } = props;

  const [checked, setChecked] = useState(defaultChecked);

  const indeterminate = useMemo(() => checked === null || checked === undefined, [checked]);

  if (checkedProp !== undefined && checked !== checkedProp) {
    setChecked(checkedProp);

    return null;
  }

  const handleChange = (event) => {
    setChecked((chckd) => {
      let nextChecked;

      if (chckd === false) {
        nextChecked = null;
      } else if (chckd === true) {
        nextChecked = false;
      } else {
        nextChecked = true;
      }

      onChange(event, nextChecked);

      return nextChecked;
    });
  };

  return (
    <Checkbox
      {...rest}
      checked={checked || false}
      indeterminate={indeterminate}
      inputRef={ref}
      onChange={handleChange}
    />
  );
});

TriStateCheckbox.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  indeterminate: PropTypes.any,
  indeterminateIcon: PropTypes.any,
  onChange: PropTypes.func,
};

TriStateCheckbox.defaultProps = {
  checked: undefined,
  defaultChecked: undefined,
  indeterminate: undefined,
  indeterminateIcon: undefined,
  onChange: noop,
};
