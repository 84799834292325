import { useLayoutEffect, useState } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    height,
    width,
  };
};

export const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    const handleResize = () => {
      setDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
};

const getIsRetina = () => {
  const { devicePixelRatio } = window;

  return devicePixelRatio > 1;
};

export const useRetina = () => {
  const [retina, setRetina] = useState(getIsRetina);

  useLayoutEffect(() => {
    const handleResize = () => {
      setRetina(getIsRetina());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { retina };
};
