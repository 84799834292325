import { authenticationReducer, authenticationState } from '../authentication';
import { drillReducer, drillState } from '../drill';
import { eyegymReducer, eyegymState } from '../eyegym';
import { offlineReducer, offlineState } from '../offline';
import { rehydrateReducer, rehydrateState } from '../rehydrate';

export const reducers = {
  authentication: authenticationReducer,
  drill: drillReducer,
  eyegym: eyegymReducer,
  offlineUsers: offlineReducer,
  rehydrate: rehydrateReducer,
};

export const applicationState = {
  authentication: authenticationState,
  drill: drillState,
  eyegym: eyegymState,
  offlineUsers: offlineState,
  rehydrate: rehydrateState,
};
