/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid as DataGrid,
  TableColumnVisibility,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { Box, Button, Grid, Paper, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import is from 'is_js';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LogContext } from '../../providers';
import { actionCreators } from '../../redux/offline';
import { noop, useWindowDimensions } from '../../utils';
import { CheckboxCell } from '../CheckboxCell';
import { Loader } from '../Loader';
import { SynchroniseUsersModal } from '../Modals';
import { TooltipTableCell } from '../TooltipTableCell';
import { TriStateCheckbox } from '../TriStateCheckbox';

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
    width: '100%',
  },
  centered: {
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  checkboxCell: {
    padding: '16px 8px',
  },
  checkboxColumn: {
    padding: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropdownFilter: {
    fontSize: '14px !important',
    width: '100%',
  },
  filterCell: {
    padding: 0,
  },
  filterContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  gridRoot: {
    '&:first-child': {
      height: (props) => `${props.tableHeight}px !important`,
    },
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  headlineButton: {
    marginLeft: `${theme.spacing(1)} !important`,
  },
  loader: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

const columns = [
  { name: 'id', title: 'ID' },
  { name: 'surname', title: 'Surname' },
  { name: 'name', title: 'Name' },
  { name: 'username', title: 'Username' },
  { name: 'email', title: 'Email' },
  { name: 'expiryDate', title: 'Expiry date' },
  { name: 'dateModified', title: 'Last updated' },
  { name: 'lastSynchronised', title: 'Last synchronised' },
  { name: 'active', title: 'Active' },
];

const getRowId = (row) => row.id;

function DateTypeProvider(props) {
  return (
    <DataTypeProvider
      formatterComponent={({ value }) => (value ? moment.utc(value).format('YYYY-MM-DD') : '')}
      {...props}
    />
  );
}

function CheckboxFilterCell(props) {
  const { filter, onFilter } = props;
  const classes = useStyles();

  return (
    <TableCell className={classes.checkboxCell}>
      <TriStateCheckbox
        checked={filter ? filter.value : null}
        color="primary"
        className={clsx(classes.checkboxColumn, classes.centered)}
        onChange={(_, value) => onFilter({ value })}
      />
    </TableCell>
  );
}

CheckboxFilterCell.propTypes = {
  filter: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
};

CheckboxFilterCell.defaultProps = {
  filter: null,
};

function FilterCell(props) {
  const { column, onFilter } = props;

  switch (column.name.toLowerCase()) {
    case 'expirydate':
    case 'datemodified':
    case 'lastsynchronised':
      return <TableCell />;
    case 'active':
      return <CheckboxFilterCell {...props} />;
    default: {
      const handleFilter = debounce(onFilter, 1000);

      return <TableFilterRow.Cell {...props} id={`FilterCell-${column.name}`} onFilter={handleFilter} />;
    }
  }
}

FilterCell.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  onFilter: PropTypes.func,
};

FilterCell.defaultProps = {
  onFilter: noop,
};

function NoDataCell(props) {
  const { colSpan } = props;
  const { loadingUsers: loading } = useSelector((state) => state.offlineUsers);

  return loading ? <td colSpan={colSpan} /> : <VirtualTable.NoDataCell {...props} />;
}

NoDataCell.propTypes = {
  colSpan: PropTypes.number,
};

NoDataCell.defaultProps = {
  colSpan: undefined,
};

export function UsersGrid(props) {
  const { teamId } = props;

  const {
    user: { team },
  } = useSelector((state) => state.eyegym);

  const { loadingUsers: loading } = useSelector((state) => state.offlineUsers);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [sorting, setSorting] = useState([
    { columnName: 'surname', direction: 'asc' },
    { columnName: 'name', direction: 'asc' },
  ]);

  const [filters, setFilters] = useState([]);
  const { height } = useWindowDimensions();
  const { log } = useContext(LogContext);
  const activeElementId = useRef();
  const dispatch = useDispatch();

  const CHROME_HEIGHT = 420;
  const FIXED_ROW_HEIGHT = 182;
  const rowSpace = height - CHROME_HEIGHT;

  const classes = useStyles({ tableHeight: rowSpace + FIXED_ROW_HEIGHT });

  const numberOfUsers = useMemo(() => rows?.length ?? 0, [rows]);

  const caption = useMemo(() => `${numberOfUsers} User${numberOfUsers !== 1 ? 's' : ''}`, [numberOfUsers]);

  const loadData = useCallback(async () => {
    const element = document.activeElement;
    const parent = element.closest('[id^="FilterCell-"]');

    if (parent) {
      activeElementId.current = parent.id;
    } else {
      activeElementId.current = undefined;
    }

    await dispatch(actionCreators.addOfflineTeam(team));

    return dispatch(actionCreators.fetchOfflineTeamUsers(teamId, { log }));
  }, [dispatch, teamId, team, log]);

  useEffect(() => {
    loadData().then((users) => setRows(users ?? []));
  }, [loadData]);

  const handleSynchroniseClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  function RootComponent(innerProps) {
    return <DataGrid.Root {...innerProps} className={clsx(classes.fullHeight, classes.gridRoot)} />;
  }

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12}>
        <Paper id="usersGrid" className={clsx(classes.fullHeight, classes.card)}>
          <Box sx={{ p: 2 }} className={clsx(classes.header, classes.row, classes.wrap)}>
            <Box className={clsx(classes.headline, classes.stretched)}>{caption}</Box>
            <Box>
              <Button
                color="primary"
                variant="outlined"
                disabled={!selection.length || is.not.online()}
                className={classes.headlineButton}
                onClick={handleSynchroniseClick}
              >
                Synchronise users
              </Button>
              <SynchroniseUsersModal open={modalOpen} users={selection} team={team} onClose={handleModalClose} />
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box className={classes.stretched}>
              {is.online() && (
                <DataGrid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
                  <SelectionState selection={selection} onSelectionChange={setSelection} />
                  <IntegratedSelection />
                  <FilteringState filters={filters} onFiltersChange={setFilters} />
                  <IntegratedFiltering />
                  <SortingState sorting={sorting} onSortingChange={setSorting} />
                  <IntegratedSorting />
                  <DateTypeProvider for={['dateModified', 'expiryDate', 'lastSynchronised']} />
                  <CheckboxCell disabled for={['active']} className={classes.checkboxColumn} />
                  <TooltipTableCell for={['name', 'surname', 'username', 'email']} />
                  <VirtualTable
                    noDataCellComponent={NoDataCell}
                    columnExtensions={[{ align: 'center', columnName: 'active' }]}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableSelection showSelectAll />
                  <TableFilterRow cellComponent={FilterCell} />
                  <TableColumnVisibility defaultHiddenColumnNames={['id']} />
                </DataGrid>
              )}
              {is.not.online() && (
                <DataGrid rows={rows} columns={columns} getRowId={getRowId} rootComponent={RootComponent}>
                  <FilteringState filters={filters} onFiltersChange={setFilters} />
                  <IntegratedFiltering />
                  <SortingState sorting={sorting} onSortingChange={setSorting} />
                  <IntegratedSorting />
                  <DateTypeProvider for={['dateModified', 'expiryDate', 'lastSynchronised']} />
                  <CheckboxCell disabled for={['active']} className={classes.checkboxColumn} />
                  <TooltipTableCell for={['name', 'surname', 'username', 'email']} />
                  <VirtualTable
                    noDataCellComponent={NoDataCell}
                    columnExtensions={[{ align: 'center', columnName: 'active' }]}
                  />
                  <TableHeaderRow showSortingControls />
                  <TableFilterRow cellComponent={FilterCell} />
                  <TableColumnVisibility defaultHiddenColumnNames={['id']} />
                </DataGrid>
              )}
            </Box>
          </Box>
          {loading && (
            <div className={classes.loader}>
              <Loader />
            </div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}

UsersGrid.propTypes = {
  teamId: PropTypes.string.isRequired,
};
