/* eslint-disable no-debugger */
import { Box, Button, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import is from 'is_js';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { actionCreators as authActionCreators } from 'redux/authentication';
import { actionCreators as drillActionCreators } from 'redux/drill';
import { actionCreators as eyegymActionCreators } from 'redux/eyegym';

import { AuthContext, LogContext } from '../../providers';
import { signIn } from '../../services';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '10em',
    marginTop: '15px !important',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
    flexWrap: 'wrap',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  rememberMe: {
    marginLeft: 0,
    padding: '8px 0',
  },
  rememberMeCheckbox: {
    '&:first-child': {
      marginRight: '8px',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  spaced: {
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  stretched: {
    flexGrow: 100000,
  },
}));

export function LoginForm() {
  const location = useLocation();
  const { search } = location;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [returnUrl, setReturnUrl] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const { log } = useContext(LogContext);
  const { navigate, store } = useContext(AuthContext);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const values = queryString.parse(search);
    setReturnUrl(values.ReturnUrl);
    dispatch(eyegymActionCreators.clearStore());
    dispatch(drillActionCreators.clearStore());
    dispatch(authActionCreators.clearStore());
  }, [dispatch, search]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const navigateToReturnUrl = (url) => {
    if (is.not.online()) {
      navigate(url);
    } else {
      window.location.replace(url);
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      log?.debug('returnUrl: {returnUrl}', { returnUrl });

      const response = await signIn(username, password, false, returnUrl, { log, store });

      if (!response?.returnUrl) {
        debugger;
      }

      log?.debug('response.returnUrl: {returnUrl}', {
        returnUrl: response?.returnUrl,
      });

      if (response.status && response.status === 401) {
        setError(true);
        setHelperText('Username and/or password is incorrect');
      } else {
        navigateToReturnUrl(response.returnUrl);
      }
    } catch (ex) {
      log?.debug('Exception during login: {error}', {
        error: ex,
      });
    }
  };

  return (
    <>
      <Box sx={{ p: 2 }} className={clsx(classes.header, classes.row)}>
        <Box className={clsx(classes.headline, classes.stretched)}>Login</Box>
      </Box>
      <Box sx={{ p: 2 }} className={classes.row}>
        <form>
          <Box className={classes.stretched}>
            <TextField
              fullWidth
              error={error}
              label="User name"
              value={username}
              className={classes.spaced}
              autoComplete="username"
              variant="standard"
              onChange={handleUsernameChange}
            />
            <TextField
              fullWidth
              error={error}
              helperText={helperText}
              label="Password"
              value={password}
              type="password"
              className={classes.spaced}
              autoComplete="current-password"
              variant="standard"
              onChange={handlePasswordChange}
            />
            <Button
              fullWidth
              disabled={!(username && password)}
              variant="outlined"
              className={classes.button}
              color="primary"
              onClick={handleSubmit}
            >
              Sign in
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}

LoginForm.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

LoginForm.defaultProps = {
  location: { search: undefined },
};
