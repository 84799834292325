/* eslint-disable no-console */
import { plainToClass } from 'class-transformer';
import is from 'is_js';
import maxBy from 'lodash/maxBy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TrainingFrequency, User } from '../models';
import { actionCreators as eyegymActionCreators } from '../redux/eyegym';

import { LogContext } from './LogProvider';

const defaultConfig = {
  assessment: null,
  drills: null,
  expiryDate: null,
  lastSession: null,
  product: null,
  scores: null,
  timeRemaining: null,
  timeTrained: null,
  totalTrainingTime: null,
  trainingFrequency: null,
  user: null,
};

export const EyeGymContext = React.createContext(defaultConfig);

export function EyeGymProvider({ children, nested, ...rest }) {
  const { user: authUser, isSilentRenew } = useSelector((state) => state.authentication);
  const { user: plainUser, product, drills, assessment, scores } = useSelector((state) => state.eyegym);
  const outerOptions = useContext(EyeGymContext);
  const { log } = useContext(LogContext);
  const dispatch = useDispatch();
  const assessmentCompleted = useRef(!!assessment?.isCompleted);

  const user = useMemo(() => plainToClass(User, plainUser), [plainUser]);

  const isAuthenticated = useMemo(() => {
    const id = is.not.online() ? authUser?.id : authUser?.profile?.sub;

    return id;
  }, [authUser?.id, authUser?.profile?.sub]);

  const lastSession = useMemo(() => {
    if (!scores?.length) {
      return null;
    }

    const lastScore = maxBy(scores, (s) => moment.utc(s.scoreDate).valueOf());
    const date = moment.utc(lastScore.scoreDate).local();

    if (date.isSameOrBefore('1970-01-01')) {
      return null;
    }

    return date;
  }, [scores]);

  const trainingFrequency = useMemo(
    () => (user?.forceTrainingOverrides ? user?.trainingFrequency : user?.team?.trainingFrequency),
    [user]
  );

  const totalTrainingTime = useMemo(() => scores.reduce((sum, s) => sum + s.duration, 0), [scores]);

  const timeTrained = useMemo(() => {
    let filteredScores;

    switch (trainingFrequency) {
      case TrainingFrequency.PER_WEEK:
        filteredScores = scores.filter((s) => moment.utc(s.scoreDate).isSame(moment.utc(), 'week'));
        break;
      case TrainingFrequency.PER_MONTH:
        filteredScores = scores.filter((s) => moment.utc(s.scoreDate).isSame(moment.utc(), 'month'));
        break;
      default:
        filteredScores = scores.filter((s) => moment.utc(s.scoreDate).isSame(moment.utc(), 'day'));
        break;
    }

    const retVal = filteredScores.reduce((sum, s) => sum + s.duration, 0);

    return retVal;
  }, [trainingFrequency, scores]);

  const expiryDate = useMemo(
    () => moment.utc(user && is.function(user.getExpiryDate) && user.getExpiryDate(product)),
    [user, product]
  );

  const timeRemaining = useMemo(() => {
    if (!user || !user.team) {
      return 0;
    }

    const trainingTime = user.forceTrainingOverrides ? user.trainingTime : user.team.trainingTime;
    const isValid = expiryDate.isSameOrAfter(moment.utc(), 'day') && product && !product.isComplete;

    const value = trainingTime * 60 - timeTrained;

    return isValid && value > 0 ? value : 0;
  }, [user, expiryDate, product, timeTrained]);

  useEffect(() => {
    localStorage.setItem('postLogoutRedirectUri', user?.redirectUrl);
  }, [user]);

  useEffect(() => {
    const id = is.not.online() ? authUser?.id : authUser?.profile?.sub;

    if (isSilentRenew || !id || nested) {
      return;
    }

    if (id) {
      dispatch(eyegymActionCreators.fetchUser(id, { log }));
    }
  }, [dispatch, authUser?.id, authUser?.profile?.sub, isSilentRenew, log, nested]);

  useEffect(() => {
    if (isSilentRenew || !authUser || !isAuthenticated || nested) {
      return;
    }

    if (user?.currentUserProduct?.productId) {
      dispatch(eyegymActionCreators.fetchProduct(user.currentUserProduct.productId, { log }));
    }

    if (user?.assessments?.length) {
      const currentAssessments = user.assessments.filter(
        (a) => new Date(a.assessmentDate) <= new Date() && !a.isCompleted
      );

      if (currentAssessments?.length) {
        currentAssessments.sort((a, b) => new Date(b.assessmentDate) - new Date(a.assessmentDate));
        const currentAssessment = currentAssessments[0];
        dispatch(eyegymActionCreators.setCurrentAssessment(currentAssessment));
        dispatch(eyegymActionCreators.fetchProduct(currentAssessment.productId, { log }));
      } else {
        dispatch(eyegymActionCreators.setCurrentAssessment(null));
      }
    } else {
      dispatch(eyegymActionCreators.setCurrentAssessment(null));
    }
  }, [
    dispatch,
    authUser,
    isAuthenticated,
    isSilentRenew,
    nested,
    user?.assessments,
    user?.currentUserProduct?.productId,
    log,
  ]);

  useEffect(() => {
    if (isSilentRenew || !authUser || !isAuthenticated || nested) {
      return;
    }

    if (assessmentCompleted.current !== !!assessment?.isCompleted) {
      return;
    }

    if (product?.productDrills) {
      const drillIds = product.productDrills.map((drill) => drill.drillId);

      if (assessment?.product && !assessment?.isCompleted) {
        drillIds.push(...assessment.product.productDrills.map((drill) => drill.drillId));
      }

      const drillFilter = [
        {
          columnName: 'Id',
          op: 'in',
          value: drillIds,
        },
      ];

      dispatch(eyegymActionCreators.fetchDrills(drillFilter, { log }));

      const scoresFilter = [
        {
          columnName: 'drillId',
          op: 'in',
          value: drillIds,
        },
        {
          columnName: 'userProductId',
          op: 'eq',
          value: user?.currentUserProductId,
        },
      ];

      dispatch(eyegymActionCreators.fetchUserScores(scoresFilter, { log }));
    }
  }, [
    dispatch,
    authUser,
    product,
    user,
    log,
    isSilentRenew,
    isAuthenticated,
    nested,
    assessment?.product,
    assessment?.isCompleted,
  ]);

  const context = useMemo(
    () => ({
      ...outerOptions,
      ...rest,
      assessment,
      drills,
      expiryDate,
      lastSession,
      product,
      scores,
      timeRemaining,
      timeTrained,
      totalTrainingTime,
      trainingFrequency,
      user,
    }),
    [
      assessment,
      drills,
      expiryDate,
      lastSession,
      outerOptions,
      product,
      rest,
      scores,
      timeRemaining,
      timeTrained,
      totalTrainingTime,
      trainingFrequency,
      user,
    ]
  );

  return <EyeGymContext.Provider value={context}>{children}</EyeGymContext.Provider>;
}

EyeGymProvider.propTypes = {
  children: PropTypes.node.isRequired,
  nested: PropTypes.bool,
};

EyeGymProvider.defaultProps = {
  nested: false,
};
