/* eslint-disable no-console */

import ExpectedTimeIcon from '@mui/icons-material/Alarm';
import TooSlowIcon from '@mui/icons-material/AlarmOff';
import GoodResponseTimeIcon from '@mui/icons-material/AlarmOn';
import DisappointedMoodIcon from '@mui/icons-material/SentimentDissatisfied';
import GoodMoodIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import BadMoodIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import is from 'is_js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GoalType, Key } from '../../models';
import { DrillContext } from '../../providers';
import { actionCreators } from '../../redux/eyegym';
import { addEvent, getImageAssetUri, noop, removeEvent } from '../../utils';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '10em',
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: '150px',
  },
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  centered: {
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  greenText: {
    color: '#0caa0c',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  iconColumn: {
    textAlign: 'right',
  },
  marginAuto: {
    margin: 'auto',
  },
  paddedContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: `${theme.spacing(2)} !important`,
    textAlign: 'center',
    width: '100%',
  },
  redText: {
    color: '#cc0c0c',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  valueColumn: {
    textAlign: 'right',
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function Score(props) {
  const { onReplay, onNext, onContinue, onFinish, showAnswer, correctAnswer, userAnswer } = props;
  const { assessment, drill, userLevel, level, maximumLevel, settings } = useContext(DrillContext);
  const { scores, assessmentDrillNumber } = useSelector((state) => state.eyegym);
  const [isAssessment, setIsAssessment] = useState(false);
  const [answer, setAnswer] = useState();
  const [showAverage, setShowAverage] = useState(true);
  const [showContinue, setShowContinue] = useState(false);
  const [showCorrectScore, setShowCorrectScore] = useState(true);
  const [showFinish, setShowFinish] = useState(false);
  const [finishDisabled, setFinishDisabled] = useState(true);
  const [showIncorrectScore, setShowIncorrectScore] = useState(true);
  const [showNext, setShowNext] = useState(false);
  const [showPercentage, setShowPercentage] = useState(true);
  const [showReplay, setShowReplay] = useState(false);
  const [showRequired, setShowRequired] = useState(true);
  const [showSlow, setShowSlow] = useState(true);
  const score = scores[scores.length - 1];
  const classes = useStyles();
  const dispatch = useDispatch();

  const TYPO_VARIANT = 'h5';

  useEffect(() => {
    const listener = (event) => {
      if (event.code === Key.K_ENTER || event.code === Key.K_NUMPAD_ENTER) {
        if (isAssessment) {
          if (showContinue) {
            onContinue();
          } else if (showFinish) {
            onFinish();
          }
        } else if (showNext) {
          onNext();
        } else if (showReplay && !showNext) {
          onReplay();
        }
      }
    };

    addEvent(document, 'keydown', listener);

    return () => {
      removeEvent(document, 'keydown', listener);
    };
  }, [isAssessment, onContinue, onFinish, onNext, onReplay, showContinue, showFinish, showNext, showReplay]);

  useEffect(() => {
    if (!settings) {
      return;
    }

    const targetTime = settings.targetTime * 1000;

    setIsAssessment(!!assessment);
    setShowAverage(true);
    setShowCorrectScore(true);
    setShowContinue(false);
    setShowIncorrectScore(true);
    setShowNext(false);
    setShowPercentage(true);
    setShowRequired(true);
    setShowReplay(false);
    setShowSlow(true);
    setFinishDisabled(true);

    if (showAnswer) {
      if (correctAnswer === userAnswer) {
        setAnswer(`The answer was ${correctAnswer}, you answered correctly`);
      } else {
        setAnswer(
          `The answer was ${correctAnswer}, you answered ${is.number(userAnswer) ? userAnswer : `'${userAnswer}'`}`
        );
      }
    }

    if (drill) {
      if (drill.goalType === GoalType.BATAK || drill.goalType === GoalType.GREEN_RED_LIGHT) {
        setShowCorrectScore(false);
        setShowIncorrectScore(false);
        setShowPercentage(false);
      } else if (drill.goalType === GoalType.EYE_RUN) {
        setShowCorrectScore(false);
        setShowIncorrectScore(false);
      }

      if (!targetTime || drill.goalType === GoalType.TRACK_CORRECT) {
        setShowAverage(drill.goalType === GoalType.BATAK);
        setShowRequired(false);
        setShowSlow(false);
      }

      if (drill.goalType === GoalType.STOP_CENTRE) {
        setShowAverage(true);
      }

      if (drill.goalType === GoalType.LINE_TRACKING || drill.goalType === GoalType.LINE_TRACKING_MULTIPLE) {
        setShowSlow(false);
      }
    }

    if (assessment) {
      // TODO: need to check if there is a drill to continue to.
      const remainingDrills = assessment.product.productDrills.filter((pd) => pd.order >= assessmentDrillNumber);

      if (remainingDrills.length) {
        setShowContinue(true);
      } else {
        if (is.online()) {
          dispatch(actionCreators.completeUserAssessment(assessment.id, moment.utc().toDate())).then(() => {
            setFinishDisabled(false);
          });
        } else {
          dispatch(actionCreators.completeUserAssessment(assessment.id, moment.utc().toDate()));
          setFinishDisabled(false);
        }

        setShowFinish(true);
      }
    } else if (score.passed) {
      setShowReplay(true);
      setShowNext(true);
    } else {
      setShowReplay(true);
    }
  }, [drill, settings, assessment, assessmentDrillNumber, score, showAnswer, correctAnswer, userAnswer, dispatch]);

  const message = useMemo(() => {
    if (!drill) {
      return;
    }

    let m = '';

    if (assessment) {
      return m;
    }

    if (score.passed) {
      if (userLevel?.level === level) {
        m = `You may proceed to level ${level + 1}.`;
      }

      if (settings.level === maximumLevel) {
        m = 'You have reached the maximum level for this drill.';
      }

      if (drill.goalType !== GoalType.BATAK && drill.goalType !== GoalType.GREEN_RED_LIGHT) {
        m = `Congratulations, you have achieved the necessary percentage of ${settings.scoreRequired.toLocaleString(
          'en-US',
          {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }
        )}%. ${m}`;
      }
    } else if (drill.goalType !== GoalType.BATAK && drill.goalType !== GoalType.GREEN_RED_LIGHT) {
      m = `Sorry, you did not achieve the necessary percentage of ${settings.scoreRequired.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}%. Please try this level again.`;
    } else {
      m = 'Sorry, you did not achieve the necessary score. Please try this level again.';
    }

    return m;
  }, [assessment, drill, level, maximumLevel, score, settings, userLevel?.level]);

  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.column, classes.card)}>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap, classes.header)}>
          {!isAssessment && (
            <Box className={clsx(classes.stretched, classes.headline)}>{`${drill && drill.name} - Level ${level}`}</Box>
          )}
          {isAssessment && <Box className={clsx(classes.stretched, classes.headline)}>{`${drill && drill.name}`}</Box>}
        </Box>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap)}>
          <Box className={clsx(classes.row, classes.marginAuto)}>
            <Box className={classes.column}>
              <Grid container spacing={1}>
                {!isAssessment && showAnswer && (
                  <Grid
                    item
                    container
                    spacing={1}
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                    className={classes.paddedContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(score.passed ? classes.greenText : classes.redText, classes.centered)}
                    >
                      <Typography variant={TYPO_VARIANT}>{answer}</Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAssessment && showCorrectScore && (
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={5} className={classes.iconColumn}>
                      <GoodMoodIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={1} className={clsx(classes.greenText, classes.valueColumn)}>
                      <Typography variant={TYPO_VARIANT}>{score.correct}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.greenText}>
                      <Typography variant={TYPO_VARIANT}>correct.</Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAssessment && showIncorrectScore && (
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={5} className={classes.iconColumn}>
                      <BadMoodIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={1} className={clsx(classes.redText, classes.valueColumn)}>
                      <Typography variant={TYPO_VARIANT}>{score.incorrect}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.redText}>
                      <Typography variant={TYPO_VARIANT}>incorrect.</Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAssessment && showSlow && (
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={5} className={classes.iconColumn}>
                      <DisappointedMoodIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={1} className={clsx(classes.redText, classes.valueColumn)}>
                      <Typography variant={TYPO_VARIANT}>{score.slow}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.redText}>
                      <Typography variant={TYPO_VARIANT}>slow.</Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAssessment && showRequired && (
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={5} className={classes.iconColumn}>
                      <ExpectedTimeIcon fontSize="large" />
                    </Grid>
                    <Grid item xs={1} className={classes.valueColumn}>
                      <Typography variant={TYPO_VARIANT}>
                        {score.targetTime.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={TYPO_VARIANT}>expected average response time.</Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAssessment && showAverage && (
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={5} className={classes.iconColumn}>
                      {score.responseTime <= score.targetTime && <GoodResponseTimeIcon fontSize="large" />}
                      {score.responseTime > score.targetTime && <TooSlowIcon fontSize="large" />}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className={clsx(
                        score.responseTime <= score.targetTime ? classes.greenText : classes.redText,
                        classes.valueColumn
                      )}
                    >
                      <Typography variant={TYPO_VARIANT}>
                        {score.responseTime.toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={clsx(score.responseTime < score.targetTime ? classes.greenText : classes.redText)}
                    >
                      <Typography variant={TYPO_VARIANT}>your average response time.</Typography>
                    </Grid>
                  </Grid>
                )}
                {!isAssessment && (
                  <Grid
                    item
                    container
                    spacing={1}
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                    className={classes.paddedContainer}
                  >
                    {showPercentage && (
                      <Grid item xs={12} className={classes.centered}>
                        <Typography variant={TYPO_VARIANT}>
                          {`Your overall percentage ${score.percentage.toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })}%`}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      className={clsx(score.passed ? classes.greenText : classes.redText, classes.centered)}
                    >
                      <Typography variant={TYPO_VARIANT}>{message}</Typography>
                    </Grid>
                  </Grid>
                )}
                {isAssessment && (
                  <Grid
                    item
                    container
                    spacing={1}
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                    className="paddedContainer"
                  >
                    <Grid item xs={12} className={classes.centered}>
                      <img alt="logo" src={getImageAssetUri('training-logo-colour.png')} />
                    </Grid>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  spacing={1}
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.paddedContainer}
                >
                  {!isAssessment && showReplay && (
                    <Button variant="outlined" className={classes.button} onClick={onReplay}>
                      Replay level
                    </Button>
                  )}
                  {!isAssessment && showNext && (
                    <Button variant="outlined" className={classes.button} onClick={onNext}>
                      Continue
                    </Button>
                  )}
                  {isAssessment && showContinue && (
                    <Button variant="outlined" className={classes.button} onClick={onContinue}>
                      Continue
                    </Button>
                  )}
                  {isAssessment && showFinish && (
                    <Button variant="outlined" disabled={finishDisabled} className={classes.button} onClick={onFinish}>
                      Finish
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

Score.propTypes = {
  correctAnswer: PropTypes.any,
  onContinue: PropTypes.func,
  onFinish: PropTypes.func,
  onNext: PropTypes.func,
  onReplay: PropTypes.func,
  showAnswer: PropTypes.bool,
  userAnswer: PropTypes.any,
};

Score.defaultProps = {
  correctAnswer: undefined,
  onContinue: noop,
  onFinish: noop,
  onNext: noop,
  onReplay: noop,
  showAnswer: false,
  userAnswer: undefined,
};
