import is from 'is_js';
import { Log, UserManager, WebStorageStateStore } from 'oidc-client';

import { IDENTITY_CONFIG, METADATA_OIDC } from '../constants/authConstants';
import { actionCreators } from '../redux/authentication';
import { routes } from '../routes';

import { signInWithToken } from './AccountsService';
import { AuthServiceEvents } from './AuthServiceEvents';

// const parseJwt = (token) => {
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace('-', '+').replace('_', '/');

//   return JSON.parse(window.atob(base64));
// };

const userManager = new UserManager({
  ...IDENTITY_CONFIG,
  metadata: {
    ...METADATA_OIDC,
  },
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
});

export class AuthService {
  UserManager;
  store;

  constructor(navigate, store) {
    this.UserManager = userManager;
    // this.UserManager = new UserManager({
    //   ...IDENTITY_CONFIG,
    //   metadata: {
    //     ...METADATA_OIDC,
    //   },
    //   userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    // });
    this._events = new AuthServiceEvents();
    this.store = store;
    this.navigate = navigate;
    this.UserManager.events.addSilentRenewError((e) => {
      Log.error('Error: {error} occurred in {source}', { error: e, source: 'service.AuthService.silentRenew' });
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      Log.warn('token expired');
      this.signinSilent();
    });
  }

  get events() {
    return this._events;
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then((user) => {
      const pathname = localStorage.getItem('redirectUri');
      this.events.load(user).then(() => {
        this.events.redirect(pathname);
      });
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();

    if (!user) {
      return this.UserManager.signinRedirectCallback();
    }

    return user;
  };

  signinRedirect = (token, context) => {
    localStorage.setItem('redirectUri', window.location.pathname);
    localStorage.setItem('token', token);
    const redirectUri = localStorage.getItem('driver-bureau-redirect-uri');

    if (is.online()) {
      const signIn = async () => {
        const response = await signInWithToken(token, window.location.pathname, context);

        if (response.returnUrl) {
          this.UserManager.signinSilent().then((user) => {
            localStorage.setItem('token-signed-in', true);
            const pathname = response.returnUrl;
            this.events.load(user).then(() => {
              this.events.redirect(pathname);
            });
          });
        } else {
          window.location.href = `${redirectUri}${token}`;
        }
      };

      if (token) {
        if (!localStorage.getItem('token-signed-in')) {
          signIn();
        }
      } else {
        this.UserManager.signinRedirect({
          useReplaceToNavigate: true,
        });
      }
    } else {
      this.navigate(routes.account.login);
    }
  };

  navigateToRedirectUri = () => {
    const pathname = localStorage.getItem('redirectUri');

    if (this.navigate) {
      this.navigate(pathname);
    } else {
      window.location.replace(pathname);
    }
  };

  isAuthenticated = () => {
    if (is.online()) {
      const oidcStorage = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
        )
      );

      return !!oidcStorage && !!oidcStorage.access_token;
    }

    const {
      authentication: { user },
    } = this.store.getState();

    return !!user;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        Log.debug('{user} signed in', { user });
      })
      .catch((err) => {
        Log.error('Error: {error} occurred in {source}', { error: err, source: 'services.AuthService.signInSilent' });
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => this.UserManager.createSigninRequest();

  logout = () => {
    if (is.online()) {
      this.UserManager.signoutRedirect({
        id_token_hint: localStorage.getItem('id_token'),
        useReplaceToNavigate: true,
      });
      this.UserManager.clearStaleState();
      localStorage.removeItem('token-signed-in');
    } else {
      this.store.dispatch(actionCreators.resetAuthState());
      this.navigate(routes.loggedOut);
    }
  };

  clearStaleState = () => {
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback()
      .then(() => {
        const postLogoutRedirectUri = localStorage.getItem('postLogoutRedirectUri');
        const token = localStorage.getItem('token');
        localStorage.clear();
        localStorage.setItem('postLogoutRedirectUri', postLogoutRedirectUri);
        localStorage.setItem('token', token);
      })
      .then(() => this.UserManager.clearStaleState())
      .then(() => {
        this.events.unload();
      });
  };
}
