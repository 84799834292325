import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import * as localforage from 'localforage';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

import { applicationState, reducers } from './reducers';
import { actionCreators } from './rehydrate';

const discard = (error) => {
  const { request, response } = error;

  if (!request) {
    throw error;
  }

  if (!response) {
    return false;
  }

  return 400 <= response.status && response.status <= 500;
};

const configureStore = (initialState = applicationState) => {
  const middleware = [
    thunk,
    createLogger({
      collapsed: true,
      predicate: () => process.env.NODE_ENV === 'development',
    }),
  ];

  const rootReducer = combineReducers({
    ...reducers,
  });

  const enhancers = [];

  enhancers.push(
    offline({
      ...offlineConfig,
      discard,
      persistCallback: () => {
        // eslint-disable-next-line no-use-before-define
        store.dispatch(actionCreators.rehydrateStore());
      },
      persistOptions: { blacklist: ['rehydrate'], stateReconciler: autoMergeLevel2, storage: localforage },
      returnPromises: true,
    })
  );

  const windowIfDefined = typeof window === 'undefined' ? null : window;

  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));

  return store;
};

export const store = configureStore();
