import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';
import track from 'react-tracking';

import { defaultOptions as logOptions, Log } from '../utils';

let trackLog;

export const defaultOptions = {
  appName: 'eyegym',
  host: null,
  level: 'debug',
  log: null,
  url: 'http://localhost:5341/training',
  version: '0.0.0',
};

export const LogContext = createContext({ ...defaultOptions });

function LogProviderImpl(props) {
  const { children, tracking, ...innerOptions } = props;
  const outerOptions = useContext(LogContext);

  const context = useMemo(
    () => ({
      ...outerOptions,
      ...innerOptions,
    }),
    [innerOptions, outerOptions]
  );

  const options = {
    ...logOptions,
    ...context,
  };

  trackLog = new Log(options);

  context.log = trackLog;
  context.tracking = tracking;

  return <LogContext.Provider value={context}>{children}</LogContext.Provider>;
}

LogProviderImpl.propTypes = {
  children: PropTypes.node.isRequired,
  tracking: PropTypes.any,
};

LogProviderImpl.defaultProps = {
  tracking: undefined,
};

export const LogProvider = track(
  {},
  {
    dispatch: (data) => {
      const { message, ...props } = data;
      trackLog.info(message, props);
    },
  }
)(LogProviderImpl);
