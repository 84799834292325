import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Key } from '../../../models';
import { DrillContext } from '../../../providers';
import { addEvent, noop, removeEvent } from '../../../utils';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '10em',
    width: '150px',
  },
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  centered: {
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  greenText: {
    color: '#0caa0c',
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  iconColumn: {
    textAlign: 'right',
  },
  marginAuto: {
    margin: 'auto',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  paddedContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    paddingTop: `${theme.spacing(2)} !important`,
    textAlign: 'center',
    width: '100%',
  },
  redText: {
    color: '#cc0c0c',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  valueColumn: {
    textAlign: 'right',
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function CaptureAnswer(props) {
  const { onAnswer, onPass } = props;
  const { targetIcon } = useSelector((state) => state.drill);
  const { assessment, drill, level, iconSet, details } = useContext(DrillContext);
  const [isAssessment, setIsAssessment] = useState(false);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const classes = useStyles();

  const TYPO_VARIANT = 'h5';

  const handleOkayClick = useCallback(() => {
    onAnswer(answer);
  }, [answer, onAnswer]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === Key.K_ENTER || event.code === Key.K_NUMPAD_ENTER) {
        handleOkayClick();
      }
    };

    addEvent(document, 'keydown', listener);

    return () => {
      removeEvent(document, 'keydown', listener);
    };
  }, [handleOkayClick]);

  useEffect(() => {
    setIsAssessment(!!assessment);
  }, [assessment]);

  useEffect(() => {
    if (!(details && iconSet)) {
      return;
    }

    const iconName = targetIcon?.name ?? '';
    const iconSetName = iconSet.name.toLowerCase();

    const val = details.question.replace(/{ObjectName}/gi, iconName).replace(/{ObjectSetName}/gi, iconSetName);

    setQuestion(val);
  }, [details, iconSet, targetIcon?.name]);

  const handleAnswerChanged = (event) => {
    setAnswer(event.target.value);
  };

  const handlePassClick = () => {
    onPass();
  };

  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.column, classes.card)}>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap, classes.header)}>
          {!isAssessment && (
            <Box className={clsx(classes.stretched, classes.headline)}>{`${drill && drill.name} - Level ${level}`}</Box>
          )}
          {isAssessment && <Box className={clsx(classes.stretched, classes.headline)}>{`${drill && drill.name}`}</Box>}
        </Box>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap)}>
          <Box className={clsx(classes.row, classes.marginAuto)}>
            <Box className={classes.column}>
              <Grid container spacing={1}>
                <Grid item className={classes.paddedContainer}>
                  <Typography variant={TYPO_VARIANT}>{question}</Typography>
                </Grid>
                <Grid item className={classes.paddedContainer}>
                  <form noValidate autoComplete="off">
                    <TextField
                      autoFocus
                      label="Your answer"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      style={{ width: '332px' }}
                      onChange={handleAnswerChanged}
                    />
                  </form>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.paddedContainer}
                >
                  <Button
                    variant="contained"
                    disabled={!answer}
                    className={clsx(classes.button, classes.marginRight)}
                    onClick={handleOkayClick}
                  >
                    Okay
                  </Button>
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.marginLeft)}
                    onClick={handlePassClick}
                  >
                    Pass
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

CaptureAnswer.propTypes = {
  onAnswer: PropTypes.func,
  onPass: PropTypes.func,
};

CaptureAnswer.defaultProps = {
  onAnswer: noop,
  onPass: noop,
};
