import { Box, Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { GoalType, Key } from '../../../models';
import { DrillContext } from '../../../providers';
import { addEvent, removeEvent } from '../../../utils';
import { BackButton } from '../../BackButton';

import { ExplainerImage } from './ExplainerImage';
import { EyeSpinTask } from './EyeSpinTask';
import { PreviousLevelDropdown } from './PreviousLevelDropdown';
import { SimpleTask } from './SimpleTask';
import { StandardTask } from './StandardTask';
import { TrafficLightsTask } from './TrafficLightsTask';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:focus': {
      outline: 'none',
    },
    borderRadius: '10em',
    width: '150px',
  },
  buttonContainer: {
    alignContent: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)} 0 !important`,
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
  card: {
    boxShadow: `0 ${theme.spacing(1)} ${theme.spacing(2)} 0 rgba(0,0,0,.15)`,
    overflow: 'hidden',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  explainerContainer: {
    padding: '0 16px',
  },
  explainerContainerColumn: {
    flex: 1,
  },
  header: {
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  headline: {
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  headlineBackButtonContainer: {
    marginRight: theme.spacing(2),
  },
  headlineButton: {
    marginLeft: `${theme.spacing(1)} !important`,
  },
  marginAuto: {
    margin: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  stretched: {
    flexGrow: 100000,
  },
  wrap: {
    flexWrap: 'wrap',
  },
}));

export function Task(props) {
  const { variant, component, onStart } = props;
  const { drill, details, level, imagesPreloaded, assessment } = useContext(DrillContext);
  const [isAssessment, setIsAssessment] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const listener = (event) => {
      if (imagesPreloaded && (event.code === Key.K_ENTER || event.code === Key.K_NUMPAD_ENTER)) {
        onStart();
      }
    };

    addEvent(document, 'keydown', listener);

    return () => {
      removeEvent(document, 'keydown', listener);
    };
  }, [imagesPreloaded, onStart]);

  useEffect(() => {
    setIsAssessment(!!assessment);
  }, [assessment]);

  const getTaskComponent = () => {
    if (!drill?.needsTarget) {
      return <SimpleTask variant={variant} component={component} />;
    }

    if (drill?.goalType === GoalType.TRAFFIC_LIGHTS) {
      return <TrafficLightsTask variant={variant} component={component} />;
    }

    if (drill?.goalType === GoalType.EYE_SPIN) {
      return <EyeSpinTask variant={variant} component={component} />;
    }

    return <StandardTask variant={variant} component={component} />;
  };

  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.column, classes.card)}>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap, classes.header)}>
          {!isAssessment && (
            <>
              <Box className={classes.headlineBackButtonContainer}>
                <BackButton />
              </Box>
              <Box className={clsx(classes.stretched, classes.headline)}>{`${drill?.name} - Level ${level}`}</Box>
              <Box>
                <PreviousLevelDropdown drillId={drill?.id} drillName={kebabCase(drill?.name)} level={level} />
              </Box>
            </>
          )}
          {isAssessment && <Box className={clsx(classes.stretched, classes.headline)}>{`${drill?.name}`}</Box>}
        </Box>
        <Box sx={{ p: 2 }} className={clsx(classes.row, classes.wrap)}>
          {details && details.explainerImageId && details.explainerImageAlignment === 'Top' && (
            <Box className={clsx(classes.row, classes.explainerContainer)}>
              <ExplainerImage explainerImageId={details.explainerImageId} />
            </Box>
          )}
          <Box className={clsx(classes.row, classes.marginAuto)}>
            {details && details.explainerImageId && (
              <Box className={clsx(classes.column, classes.explainerContainer, classes.explainerContainerColumn)}>
                {details.explainerImageAlignment === 'Left' && (
                  <ExplainerImage explainerImageId={details.explainerImageId} />
                )}
              </Box>
            )}
            <Box className={classes.column}>
              {getTaskComponent()}
              <Box m={2} className={classes.buttonContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  disabled={!imagesPreloaded}
                  onClick={onStart}
                >
                  Start
                </Button>
              </Box>
            </Box>
            {details && details.explainerImageId && (
              <Box className={clsx(classes.column, classes.explainerContainer, classes.explainerContainerColumn)}>
                {details.explainerImageAlignment === 'Right' && (
                  <ExplainerImage explainerImageId={details.explainerImageId} />
                )}
              </Box>
            )}
          </Box>
          {details && details.explainerImageId && details.explainerImageAlignment === 'Bottom' && (
            <Box className={clsx(classes.row, classes.explainerContainer)}>
              <ExplainerImage explainerImageId={details.explainerImageId} />
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
}

Task.propTypes = {
  component: PropTypes.string,
  onStart: PropTypes.func,
  variant: PropTypes.string,
};

Task.defaultProps = {
  component: 'div',
  onStart: () => {},
  variant: 'body1',
};
