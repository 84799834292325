/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable no-loop-func */
class GuidStruct {
  constructor() {
    const self = this;
    self._a = 0;
    self._b = 0;
    self._c = 0;
    self._d = 0;
    self._e = 0;
    self._f = 0;
    self._g = 0;
    self._h = 0;
    self._i = 0;
    self._j = 0;
    self._k = 0;
  }

  equals(other) {
    if (
      other._a === undefined ||
      other._b === undefined ||
      other._c === undefined ||
      other._d === undefined ||
      other._e === undefined ||
      other._f === undefined ||
      other._g === undefined ||
      other._h === undefined ||
      other._i === undefined ||
      other._j === undefined ||
      other._k === undefined
    ) {
      return false;
    }

    if (other._a !== this._a) {
      return false;
    }

    if (other._b !== this._b) {
      return false;
    }

    if (other._c !== this._c) {
      return false;
    }

    if (other._d !== this._d) {
      return false;
    }

    if (other._e !== this._e) {
      return false;
    }

    if (other._f !== this._f) {
      return false;
    }

    if (other._g !== this._g) {
      return false;
    }

    if (other._h !== this._h) {
      return false;
    }

    if (other._i !== this._i) {
      return false;
    }

    if (other._j !== this._j) {
      return false;
    }

    if (other._k !== this._k) {
      return false;
    }

    return true;
  }

  toString(...args) {
    const hexToChar = (a) => {
      a &= 15;

      return a > 9 ? String.fromCharCode(a - 10 + 97) : String.fromCharCode(a + 48);
    };

    const hexToChars = (guidChars, offset, a, b, hex = false) => {
      if (hex) {
        guidChars[offset] = '0';
        offset += 1;
        guidChars[offset] = 'x';
        offset += 1;
      }

      guidChars[offset] = hexToChar(a >> 4);
      offset += 1;
      guidChars[offset] = hexToChar(a);
      offset += 1;

      if (hex) {
        guidChars[offset] = ',';
        offset += 1;
        guidChars[offset] = '0';
        offset += 1;
        guidChars[offset] = 'x';
        offset += 1;
      }

      guidChars[offset] = hexToChar(b >> 4);
      offset += 1;
      guidChars[offset] = hexToChar(b);
      offset += 1;

      return offset;
    };

    const [format = 'D'] = args;
    const chars = [];
    let offset = 0;
    let length = 38;
    let dashes = true;
    let verbose = false;

    if (format.length !== 1) {
      throw new Error('Invalid Guid format specification');
    }

    switch (format.toUpperCase()) {
      case 'D':
        length = 36;
        chars[length - 1] = undefined;
        break;
      case 'N':
        length = 32;
        chars[length - 1] = undefined;
        dashes = false;
        break;
      case 'B':
        chars[offset] = '{';
        offset += 1;
        chars[length - 1] = '}';
        break;
      case 'P':
        chars[offset] = '(';
        offset += 1;
        chars[length - 1] = ')';
        break;
      default:
        if (format.toUpperCase() !== 'X') {
          throw new Error('Invalid Guid format specification');
        }

        length = 68;
        chars[offset] = '{';
        offset += 1;
        chars[length - 1] = '}';
        dashes = false;
        verbose = true;
        break;
    }

    if (verbose) {
      chars[offset] = '0';
      offset += 1;
      chars[offset] = 'x';
      offset += 1;
      offset = hexToChars(chars, offset, this._a >> 24, this._a >> 16);
      offset = hexToChars(chars, offset, this._a >> 8, this._a);
      chars[offset] = ',';
      offset += 1;
      chars[offset] = '0';
      offset += 1;
      chars[offset] = 'x';
      offset += 1;
      offset = hexToChars(chars, offset, this._b >> 8, this._b);
      chars[offset] = ',';
      offset += 1;
      chars[offset] = '0';
      offset += 1;
      chars[offset] = 'x';
      offset += 1;
      offset = hexToChars(chars, offset, this._c >> 8, this._c);
      chars[offset] = ',';
      offset += 1;
      chars[offset] = '{';
      offset += 1;
      offset = hexToChars(chars, offset, this._d, this._e, true);
      chars[offset] = ',';
      offset += 1;
      offset = hexToChars(chars, offset, this._f, this._g, true);
      chars[offset] = ',';
      offset += 1;
      offset = hexToChars(chars, offset, this._h, this._i, true);
      chars[offset] = ',';
      offset += 1;
      offset = hexToChars(chars, offset, this._j, this._k, true);
      chars[offset] = '}';
      offset += 1;
    } else {
      offset = hexToChars(chars, offset, this._a >> 24, this._a >> 16);
      offset = hexToChars(chars, offset, this._a >> 8, this._a);

      if (dashes) {
        chars[offset] = '-';
        offset += 1;
      }

      offset = hexToChars(chars, offset, this._b >> 8, this._b);

      if (dashes) {
        chars[offset] = '-';
        offset += 1;
      }

      offset = hexToChars(chars, offset, this._c >> 8, this._c);

      if (dashes) {
        chars[offset] = '-';
        offset += 1;
      }

      offset = hexToChars(chars, offset, this._d, this._e);

      if (dashes) {
        chars[offset] = '-';
        offset += 1;
      }

      offset = hexToChars(chars, offset, this._f, this._g);
      offset = hexToChars(chars, offset, this._h, this._i);
      offset = hexToChars(chars, offset, this._j, this._k);
    }

    let str = '';

    for (let i = 0; i < length; i += 1) {
      str += chars[i];
    }

    return str;
  }
}

const generateGuid = () => {
  let d = Date.now();

  return 'xxxxxxxx-xxxx-4xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    return (c === 'x' ? r : (r & 0x07) | 0x08).toString(16);
  });
};

const stringToNumber = (value, radix, currPos) => {
  if (typeof value !== 'string') {
    return {
      currPos,
      value: 0,
    };
  }

  radix = radix === undefined || radix !== 16 ? 10 : radix; // we're only going to support decimal and hexadecimal;
  let str = '';
  const startPos = currPos;

  for (let i = startPos; i < value.length; i += 1) {
    currPos = i;

    if (radix === 10 && value[i].match(/^[0-9]+$/) === null) {
      break;
    }

    if (radix === 16 && value[i].match(/^[0-9a-fA-F]+$/) === null) {
      break;
    }

    str += value[i];
  }

  return {
    currPos,
    value: parseInt(str, radix),
  };
};

const stringToInt = (value, parsePos, requiredLength) => {
  const startPos = parsePos === undefined ? 0 : parsePos;
  // var str = value.substring(parsePos);
  const result = stringToNumber(value, 16, startPos);

  if (requiredLength !== -1 && result.currPos !== undefined && result.currPos - startPos !== requiredLength) {
    return { success: false };
  }

  return {
    parsePos: result.currPos,
    success: true,
    value: result.value,
  };
};

const stringToLong = (value, parsePos) => {
  const startPos = parsePos === undefined ? 0 : parsePos;
  const result = stringToNumber(value, 16, startPos);

  return {
    parsePos: result.currPos,
    success: true,
    value: result.value,
  };
};

const tryParseGuidWithDashes = (guidString) => {
  let startPos = 0;
  let parsePos = 0;
  const parsedGuid = new GuidStruct();

  if (guidString[0] === '{') {
    if (guidString.length !== 38 || guidString[37] !== '}') {
      return {
        success: false,
      };
    }

    startPos = 1;
  } else if (guidString[0] === '(') {
    if (guidString.length !== 38 || guidString[37] !== ')') {
      return {
        success: false,
      };
    }

    startPos = 1;
  } else if (guidString.length !== 36) {
    return { success: false };
  }

  if (
    guidString[8 + startPos] !== '-' ||
    guidString[13 + startPos] !== '-' ||
    guidString[18 + startPos] !== '-' ||
    guidString[23 + startPos] !== '-'
  ) {
    return { success: false };
  }

  parsePos = startPos;
  let r = stringToInt(guidString, parsePos, 8);

  if (!r.success) {
    return { success: false };
  }

  parsedGuid._a = r.value;
  r.parsePos += 1;
  parsePos = r.parsePos;
  r = stringToInt(guidString, parsePos, 4);

  if (!r.success) {
    return { success: false };
  }

  parsedGuid._b = r.value;

  r.parsePos += 1;
  parsePos = r.parsePos;
  r = stringToInt(guidString, parsePos, 4);

  if (!r.success) {
    return { success: false };
  }

  parsedGuid._c = r.value;
  r.parsePos += 1;
  parsePos = r.parsePos;
  r = stringToInt(guidString, parsePos, 4);

  if (!r.success) {
    return { success: false };
  }

  let { value } = r;
  r.parsePos += 1;
  parsePos = r.parsePos;
  startPos = parsePos;
  r = stringToLong(guidString, parsePos);

  if (!r.success) {
    return { success: false };
  }

  r.parsePos += 1;

  if (r.parsePos - startPos !== 12) {
    return { success: false };
  }

  const checksum = r.value;
  parsedGuid._d = value >> 8;
  parsedGuid._e = value;
  value = checksum >> 32;
  parsedGuid._f = value >> 8;
  parsedGuid._g = value;
  value = checksum;
  parsedGuid._h = value >> 24;
  parsedGuid._i = value >> 16;
  parsedGuid._j = value >> 8;
  parsedGuid._k = value;

  return {
    success: true,
    value: parsedGuid,
  };
};

const tryParseGuidWithNoStyle = (guidString) => {
  let startPos = 0;
  const parsedGuid = new GuidStruct();

  if (guidString.length !== 32) {
    return { success: false };
  }

  for (let i = 0; i < guidString.length; i += 1) {
    const c = guidString[i];

    if (c < '0' || c > '9') {
      if (c.toUpperCase < 'A' || c.toUpperCase > 'F') {
        return { success: false };
      }
    }
  }

  // parsePos = startPos;
  let r = stringToInt(guidString.substring(startPos, 8), undefined, -1);

  if (!r.success) {
    return { success: false };
  }

  parsedGuid._a = r.value;
  startPos += 8;
  r = stringToInt(guidString.substring(startPos, 4), undefined, -1);

  if (!r.success) {
    return { success: false };
  }

  parsedGuid._b = r.value;
  startPos += 4;
  r = stringToInt(guidString.substring(startPos, 4), undefined, -1);

  if (!r.success) {
    return { success: false };
  }

  parsedGuid._c = r.value;
  startPos += 4;
  r = stringToInt(guidString.substring(startPos, 4), undefined, -1);

  if (!r.success) {
    return { success: false };
  }

  let { value } = r;

  startPos += 4;
  r = stringToLong(guidString, startPos);

  if (!r.success) {
    return { success: false };
  }

  r.parsePos += 1;

  if (r.parsePos - startPos !== 12) {
    return { success: false };
  }

  const checksum = r.value;
  parsedGuid._d = value >> 8;
  parsedGuid._e = value;
  value = checksum >> 32;
  parsedGuid._f = value >> 8;
  parsedGuid._g = value;
  value = checksum;
  parsedGuid._h = value >> 24;
  parsedGuid._i = value >> 16;
  parsedGuid._j = value >> 8;
  parsedGuid._k = value;

  return {
    success: true,
    value: parsedGuid,
  };
};

const tryParseString = (value) => {
  if (typeof value !== 'string') {
    return {};
  }

  const hasDashes = value.indexOf('-') >= 0;

  if (hasDashes) {
    return tryParseGuidWithDashes(value);
  }

  return tryParseGuidWithNoStyle(value);
};

export class Guid {
  _value;
  constructor(value) {
    if (!value) {
      this._value = new GuidStruct();
    } else {
      const result = tryParseString(value);

      if (result.success) {
        this._value = result.value;
      } else {
        throw new Error(`Unable to parse constructor parameter: ${value}`);
      }
    }
  }

  static get empty() {
    return new Guid();
  }

  static newGuid() {
    return new Guid(generateGuid());
  }

  static parse(value) {
    const result = tryParseString(value);

    if (result.success) {
      return result.value;
    }

    throw new Error(`Unable to parse constructor parameter: ${value}`);
  }

  equals(other) {
    return this.toString() === other.toString();
  }

  toString(format = 'D') {
    return this._value.toString(format);
  }
}
