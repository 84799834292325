import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DrillContext } from '../../../providers';

const useStyles = makeStyles({
  html: {
    whiteSpace: 'pre-wrap',
  },
});

const formatTrafficLightsTask = (task, numberOfObjects) => {
  if (task && numberOfObjects) {
    return task.replace(/{totalObjects}/gi, numberOfObjects);
  }

  return task;
};

export function TrafficLightsTask(props) {
  const { variant, component } = props;
  const { settings } = useContext(DrillContext);
  const { task } = useSelector((state) => state.drill);
  const classes = useStyles();

  const formattedTask = useMemo(
    () => formatTrafficLightsTask(task, settings.totalObjects),
    [task, settings.totalObjects]
  );

  return (
    <Typography variant={variant} component={component} className={classes.html}>
      {formattedTask}
    </Typography>
  );
}

TrafficLightsTask.propTypes = {
  component: PropTypes.any,
  variant: PropTypes.any,
};

TrafficLightsTask.defaultProps = {
  component: 'div',
  variant: 'body1',
};
