export class Rect {
  _x;
  _y;
  _width;
  _height;

  constructor(x = 0, y = 0, width = 0, height = 0) {
    this._x = x || 0;
    this._y = y || 0;
    this._width = width || 0;
    this._height = height || 0;
  }

  get x() {
    return this._x;
  }

  set x(value) {
    this._x = value;
  }

  get y() {
    return this._y;
  }

  set y(value) {
    this._y = value;
  }

  get width() {
    return this._width;
  }

  set width(value) {
    this._width = value;
  }

  get height() {
    return this._height;
  }

  set height(value) {
    this._height = value;
  }

  get left() {
    return this._x;
  }

  get top() {
    return this._y;
  }

  get right() {
    return this._x + this._width;
  }

  get bottom() {
    return this._y + this._height;
  }

  intersect(other) {
    if (this.left >= other.right || other.left >= this.right) {
      return false;
    }

    if (this.bottom >= other.top || other.bottom >= this.top) {
      return false;
    }

    return true;
  }
}
