import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export function TimeExhaustedModal(props) {
  const { message, onClose, open, ...others } = props;

  const handleOkayClick = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      aria-labelledby="time-exhausted-dialog-title"
      aria-describedby="time-exhausted-dialog-text"
      {...others}
    >
      <DialogTitle id="time-exhausted-dialog-title">EyeGym</DialogTitle>
      <DialogContent>
        <DialogContentText id="time-exhausted-dialog-text">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleOkayClick}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TimeExhaustedModal.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
